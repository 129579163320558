import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import timezones from "timezones-list";
import {
  CircularProgress,
  Button,
  TextField,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { AllInclusive, AddAPhoto, CloseRounded } from "@mui/icons-material";
import useGetSubscriptionDetails from "../../hooks/GET/useGetSubscriptionDetails";
import useGetAccountDetails from "../../hooks/GET/useGetAccountDetails";
import useSubManage from "../../hooks/POST/useSubManage";
import useUpdateProfile from "../../hooks/PUT/useUpdateProfile";
import useResetPassword from "../../hooks/PUT/useResetPassword";

const Profile = () => {
  const [photoModalState, setPhotoModalState] = useState(false);
  const [profile, setProfile] = useState({
    picture: "",
    company: "",
    email: "",
    timezone: {
      code: "",
      utc: "",
    },
  });
  let { user } = useAuth0();
  const { picture, email, email_verified, name } = user;
  const { username } = user[process.env.REACT_APP_AUTH0_METADATA];
  const { subIsLoading, data: subscription } = useGetSubscriptionDetails();
  const { accIsLoading, data: account } = useGetAccountDetails();
  const subManage = useSubManage();
  const updateProfile = useUpdateProfile(email, profile);
  const resetPassword = useResetPassword(email);

  const handleSubManage = async () => {
    const session = await subManage.mutateAsync();
    window.location.href = session.url;
  };

  const handleOpenPhotoModal = () => {
    // setProfile({
    //   picture: picture,
    //   company: name,
    //   email: email,
    //   timezone: {
    //     code: account.tz_code,
    //     utc: account.tz_utc,
    //   },
    // });

    setPhotoModalState(true);
  };

  const handleClosePhotoModal = () => {
    setPhotoModalState(false);
  };

  const handleUpdateProfile = async () => {
    const updatedUser = await updateProfile.mutateAsync();
    user.name = updatedUser.name;
    user.email = updatedUser.email;
    user.email_verified = updatedUser.email_verified;
    user.picture = updatedUser.picture;
    account.tz_code = updatedUser.tz_code;
    account.tz_utc = updatedUser.tz_utc;

    setProfile({
      picture: updatedUser.picture,
      company: updatedUser.name,
      email: updatedUser.email,
      timezone: {
        code: updatedUser.tz_code,
        utc: updatedUser.tz_utc,
      },
    });

    setPhotoModalState(false);
  };

  const handlePasswordReset = () => {
    resetPassword.mutate();
  };

  useEffect(() => {
    setProfile({
      picture: picture,
      company: name,
      email: email,
      timezone: {
        code: account?.tz_code || "",
        utc: account?.tz_utc || "",
      },
    });
  }, [email, name, picture, account]);

  if (accIsLoading || subIsLoading) {
    return (
      <div className="retrieving">
        <CircularProgress />
        <h2>Retrieving account details...</h2>
      </div>
    );
  }

  return (
    <div className="profile">
      <h1 className="title">Profile</h1>
      <div className="details">
        <div className="headshot">
          <div tabIndex="0" className="edit-photo" onClick={handleOpenPhotoModal}>
            <AddAPhoto titleAccess="Edit Photo" />
          </div>
          <img src={picture} alt="Headshot" />
        </div>
        <Dialog open={photoModalState} maxWidth="md" fullWidth className="dh-modal photo">
          <IconButton onClick={handleClosePhotoModal} className="close" aria-label="close">
            <CloseRounded />
          </IconButton>
          <DialogTitle>Update profile picture</DialogTitle>
          <DialogContent>
            <DialogContentText>Please enter a valid image URL</DialogContentText>
            <TextField
              fullWidth
              className="field image"
              label="Image URL"
              variant="outlined"
              value={profile.picture}
              onChange={(e) => setProfile({ ...profile, picture: e.target.value })}
            />
          </DialogContent>
          <DialogActions className="actions">
            <Button onClick={handleClosePhotoModal} variant="text" className="line-btn warning">
              Cancel
            </Button>
            <Button
              variant="contained"
              className={`white-btn ${profile.picture === picture ? "disabled" : ""}`}
              onClick={handleUpdateProfile}
            >
              {updateProfile.isLoading ? <CircularProgress /> : "Save"}
            </Button>
          </DialogActions>
        </Dialog>

        <div className="personal">
          <TextField
            fullWidth
            className="field company"
            label="Company"
            variant="outlined"
            value={profile.company}
            onChange={(e) => setProfile({ ...profile, company: e.target.value })}
          />
          <Tooltip title="Username cannot be changed" placement="bottom-start">
            <TextField
              fullWidth
              className="field username"
              label="Username"
              disabled
              variant="outlined"
              value={username}
            />
          </Tooltip>
          <TextField
            fullWidth
            className="field email"
            label="Email"
            variant="outlined"
            value={profile.email}
            helperText={
              !email_verified &&
              "Email not verified. Please check your email for a verification link."
            }
            onChange={(e) => setProfile({ ...profile, email: e.target.value.toLocaleLowerCase() })}
          />

          <FormControl fullWidth className="field timezone">
            <InputLabel id="timezone-label">Timezone</InputLabel>
            <Select
              labelId="timezone-label"
              value={profile.timezone.code || ""}
              onChange={(e) => {
                const tz = timezones.find((timezone) => timezone.tzCode === e.target.value);
                setProfile({ ...profile, timezone: { code: tz.tzCode, utc: tz.utc } });
              }}
              label="Timezone"
            >
              {timezones.map((timezone) => {
                return (
                  <MenuItem key={timezone.tzCode} value={timezone.tzCode}>
                    {timezone.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <div className="profile-actions">
            <Button variant="text" className="line-btn warning" onClick={handlePasswordReset}>
              Reset Password
            </Button>

            <Button
              variant="contained"
              className={`white-btn ${
                profile.company === name &&
                profile.email === email &&
                profile.timezone === account?.timezone
                  ? "disabled"
                  : ""
              }`}
              onClick={handleUpdateProfile}
            >
              {updateProfile.isLoading && !photoModalState ? <CircularProgress /> : "Save Changes"}
            </Button>
          </div>
        </div>
      </div>
      <div className="subscription">
        <h2>
          Subscription Level -{" "}
          <span className={subscription?.pkg_name?.toLowerCase()}>
            {subscription.pkg_name.toUpperCase()}
          </span>
        </h2>
        <div className="package">
          <div className="dollar">${subscription.monthly_cost}</div>
          <div className="period">
            <span>per</span>
            <span>month</span>
          </div>
        </div>
        <ul>
          <li>
            Contact Limit:&nbsp;
            <strong>
              {subscription.contacts === -1 ? <AllInclusive /> : subscription.contacts}
            </strong>
          </li>
          {subscription?.id >= 2 && (
            <li>
              Engagements Limit:&nbsp;
              <strong>
                {subscription.engagements === -1 ? <AllInclusive /> : subscription.engagements}
              </strong>
            </li>
          )}
          {subscription?.id >= 3 && (
            <li>
              Applications Limit:&nbsp;
              <strong>
                {subscription.applications === -1 ? <AllInclusive /> : subscription.applications}
              </strong>
            </li>
          )}
          <li>
            Slate Queries Limit: <strong>{subscription.queries}</strong>
          </li>
          <li>
            Field Mappings Limit: <strong>{subscription.field_maps}</strong>
          </li>
          <li>
            Value Mappings Limit: <strong>{subscription.value_maps}</strong>
          </li>
          <li>
            Sync Jobs Limit: <strong>{subscription.jobs}</strong>
          </li>
          <li>
            Sync Frequency: <strong>{subscription.frequency}</strong>
          </li>
        </ul>
        <div className="billing">
          <Button onClick={handleSubManage} variant="contained" className="gradient-btn">
            {subManage.isLoading ? <CircularProgress /> : "Manage Billing"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
