import React from "react";
import useGetSettings from "../../hooks/GET/useGetSettings";
import useGetSubscriptionDetails from "../../hooks/GET/useGetSubscriptionDetails";
import useGetLimits from "../../hooks/GET/useGetLimits";
import { CircularProgress, Card, CardContent, LinearProgress } from "@mui/material";
import { AllInclusiveRounded } from "@mui/icons-material";

export default function UsageDetails() {
  const { isLoading: settingsIsLoading, data: settings } = useGetSettings();
  const { isLoading: subIsLoading, data: subscription } = useGetSubscriptionDetails();
  const { isLoading: limitsIsLoading, data: limits } = useGetLimits();

  if (settingsIsLoading || subIsLoading || limitsIsLoading) {
    <div className="retrieving">
      <CircularProgress />
      <h2 className="process">Retrieving data...</h2>
    </div>;
  }

  return (
    <div className="usage-details">
      <Card>
        <CardContent>
          <h2 className="title">Sync Usage</h2>
          <h3 className="plan">
            Subscription Level:{" "}
            <strong className={subscription?.pkg_name?.toLowerCase()}>
              {subscription?.pkg_name}
            </strong>
          </h3>
          <p>Contact usage</p>
          <div className="bar">
            <LinearProgress
              variant="determinate"
              value={(limits?.contacts?.count / subscription?.contacts) * 100}
            />
            <div
              className={
                limits?.contacts?.count === subscription?.contacts
                  ? "max count"
                  : `${
                      limits?.contacts?.count / subscription?.contacts >= 0.8
                        ? "close count"
                        : "count"
                    }`
              }
            >
              <span>
                {limits?.contacts?.count} /&nbsp;
                {subscription?.contacts === -1 ? <AllInclusiveRounded /> : subscription?.contacts}
              </span>
            </div>
          </div>

          {subscription?.id >= 2 ? (
            <div>
              <p>Engagements usage</p>
              <div className="bar">
                <LinearProgress
                  variant="determinate"
                  value={(limits?.engagements?.count / subscription?.engagements) * 100}
                />
                <div
                  className={
                    limits?.engagements?.count === subscription?.engagements
                      ? "max count"
                      : `${
                          limits?.engagements?.count / subscription?.engagements >= 0.8
                            ? "close count"
                            : "count"
                        }`
                  }
                >
                  <span>
                    {limits?.engagements?.count} /&nbsp;
                    {subscription?.engagements === -1 ? (
                      <AllInclusiveRounded />
                    ) : (
                      subscription?.engagements
                    )}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {settings?.hs_enterprise && subscription?.id >= 3 ? (
            <div>
              <p>Application usage</p>
              <div className="bar">
                <LinearProgress
                  variant="determinate"
                  value={(limits?.applications?.count / subscription?.applications) * 100}
                />
                <div
                  className={
                    limits?.applications?.count === subscription?.applications
                      ? "max count"
                      : `${
                          limits?.applications?.count / subscription?.applications >= 0.8
                            ? "close count"
                            : "count"
                        }`
                  }
                >
                  <span>
                    {limits?.applications?.count} /&nbsp;
                    {subscription?.applications === -1 ? (
                      <AllInclusiveRounded />
                    ) : (
                      subscription?.applications
                    )}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
    </div>
  );
}
