import React, { useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";

export default function Table(props) {
  const {
    data,
    columns,
    defaults,
    direction,
    state,
    selectionModel,
    setSelectionModel,
    setCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    rows,
    setRows,
    setSelectedRows,
  } = props;

  useEffect(() => {
    state === "active"
      ? setRows([
          ...(defaults?.length > 0 ? defaults : []),
          ...(data?.[direction]?.[state]?.length > 0 ? data?.[direction]?.[state] : []),
        ])
      : setRows([...(data?.[direction]?.[state]?.length > 0 ? data?.[direction]?.[state] : [])]);

    setCount(data?.[direction]?.["active"]?.length + (defaults?.length || 0));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, direction, state]);

  return (
    <div className="dh-grid">
      <DataGridPro
        rows={rows}
        columns={columns}
        page={page}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        checkboxSelection
        disableSelectionOnClick
        selectionModel={selectionModel}
        onSelectionModelChange={(selection) => {
          setSelectionModel(selection);
          setSelectedRows(
            rows.filter((row) => {
              if (selection.includes(row.id)) {
                return row;
              }

              return false;
            })
          );
        }}
        onPageChange={(newPage) => {
          setSelectionModel([]);
          setPage(newPage);
        }}
        isRowSelectable={(params) => params.id >= 1}
        getRowClassName={(params) => (params.row.id < 1 ? "default" : "")}
        initialState={{ pinnedColumns: { left: [], right: ["Actions"] } }}
      />
    </div>
  );
}
