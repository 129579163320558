import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../../../globalAlerts";

export default function useDeleteJobs(record, ids) {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async () => {
      const token = await getAccessTokenSilently();

      return await axios
        .delete(`/${record}/jobs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            ids: ids,
            tenant: metadata.username,
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        alertCtx.success("Sync Job(s) successfully deleted");

        queryClient.setQueryData(["jobs", record], (old) => {
          return {
            toHubSpot: {
              active: old.toHubSpot.active,
              archived: [
                ...old.toHubSpot.archived.filter((val) => {
                  if (!ids.includes(val.id)) {
                    return val;
                  }

                  return false;
                }),
              ],
            },
            toSlate: {
              active: old.toSlate.active,
              archived: [
                ...old.toSlate.archived.filter((val) => {
                  if (!ids.includes(val.id)) {
                    return val;
                  }

                  return false;
                }),
              ],
            },
          };
        });
      },
      onError: () => {
        alertCtx.error(
          "Something went wrong, please try again later. If the problem persists, contact support."
        );
      },
    }
  );
}
