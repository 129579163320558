export default function validate(rows, newRow, submit) {
  let valid = true;
  const details = {
    jobName: {
      filled: true,
      regex: true,
      unique: true,
      errorMsg: "",
      state: true,
    },
    subrecord: {
      filled: true,
      errorMsg: "",
      state: true,
    },
    query: {
      filled: true,
      unique: true,
      errorMsg: "",
      state: true,
    },
    list: {
      filled: {
        errorMsg: "",
        state: true,
      },
      unique: {
        errorMsg: "",
        state: true,
      },
      populated: {
        errorMsg: "",
        state: true,
      },
      size: {
        errorMsg: "",
        state: true,
      },
    },
    expression: {
      day: {
        filled: true,
        unique: true,
        errorMsg: "",
        state: true,
      },
      hour: {
        filled: true,
        unique: true,
        errorMsg: "",
        state: true,
      },
      min: {
        filled: true,
        unique: true,
        errorMsg: "",
        state: true,
      },
    },
  };

  // Filled Checks
  if (submit) {
    for (const [key1, value1] of Object.entries(newRow)) {
      if (value1 === "" && key1 === "jobName") {
        details[key1].filled = false;
        details[key1].errorMsg = "This field is required.";
        details[key1].state = false;
        valid = false;
      }

      if (key1 === "subrecord") {
        if (value1 === "") {
          details[key1].filled = false;
          details[key1].errorMsg = "This field is required.";
          details[key1].state = false;
          valid = false;
        }
      }

      if (key1 === "query") {
        for (const [, value2] of Object.entries(newRow[key1])) {
          if (value2 === "") {
            details[key1].filled = false;
            details[key1].errorMsg = "This field is required.";
            details[key1].state = false;
            valid = false;
          }
        }
      }

      if (key1 === "list") {
        for (const [, value2] of Object.entries(newRow[key1])) {
          if (value2 === "") {
            details[key1].filled.state = false;
            details[key1].filled.errorMsg = "This field is required.";
            valid = false;
          }
        }
      }

      if (key1 === "expression") {
        for (const [key2, value2] of Object.entries(newRow[key1])) {
          if (value2 === "") {
            details[key1][key2].filled = false;
            details[key1][key2].errorMsg = "This field is required.";
            details[key1][key2].state = false;
            valid = false;
          }
        }
      }
    }
  }

  const regex = /[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;

  // NAME CHECKS ==========================
  // Regex check
  if (regex.test(newRow.jobName)) {
    details.jobName.regex = false;
    details.jobName.state = false;
    valid = false;
  }

  // Unique check
  for (let obj of rows) {
    if (obj.name?.toLowerCase() === newRow.jobName?.toLowerCase()) {
      details.jobName.unique = false;
      details.jobName.state = false;
      valid = false;
      break;
    }
  }

  if (!details.jobName.unique) {
    details.jobName.errorMsg = "A job with this name already exists. Please choose a unique name.";
  } else if (!details.jobName.regex) {
    details.jobName.errorMsg =
      "Invalid character(s). Only letters, numbers, and dashes are allowed.";
  }

  // QUERY/LIST checks ====================

  for (let obj of rows) {
    if (
      obj?.query_id !== null &&
      obj?.query_id !== undefined &&
      obj?.query_id === newRow?.query?.id
    ) {
      details.query.unique = false;
      details.query.errorMsg =
        "This Slate Query is already used in another job. If this intentional, ignore this warning.";
      break;
    }

    if (
      obj?.list_id !== null &&
      obj?.list_id !== undefined &&
      obj?.list_id !== "" &&
      obj?.list_id === newRow?.list?.id
    ) {
      details.list.unique.state = false;
      details.list.unique.errorMsg =
        "This HubSpot List is already used in another job. If this intentional, ignore this warning.";
      break;
    }
  }

  if (newRow?.list?.size === 0) {
    details.list.populated.state = false;
    details.list.populated.errorMsg =
      "This HubSpot List is empty. Empty lists will result in no contacts being synced. If this intentional, ignore this warning.";
  }

  if (newRow?.list?.size > 10000) {
    details.list.size.state = false;
    details.list.size.errorMsg =
      "DD Sync cannot process HubSpot lists with more than 10,000 contacts. Please reduce the size of this list or select a different list.";
    valid = false;
  }

  // CRON checks ===============
  for (let obj of rows) {
    if (
      parseInt(obj.hours) === newRow.expression.hour &&
      parseInt(obj.mins) === newRow.expression.min &&
      parseInt(obj.day_week) === newRow.expression.day
    ) {
      details.expression.hour.unique = false;
      details.expression.min.unique = false;
      details.expression.day.unique = false;
      details.expression.hour.errorMsg =
        "This job period is identical to another job. If this intentional, ignore this warning.";
      details.expression.min.errorMsg =
        "This job period is identical to another job. If this intentional, ignore this warning.";
      details.expression.day.errorMsg =
        "This job period is identical to another job. If this intentional, ignore this warning.";
      break;
    }
  }

  return { valid, details };
}
