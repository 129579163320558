import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../../../globalAlerts";

export default function useUpdateValueMap(record, valueMap) {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async () => {
      const token = await getAccessTokenSilently();

      return await axios
        .put(
          `/${record}/mapping/value`,
          {
            tenant: metadata.username,
            valueMap: valueMap,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        alertCtx.success("Value Mapping successfully updated");

        queryClient.setQueryData(
          [`valueMaps - ${record}`, `${valueMap.field_mapping_id}`],
          (old) => {
            return {
              toHubSpot: {
                active: old.toHubSpot.active.map((val) => {
                  return val.id === valueMap.id ? valueMap : val;
                }),
                archived: old.toHubSpot.archived,
              },
              toSlate: {
                active: old.toSlate.active.map((val) => {
                  return val.id === valueMap.id ? valueMap : val;
                }),
                archived: old.toSlate.archived,
              },
            };
          }
        );
      },
      onError: () => {
        alertCtx.error(
          "Something went wrong, please try again later. If the problem persists, contact support."
        );
      },
    }
  );
}
