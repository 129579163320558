import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useGetHsFields(direction, record) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    [`hsFields - ${record}`, direction],
    async () => {
      const token = await getAccessTokenSilently();

      if (record === "engagements") {
        let fields = [];

        const queries = await axios
          .get(`/${record}/queries`, {
            params: {
              tenant: metadata.username,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => res.data)
          .catch((err) => err);

        const emails = [
          {
            hs_field_value: "from_email",
            hs_field_label: "From Email",
            hs_field_type: "emails",
            hs_group: "Emails",
          },
          {
            hs_field_value: "from_first_name",
            hs_field_label: "From First Name",
            hs_field_type: "emails",
            hs_group: "Emails",
          },
          {
            hs_field_value: "from_last_name",
            hs_field_label: "From Last Name",
            hs_field_type: "emails",
            hs_group: "Emails",
          },
          {
            hs_field_value: "to_email",
            hs_field_label: "To Email",
            hs_field_type: "emails",
            hs_group: "Emails",
          },
          {
            hs_field_value: "to_first_name",
            hs_field_label: "To First Name",
            hs_field_type: "emails",
            hs_group: "Emails",
          },
          {
            hs_field_value: "to_last_name",
            hs_field_label: "To Last Name",
            hs_field_type: "emails",
            hs_group: "Emails",
          },
          {
            hs_field_value: "send_date",
            hs_field_label: "Send Date",
            hs_field_type: "emails",
            hs_group: "Emails",
          },
          {
            hs_field_value: "email_status",
            hs_field_label: "Email Status",
            hs_field_type: "emails",
            hs_group: "Emails",
          },
          {
            hs_field_value: "email_subject",
            hs_field_label: "Email Subject",
            hs_field_type: "emails",
            hs_group: "Emails",
          },
          {
            hs_field_value: "email_content",
            hs_field_label: "Email Content",
            hs_field_type: "emails",
            hs_group: "Emails",
          },
        ];
        const calls = [
          {
            hs_field_value: "call_person_email",
            hs_field_label: "Person Email",
            hs_field_type: "calls",
            hs_group: "Calls",
          },
          {
            hs_field_value: "call_date",
            hs_field_label: "Call Date",
            hs_field_type: "calls",
            hs_group: "Calls",
          },
          {
            hs_field_value: "call_title",
            hs_field_label: "Call Title",
            hs_field_type: "calls",
            hs_group: "Calls",
          },
          {
            hs_field_value: "call_notes",
            hs_field_label: "Call Notes",
            hs_field_type: "calls",
            hs_group: "Calls",
          },
          {
            hs_field_value: "call_duration",
            hs_field_label: "Call Duration",
            hs_field_type: "calls",
            hs_group: "Calls",
          },
          {
            hs_field_value: "from_number",
            hs_field_label: "From Number",
            hs_field_type: "calls",
            hs_group: "Calls",
          },
          {
            hs_field_value: "to_number",
            hs_field_label: "To Number",
            hs_field_type: "calls",
            hs_group: "Calls",
          },
          {
            hs_field_value: "call_recording_url",
            hs_field_label: "Call Recording URL",
            hs_field_type: "calls",
            hs_group: "Calls",
          },
          {
            hs_field_value: "call_status",
            hs_field_label: "Call Status",
            hs_field_type: "calls",
            hs_group: "Calls",
          },
          {
            hs_field_value: "call_direction",
            hs_field_label: "Call Direction",
            hs_field_type: "calls",
            hs_group: "Calls",
          },
        ];
        const notes = [
          {
            hs_field_value: "note_person_email",
            hs_field_label: "Person Email",
            hs_field_type: "notes",
            hs_group: "Notes",
          },
          {
            hs_field_value: "note_date",
            hs_field_label: "Note Date",
            hs_field_type: "notes",
            hs_group: "Notes",
          },
          {
            hs_field_value: "note_body",
            hs_field_label: "Note Body",
            hs_field_type: "notes",
            hs_group: "Notes",
          },
        ];
        const meetings = [
          {
            hs_field_value: "meeting_person_email",
            hs_field_label: "Person Email",
            hs_field_type: "meetings",
            hs_group: "Meetings",
          },
          {
            hs_field_value: "meeting_title",
            hs_field_label: "Meeting Title",
            hs_field_type: "meetings",
            hs_group: "Meetings",
          },
          {
            hs_field_value: "meeting_description",
            hs_field_label: "Meeting Description",
            hs_field_type: "meetings",
            hs_group: "Meetings",
          },
          {
            hs_field_value: "meeting_notes",
            hs_field_label: "Meeting Notes",
            hs_field_type: "meetings",
            hs_group: "Meetings",
          },
          {
            hs_field_value: "meeting_location",
            hs_field_label: "Meeting Location",
            hs_field_type: "meetings",
            hs_group: "Meetings",
          },
          {
            hs_field_value: "meeting_start_time_date",
            hs_field_label: "Start Time/Date",
            hs_field_type: "meetings",
            hs_group: "Meetings",
          },
          {
            hs_field_value: "meeting_end_time_date",
            hs_field_label: "End Time/Date",
            hs_field_type: "meetings",
            hs_group: "Meetings",
          },
          {
            hs_field_value: "meeting_outcome",
            hs_field_label: "Meeting Outcome",
            hs_field_type: "meetings",
            hs_group: "Meetings",
          },
        ];
        const postal_mail = [
          {
            hs_field_value: "postal_person_email",
            hs_field_label: "Person Email",
            hs_field_type: "postal_mail",
            hs_group: "Postal Mail",
          },
          {
            hs_field_value: "postal_date",
            hs_field_label: "Send Date",
            hs_field_type: "postal_mail",
            hs_group: "Postal Mail",
          },
          {
            hs_field_value: "postal_body",
            hs_field_label: "Postal Mail Body",
            hs_field_type: "postal_mail",
            hs_group: "Postal Mail",
          },
        ];

        if (
          queries?.some(
            (query) =>
              query.subrecord === "emails" && !query.archived && query.direction === direction
          )
        ) {
          fields = [...fields, ...emails];
        }

        if (
          queries?.some(
            (query) =>
              query.subrecord === "calls" && !query.archived && query.direction === direction
          )
        ) {
          fields = [...fields, ...calls];
        }

        if (
          queries?.some(
            (query) =>
              query.subrecord === "notes" && !query.archived && query.direction === direction
          )
        ) {
          fields = [...fields, ...notes];
        }

        if (
          queries?.some(
            (query) =>
              query.subrecord === "meetings" && !query.archived && query.direction === direction
          )
        ) {
          fields = [...fields, ...meetings];
        }

        if (
          queries?.some(
            (query) =>
              query.subrecord === "postal_mail" && !query.archived && query.direction === direction
          )
        ) {
          fields = [...fields, ...postal_mail];
        }

        return fields.sort((a, b) =>
          a.hs_field_value > b.hs_field_value ? 1 : b.hs_field_value > a.hs_field_value ? -1 : 0
        );
      } else {
        const settings = await axios
          .get("/database/settings", {
            params: {
              tenant: metadata.username,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => res.data)
          .catch((err) => err);

        return await axios
          .get("/hubspot/fields", {
            params: {
              grantType: "refresh_token",
              connectToken: settings.hs_refresh_token,
              tenant: metadata.username,
              record: record,
              app_obj: settings?.hs_app_object_name,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const restrictedFieldTypes = ["select", "booleancheckbox", "checkbox", "radio"];

            const hsFields = res.data.results.filter((fields) => {
              if (direction === "toHubSpot") {
                if (
                  !fields.fieldType.includes("calculat") &&
                  !fields.name.includes("calculat") &&
                  (fields.name !== "email" || record === "applications") &&
                  !fields.hidden &&
                  !fields.modificationMetadata.readOnlyValue &&
                  ((restrictedFieldTypes.includes(fields.fieldType) && fields.options.length > 0) ||
                    !restrictedFieldTypes.includes(fields.fieldType))
                ) {
                  return fields;
                }
              } else {
                if (
                  fields.name !== "email" &&
                  fields.name !== "firstname" &&
                  fields.name !== "lastname" &&
                  !fields.hidden &&
                  ((restrictedFieldTypes.includes(fields.fieldType) && fields.options.length > 0) ||
                    !restrictedFieldTypes.includes(fields.fieldType))
                ) {
                  return fields;
                }
              }

              return false;
            });

            const filteredFields = hsFields
              .map((fields, i) => {
                let groupName = fields.groupName
                  .replaceAll("information", " information")
                  .replaceAll("properties", " properties")
                  .replaceAll("fields", " fields")
                  .replaceAll("_", " ")
                  .split(" ")
                  .map((str) => str.charAt(0).toUpperCase() + str.substring(1))
                  .join(" ");

                return {
                  hs_field_value: fields.name,
                  hs_field_label: fields.label,
                  hs_field_type: fields.fieldType,
                  hs_group: groupName,
                };
              })
              .sort((a, b) =>
                a.hs_field_label > b.hs_field_label
                  ? 1
                  : b.hs_field_label > a.hs_field_label
                  ? -1
                  : 0
              );

            return filteredFields;
          })
          .catch((err) => err.response);
      }
    },
    {
      initialData: () => queryClient.getQueryData([`hsFields - ${record}`, direction]),
      staleTime: 100000,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState([`hsFields - ${record}`, direction])?.dataUpdatedAt,
    }
  );
}
