import React, { useEffect, useState } from "react";
import parser from "cron-parser";
import useGetSubscriptionDetails from "../../hooks/GET/useGetSubscriptionDetails";
import useGetJobs from "../../components/data-hub/hooks/GET/useGetJobs";
import { CircularProgress, Card, CardContent } from "@mui/material";

export default function UpcomingSyncs() {
  const [upcomingHs, setUpcomingHs] = useState([]);
  const [upcomingSl, setUpcomingSl] = useState([]);
  const { isLoading: subLoading, data: subscription } = useGetSubscriptionDetails();
  const { isLoading: cJobsIsLoading, data: cJobs } = useGetJobs("contacts");
  const { isLoading: aJobsIsLoading, data: aJobs } = useGetJobs("applications");
  const { isLoading: eJobsIsLoading, data: eJobs } = useGetJobs("engagements");

  if (subLoading || cJobsIsLoading || aJobsIsLoading || eJobsIsLoading) {
    <div className="retrieving">
      <CircularProgress />
      <h2 className="process">Retrieving data...</h2>
    </div>;
  }

  useEffect(() => {
    const currDate = new Date();
    const endDate = currDate.setDate(currDate.getDate() + 365);
    const options = {
      currentDate: Date.now(),
      endDate: endDate,
      iterator: true,
    };

    function genSyncDates(direction, jobs) {
      return new Promise((resolve, reject) => {
        jobs?.[direction]?.active?.forEach((job) => {
          let expression = [
            job.mins === "?" ? "*" : job.mins,
            job.hour === "?" ? "*" : job.hours,
            job.day_month === "?" ? "*" : job.day_month,
            job.month === "?" ? "*" : job.month,
            job.day_week === "?"
              ? "*"
              : `${
                  job.day_week === "*"
                    ? "*"
                    : `${parseInt(job.day_week) - 1 < 0 ? 6 : parseInt(job.day_week) - 1}`
                }`,
          ];
          expression = expression.join(" ");

          try {
            var interval = parser.parseExpression(expression, options);

            for (let i = 0; i < 5; i++) {
              try {
                const obj = interval.next();
                const dateRaw = obj.value.toString();
                const date = dateRaw.substring(0, dateRaw.indexOf("-"));
                dates.push({ date: date, record: job.record });
              } catch (e) {
                break;
              }
            }

            resolve();
          } catch (err) {
            console.log("Error: " + err.message);
            reject();
          }
        });
      });
    }

    let dates = [];

    genSyncDates("toHubSpot", cJobs);
    if (subscription?.id >= 2) {
      genSyncDates("toHubSpot", eJobs);
    }
    if (subscription?.id >= 3) {
      genSyncDates("toHubSpot", aJobs);
    }

    dates.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    setUpcomingHs(dates.slice(0, 5));

    dates = [];

    genSyncDates("toSlate", cJobs);
    if (subscription?.id >= 2) {
      genSyncDates("toSlate", eJobs);
    }
    if (subscription?.id >= 3) {
      genSyncDates("toSlate", aJobs);
    }

    dates.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    setUpcomingSl(dates.slice(0, 5));
  }, [subscription, cJobs, aJobs, eJobs]);

  return (
    <div className="upcoming-syncs">
      <Card>
        <CardContent>
          <h2 className="title">Upcoming Syncs</h2>
          <small></small>
          <div className="dates">
            <div className="hubspot">
              <h3>From Slate to HubSpot</h3>
              {upcomingHs?.map((job, i) => {
                return (
                  <p key={`hs-date-${i}`}>
                    {job.date} : : <strong>{job.record}</strong>
                  </p>
                );
              })}
              {upcomingHs.length === 0 && <p className="red">No upcoming syncs</p>}
            </div>
            <div className="slate">
              <h3>From HubSpot to Slate</h3>
              {upcomingSl?.map((job, i) => {
                return (
                  <p key={`sl-date-${i}`}>
                    {job.date} : : <strong>{job.record}</strong>
                  </p>
                );
              })}
              {upcomingSl.length === 0 && <p className="red">No upcoming syncs</p>}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
