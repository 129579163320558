import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../../../globalAlerts";

export default function useArchiveFieldMaps(record, ids) {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async () => {
      const token = await getAccessTokenSilently();

      return await axios
        .put(
          `/${record}/mappings/field/archive`,
          {
            ids: ids,
            tenant: metadata.username,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (res) => {
          if (record === "engagements") {
            await axios
              .get("/engagements/jobs/subrecords", {
                params: {
                  tenant: metadata.username,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .catch((err) => err);
          }

          return res.data;
        });
    },
    {
      onSettled: () => {
        if (record === "engagements") {
          queryClient.invalidateQueries("reqMappings");
          queryClient.invalidateQueries(["jobs", "engagements"]);
        }
      },
      onSuccess: () => {
        alertCtx.success("Field Mapping(s) successfully archived");

        queryClient.setQueryData(["fieldMaps", record], (old) => {
          return {
            toHubSpot: {
              active: old.toHubSpot.active.filter((val) => {
                if (!ids.includes(val.id)) {
                  return val;
                }

                return false;
              }),
              archived: [
                ...old.toHubSpot.active.filter((val) => {
                  if (ids.includes(val.id)) {
                    return val;
                  }

                  return false;
                }),
                ...old.toHubSpot.archived,
              ],
            },
            toSlate: {
              active: old.toSlate.active.filter((val) => {
                if (!ids.includes(val.id)) {
                  return val;
                }

                return false;
              }),
              archived: [
                ...old.toSlate.active.filter((val) => {
                  if (ids.includes(val.id)) {
                    return val;
                  }

                  return false;
                }),
                ...old.toSlate.archived,
              ],
            },
          };
        });
      },
      onError: () => {
        alertCtx.error(
          "Something went wrong, please try again later. If the problem persists, contact support."
        );
      },
    }
  );
}
