import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../../../globalAlerts";

export default function useSyncToSl(record, job, rows, test) {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async () => {
      return new Promise(async (resolve, reject) => {
        const token = await getAccessTokenSilently();

        return await axios
          .post(
            `/${record}/sync/slate`,
            {
              tenant: metadata.username,
              jobID: job?.id,
              subrecord: job?.subrecord,
              // For test send only
              rows: rows,
              testBool: test,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => resolve(res.data))
          .catch((err) => reject(err));
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["syncHistory", record]);
        queryClient.invalidateQueries("account");
        queryClient.invalidateQueries("limits");
        queryClient.invalidateQueries("notifications");
        queryClient.invalidateQueries("syncStats");

        const totalRecords =
          data?.details?.rCreated + data?.details?.rUpdated + data?.details?.rFailed;

        if (data?.test) {
          alertCtx.success("Test data has been sent to Slate.");
        } else if (data.status === 426) {
          alertCtx.warning(
            `You have already reached your ${record} limit. In order to continue syncing, please upgrade your account or wait until your limit resets.`
          );
        } else if (totalRecords === 0) {
          alertCtx.info("Sync stopped as there are no new updates to process.");
        } else if (data?.max) {
          alertCtx.warning(
            `Sync complete, but you have reached your monthly ${record} limit in the process. It is likely only some ${record} were synced.`
          );
        } else if (data.details.rFailed === 0) {
          alertCtx.success(`Sync successful! Please check your sync history for more details.`);
        } else if (
          data.details.rCreated === 0 &&
          data.details.rUpdated === 0 &&
          data.details.rFailed > 0
        ) {
          alertCtx.error(
            `Sync completely failed. Please check your sync history for more details.`
          );
        } else {
          alertCtx.warning(
            `Sync complete, but some ${record} have failed to sync. Please check your sync history for more details.`
          );
        }
      },
      onError: (err) => {
        queryClient.invalidateQueries("syncHistory");
        queryClient.invalidateQueries("account");
        queryClient.invalidateQueries("limits");
        queryClient.invalidateQueries(["jobs", record]);
        queryClient.invalidateQueries("notifications");
        queryClient.invalidateQueries("syncStats");

        if (err.response.status === 426) {
          alertCtx.warning(
            `You have already reached your ${record} limit. In order to continue syncing, please upgrade your account or wait until your limit resets.`
          );
        } else if (err.response.status === 404) {
          alertCtx.error(
            `The HubSpot List associated with this job no longer exists. Please remove this job and replace it if desired.`
          );
        } else {
          alertCtx.error(
            `Something went wrong. Please check your sync history, notifications, or email for more details.`
          );
        }
      },
    }
  );
}
