import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Box, Tab, Card, CardContent } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BubbleChart, EditRounded } from "@mui/icons-material";
import Xarrow, { Xwrapper } from "react-xarrows";
import useGetHsFieldOptions from "../hooks/GET/useGetHsFieldOptions";
import useGetValueMaps from "../hooks/GET/useGetValueMaps";
import useGetFieldMapById from "../hooks/GET/useGetFieldMapById";
import Table from "../modules/Table.js";
import ValueMapsModal from "../modals/ValueMapsModal";
import ValueMapsAlerts from "../alerts/ValueMapsAlerts";

export default function ValueMapsTable(props) {
  const { id } = useParams();
  const {
    record,
    setMarker,
    state,
    setCount,
    direction,
    hsConnect,
    slConnect,
    setSelectionModel,
    setSaveModalState,
    setEdit,
    setSubmit,
    setDeleteModalState,
    setSelectedRows,
  } = props;
  const [update, setUpdate] = useState("visualize");
  const [processHsValueMaps, setProcessHsValueMaps] = useState(null);
  const [processSlValueMaps, setProcessSlValueMaps] = useState(null);
  const [uniqueSlValueMaps, setUniqueSlValueMaps] = useState(null);
  const [uniqueHsValueMaps, setUniqueHsValueMaps] = useState(null);

  const { isLoading, isFetching, data } = useGetValueMaps(record, id);
  const valueMaps = data?.[direction]?.["active"];

  const { isLoading: fieldMapLoading, data: fieldMap } = useGetFieldMapById(record, id);
  const { isLoading: hsFieldOptsIsLoading, data: hsFieldOpts } = useGetHsFieldOptions(id);
  let columns = [
    {
      field: "Actions",
      type: "actions",
      width: 40,
      getActions: (params) => [
        <GridActionsCellItem
          onClick={() => {
            setSelectionModel([params.id]);
            setSelectedRows([params.row]);
            setSaveModalState(true);
            setEdit(true);
            setSubmit(true);
          }}
          label="Edit"
          showInMenu
        />,
        <GridActionsCellItem
          onClick={() => {
            setSelectionModel([params.id]);
            setSelectedRows([params.row]);
            setDeleteModalState(true);
          }}
          label="Delete"
          showInMenu
        />,
      ],
    },
  ];

  if (direction === "toHubSpot") {
    columns = [
      {
        field: "sl_value",
        headerName: "Slate Values",
        width: 300,
        editable: false,
      },
      {
        field: "hs_value",
        headerName: "HubSpot Values",
        width: 300,
        editable: false,
      },
      ...columns,
    ];
  } else {
    columns = [
      {
        field: "hs_value",
        headerName: "HubSpot Values",
        width: 300,
        editable: false,
      },
      {
        field: "sl_value",
        headerName: "Slate Values",
        width: 300,
        editable: false,
      },
      ...columns,
    ];
  }

  useEffect(() => {
    if (fieldMap?.archived) {
      setMarker("archived");
    } else if (update === "visualize") {
      setMarker("visualize");
    } else {
      setMarker(null);
    }

    if (valueMaps?.length > 0) {
      setCount(valueMaps?.length);
    }

    if (!data?.status && !hsFieldOpts?.status && !isLoading && !isFetching) {
      // ======================== CONVERT VALUES TO ARR OF OBJS  ===============================

      // Place all associated values and shared keys for each key into an associative object
      let hsValueMaps = {};
      valueMaps.forEach((map) => {
        hsValueMaps[map.hs_value] = { ...hsValueMaps[map.hs_value] };

        if (hsValueMaps[map.hs_value]?.values?.length > 0) {
          hsValueMaps[map.hs_value]["values"].push(map.sl_value);
        } else {
          hsValueMaps[map.hs_value]["values"] = [map.sl_value];
        }

        valueMaps.forEach((innerMap) => {
          if (map.hs_value !== innerMap.hs_value && map.sl_value === innerMap.sl_value) {
            if (hsValueMaps[map.hs_value]?.shared?.length > 0) {
              hsValueMaps[map.hs_value]["shared"].push(innerMap.hs_value);
            } else {
              hsValueMaps[map.hs_value]["shared"] = [innerMap.hs_value];
            }
          }
        });
      });

      // Convert object of properties into array of objects for easier sorting
      hsValueMaps = Object.entries(hsValueMaps).map((e) => ({ [e[0]]: e[1] }));

      // Place all associated values and shared keys for each key into an associative object
      let slValueMaps = {};
      valueMaps.forEach((map) => {
        slValueMaps[map.sl_value] = { ...slValueMaps[map.sl_value] };

        if (slValueMaps[map.sl_value]?.values?.length > 0) {
          slValueMaps[map.sl_value]["values"].push(map.hs_value);
        } else {
          slValueMaps[map.sl_value]["values"] = [map.hs_value];
        }

        valueMaps.forEach((innerMap) => {
          if (map.sl_value !== innerMap.sl_value && map.hs_value === innerMap.hs_value) {
            if (slValueMaps[map.sl_value]?.shared?.length > 0) {
              slValueMaps[map.sl_value]["shared"].push(innerMap.sl_value);
            } else {
              slValueMaps[map.sl_value]["shared"] = [innerMap.sl_value];
            }
          }
        });
      });

      // Convert object of properties into array of objects for easier sorting
      slValueMaps = Object.entries(slValueMaps).map((e) => ({ [e[0]]: e[1] }));

      // Get reverse value count
      slValueMaps.forEach((slMap) => {
        const slEntries = Object.entries(slMap)[0];
        const slKey = slEntries[0];
        const slVals = slEntries[1];
        let revValCount = 0;

        hsValueMaps.forEach((hsMap) => {
          const hsEntries = Object.entries(hsMap)[0];
          const hsKey = hsEntries[0];
          const hsVals = hsEntries[1];

          if (slVals.values.includes(hsKey)) {
            revValCount += hsVals.values.length;
          }
        });

        slMap[slKey]["revValCount"] = revValCount;
      });

      // ======================== SLATE VALUES SORT ===============================

      // Sort by most values to least values
      slValueMaps = slValueMaps.sort((a, b) => {
        const objCurr = Object.values(a)[0];
        const objNext = Object.values(b)[0];

        return objNext.values.length - objCurr.values.length;
      });

      // Sort so associated objects of previous keys appear just after said key
      let slValueMapsTemp = slValueMaps.map((map) => map);
      let slValueMapsTempKeys = slValueMapsTemp.map((tempMap) => Object.keys(tempMap)[0]);

      let offsetBefore = 0;
      slValueMaps.forEach((outerMap, i) => {
        let offsetAfter = 0;

        const objOuter = Object.values(outerMap)[0];
        objOuter?.shared?.forEach((item) => {
          slValueMaps.forEach((innerMap, j) => {
            const objInner = Object.keys(innerMap)[0];

            if (i < j && item === objInner) {
              const currKey = Object.keys(outerMap)[0];
              slValueMapsTempKeys = slValueMapsTemp.map((tempMap) => Object.keys(tempMap)[0]);
              const currIndex = slValueMapsTempKeys.indexOf(currKey);
              const matchIndex = slValueMapsTempKeys.indexOf(objInner);
              const move = slValueMapsTemp.splice(matchIndex, 1)[0];
              const moveVals = Object.values(move)[0];

              if (moveVals.revValCount < objOuter.revValCount) {
                slValueMapsTemp.splice(currIndex, 0, move);
              } else {
                slValueMapsTemp.splice(currIndex + 1 + offsetAfter + offsetBefore, 0, move);
              }

              offsetAfter++;
              slValueMapsTempKeys = slValueMapsTemp.map((tempMap) => Object.keys(tempMap)[0]);
            }
          });
        });
      });

      setProcessSlValueMaps(Object.assign({}, ...slValueMaps));

      // Get just array of keys for final display
      slValueMaps = slValueMapsTemp.map((tempMap) => Object.keys(tempMap)[0]);
      setUniqueSlValueMaps(slValueMaps);

      // ======================== HUBSPOT VALUES SORT ===============================

      // Sort by least values to most values
      hsValueMaps = hsValueMaps.sort((a, b) => {
        const objCurr = Object.values(a)[0];
        const objNext = Object.values(b)[0];

        return objCurr.values.length - objNext.values.length;
      });

      // Reorder once more so it follows the order of the slate keys
      let hsValueMapsTemp = [];
      slValueMaps.forEach((slMap) => {
        hsValueMaps.forEach((hsMap) => {
          if (
            Object.values(hsMap)[0].values.includes(slMap) &&
            !hsValueMapsTemp.includes(Object.keys(hsMap)[0])
          ) {
            hsValueMapsTemp.push(Object.keys(hsMap)[0]);
          }
        });
      });

      setProcessHsValueMaps(Object.assign({}, ...hsValueMaps));

      // Get just array of keys for final display
      hsValueMaps = hsValueMapsTemp.map((map) => map);
      setUniqueHsValueMaps(hsValueMaps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, valueMaps, fieldMap]);

  if (isLoading || isFetching || fieldMapLoading || hsFieldOptsIsLoading) {
    return (
      <div className="retrieving">
        <CircularProgress />
        <h2 className="process">Retrieving data...</h2>
      </div>
    );
  }

  return (
    <div className="dh-type dh-value-maps">
      <div className="dh-alerts">
        <ValueMapsAlerts
          {...props}
          data={data}
          hsFieldOpts={hsFieldOpts}
          fm_valid={fieldMap.valid}
          fm_archived={fieldMap.archived}
          fm_type={fieldMap.hs_field_type}
          fm_dir={fieldMap.direction}
        />
      </div>
      {!data?.status &&
      !hsFieldOpts?.status &&
      fieldMap.valid &&
      fieldMap.direction === direction &&
      hsConnect.status &&
      (slConnect.status || direction === "toHubSpot") ? (
        <Box className={`dh-tables ${state}`}>
          <TabContext value={update}>
            <div className="tabs">
              <Box>
                <TabList
                  onChange={(e, val) => {
                    setUpdate(val);
                    setSelectionModel([]);
                    if (fieldMap.archived) {
                      setMarker("archived");
                    } else if (val === "visualize") {
                      setMarker(val);
                    } else {
                      setMarker(null);
                    }
                  }}
                >
                  <Tab
                    icon={<BubbleChart />}
                    iconPosition="start"
                    label="Visualize"
                    value="visualize"
                  />
                  <Tab icon={<EditRounded />} iconPosition="start" label="Update" value="update" />
                </TabList>
              </Box>
            </div>
            <TabPanel value="visualize" className="visualize">
              <div className="fields">
                {direction === "toHubSpot" && (
                  <div className="slate">
                    <h4>Slate Field</h4>
                    <h2>{fieldMap.sl_field_label}</h2>
                  </div>
                )}
                <div className="hubspot">
                  <h4>HubSpot Field</h4>
                  <h2>{fieldMap.hs_field_label}</h2>
                </div>
                {direction === "toSlate" && (
                  <div className="slate">
                    <h4>Slate Field</h4>
                    <h2>Slate Field</h2>
                  </div>
                )}
              </div>

              {uniqueSlValueMaps !== null && uniqueHsValueMaps !== null && (
                <Xwrapper>
                  <div className="maps">
                    <div className="side from-slate">
                      {uniqueSlValueMaps.map((map, i) => (
                        <div
                          key={`sl-${i}`}
                          className="map"
                          style={{
                            flexGrow: 0,
                            flexShrink: 0,
                            flexBasis: `${
                              (processSlValueMaps?.[map]?.values?.length /
                                Math.max(uniqueHsValueMaps.length, uniqueSlValueMaps.length)) *
                              100
                            }%`,
                          }}
                        >
                          <div className="inner" id={`sl-${map}`}>
                            {map}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="side to-hubspot">
                      {uniqueHsValueMaps.map((map, i) => (
                        <div
                          key={`hs-${i}`}
                          className="map"
                          style={{
                            flexGrow: 0,
                            flexShrink: 0,
                            flexBasis: `${
                              (processHsValueMaps?.[map]?.values?.length /
                                Math.max(uniqueHsValueMaps.length, uniqueSlValueMaps.length)) *
                              100
                            }%`,
                          }}
                        >
                          <div className="inner" id={`hs-${map}`}>
                            {map}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {valueMaps.map((map, i) => (
                    <Xarrow
                      key={`arrow-${i}`}
                      start={`sl-${map.sl_value}`}
                      end={`hs-${map.hs_value}`}
                      color={direction === "toHubSpot" ? "#2D659C" : "#EA6644"}
                      curveness={0.5}
                      headSize={4}
                      strokeWidth={3}
                    />
                  ))}
                </Xwrapper>
              )}
            </TabPanel>
            <TabPanel value="update" className={`update ${fieldMap.archived ? "disabled" : ""}`}>
              <Table {...props} isLoading={isLoading} data={data} columns={columns} />
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <Card className="dh-error">
          <CardContent>Data could not be displayed.</CardContent>
        </Card>
      )}
      <div className="dh-modals">
        {!data?.status &&
          !hsFieldOpts?.status &&
          hsConnect.status &&
          (slConnect.status || direction === "toHubSpot") && (
            <ValueMapsModal
              {...props}
              hsFieldOpts={hsFieldOpts}
              fieldMapId={fieldMap.id}
              fieldMapType={fieldMap.hs_field_type}
            />
          )}
      </div>
    </div>
  );
}
