import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useGetFieldMapById(record, id) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    [`fieldMap - ${record}`, id],
    async () => {
      try {
        const token = await getAccessTokenSilently();

        const fieldMap = await axios.get(`/${record}/mappings/field/id`, {
          params: {
            tenant: metadata.username,
            id: id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return fieldMap.data;
      } catch (err) {
        return err.response;
      }
    },
    {
      initialData: () => queryClient.getQueryData([`fieldMap - ${record}`, id]),
      staleTime: Infinity,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState([`fieldMap - ${record}`, id])?.dataUpdatedAt,
    }
  );
}
