import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import useAddRow from "../hooks/POST/useAddQuery";
import useUpdateRow from "../hooks/PUT/useUpdateQuery";
import validate from "../utils/validateQueries.js";
import validateSubmit from "../utils/validateSubmit.js";
import { CloseRounded } from "@mui/icons-material";

export default function QueriesModal(props) {
  const {
    record,
    direction,
    setSelectionModel,
    setPage,
    newRow,
    setNewRow,
    rows,
    saveModalState,
    setSaveModalState,
    setSubmit,
    submit,
    setEdit,
    edit,
    dbStruct,
  } = props;
  const addRow = useAddRow(record, newRow, direction);
  const updateRow = useUpdateRow(record, newRow);
  const validateRow = validate(rows, newRow, submit, edit);

  const handleSaveModalClose = () => {
    setSelectionModel([]);
    setNewRow(dbStruct);
    setSubmit(false);
    setEdit(false);
    setSaveModalState(false);
  };

  const handleSaveRow = async (e) => {
    validateSubmit("queries", rows, newRow, true, edit).then(async (res) => {
      if (res.valid) {
        if (!newRow.id) {
          await addRow.mutateAsync().then(() => {
            setPage(0);
            handleSaveModalClose();
          });
        } else {
          await updateRow.mutateAsync().then(() => {
            handleSaveModalClose();
          });
        }
      }
    });
  };

  return (
    <Dialog open={saveModalState} maxWidth="md" fullWidth className="dh-modal save">
      <IconButton onClick={handleSaveModalClose} className="close" aria-label="close">
        <CloseRounded />
      </IconButton>
      <DialogTitle>Add/Update Slate Query</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fill out the fields below to add or update a Slate Query.
        </DialogContentText>
        <form className="dh-modal-form">
          <div className="query-fields">
            <div className="name-sub">
              <TextField
                required
                fullWidth
                id="name"
                label="Name"
                variant="outlined"
                value={newRow.name}
                error={!validateRow.details.name.state}
                helperText={
                  !validateRow.details.name.state ? validateRow.details.name.errorMsg : ""
                }
                onChange={(e) => {
                  setNewRow({ ...newRow, name: e.target.value });
                }}
              />

              {record === "engagements" ? (
                <FormControl
                  error={!validateRow.details.subrecord.filled}
                  required
                  className="subrecord-select"
                >
                  <InputLabel id="subrecord-label">Type</InputLabel>
                  <Select
                    disabled={edit}
                    id="subrecord"
                    labelId="subrecord-label"
                    value={newRow.subrecord}
                    label="Type"
                    onChange={(e) =>
                      setNewRow({
                        ...newRow,
                        subrecord: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="emails">Emails</MenuItem>
                    <MenuItem value="calls">Calls</MenuItem>
                    <MenuItem value="notes">Notes</MenuItem>
                    <MenuItem value="meetings">Meetings</MenuItem>
                    <MenuItem value="postal_mail">Postal Mail</MenuItem>
                  </Select>
                  <FormHelperText className="error-msg">
                    {!validateRow.details.subrecord.filled
                      ? validateRow.details.subrecord.errorMsg
                      : ""}
                  </FormHelperText>
                </FormControl>
              ) : (
                ""
              )}
            </div>
            <TextField
              required
              fullWidth
              id="url"
              label="URL"
              variant="outlined"
              value={newRow.url}
              error={!validateRow.details.url.state}
              helperText={!validateRow.details.url.state ? validateRow.details.url.errorMsg : ""}
              onChange={(e) => {
                setNewRow({ ...newRow, url: e.target.value });
              }}
            />
            <div className="user-pass">
              <TextField
                required
                fullWidth
                id="user"
                label="Username"
                variant="outlined"
                value={newRow.user}
                error={!validateRow.details.user.state}
                helperText={
                  !validateRow.details.user.state ? validateRow.details.user.errorMsg : ""
                }
                onChange={(e) => setNewRow({ ...newRow, user: e.target.value })}
              />
              <TextField
                required
                fullWidth
                type="password"
                id="pass"
                label="Password"
                variant="outlined"
                value={newRow.pass}
                error={!validateRow.details.pass.state}
                helperText={
                  !validateRow.details.pass.state ? validateRow.details.pass.errorMsg : ""
                }
                onChange={(e) => setNewRow({ ...newRow, pass: e.target.value })}
              />
            </div>
          </div>
          <DialogActions className="actions">
            <Button onClick={handleSaveModalClose} variant="text" className="line-btn warning">
              Cancel
            </Button>
            <Button
              variant="contained"
              className="gradient-btn"
              onClick={() => {
                setSubmit(true);
                handleSaveRow();
              }}
            >
              {addRow.isLoading || updateRow.isLoading ? <CircularProgress /> : "Save"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
