import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  ClickAwayListener,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import useSyncToSl from "../hooks/POST/useSyncToSl";

export default function XML(props) {
  const { record, xmlModalState, setXMLModalState, rows } = props;
  const [XMLMap, setXMLMap] = useState();
  const [openTooltip, setOpenTooltip] = useState(false);
  const XMLRef = useRef(null);
  const sendTest = useSyncToSl(record, null, rows, true);

  const generateXMLMap = (rows) => {
    return new Promise((resolve, reject) => {
      let xml = [];

      rows.forEach((row) => {
        xml.push(`<f id="${row.hs_field_label.replace("&", "and")}" s="${row.hs_field_value}" />`);
      });

      resolve(xml);
    });
  };

  const handleCopyXML = () => {
    setOpenTooltip(false);

    setTimeout(() => {
      navigator.clipboard.writeText(XMLRef.current.innerText);
      setOpenTooltip(true);
    }, 250);
  };

  useEffect(() => {
    const opening = '<layout type="json" node="/contacts">';
    const closing = "</layout>";

    generateXMLMap(rows).then((inner) => setXMLMap({ opening, inner, closing }));
  }, [rows]);

  return (
    <div className="dh-xml">
      <Dialog open={xmlModalState} maxWidth="md" className="dh-modal xml">
        <IconButton onClick={() => setXMLModalState(false)} className="close" aria-label="close">
          <CloseRounded />
        </IconButton>
        <DialogTitle>XML Map</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Copy the XML Map below exactly to use as your Source Format in Slate.
          </DialogContentText>
          <div className="console" ref={XMLRef}>
            <code>{XMLMap?.opening}</code>
            <div className="inner">
              {XMLMap?.inner.map((item, i) => (
                <code key={i}>{item}</code>
              ))}
            </div>
            <code>{XMLMap?.closing}</code>
          </div>
          <DialogActions className="actions">
            <Button
              className="line-btn warning"
              variant="text"
              onClick={() => setXMLModalState(false)}
            >
              CLOSE
            </Button>
            <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={openTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied to clipboard!"
              >
                <Button className="gradient-btn" variant="contained" onClick={handleCopyXML}>
                  <span>COPY</span>
                </Button>
              </Tooltip>
            </ClickAwayListener>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Button onClick={() => sendTest.mutate()} className="line-btn dh-test" variant="text">
        Send Test Data
      </Button>
      <Button
        onClick={() => setXMLModalState(true)}
        className="white-btn dh-gen"
        variant="contained"
      >
        Generate XML Map
      </Button>
    </div>
  );
}
