import React, { useEffect } from "react";
import Alerts from "../modules/Alerts";

export default function ValueMapsAlerts(props) {
  const {
    direction,
    data,
    hsFieldOpts,
    fm_valid,
    fm_archived,
    fm_type,
    fm_dir,
    hsConnect,
    slConnect,
  } = props;

  useEffect(() => {}, [data, direction]);

  return (
    <div className="dh-alerts">
      {direction === "toHubSpot" ? (
        <Alerts
          {...props}
          content={{
            info: [
              {
                test: fm_type !== "text",
                title: "'Text' fields are a bit different",
                message:
                  "Unlike other field types, 'text' fields will automatically use the provided value when syncing if no mapping for said value exists here.",
              },
            ],
            warning: [
              {
                test: !fm_archived,
                title: "The parent Field Mapping has been archived",
                message:
                  "Please activate it or create a new Field Mapping to continue with Value Mapping.",
              },
              {
                test:
                  data?.[direction]?.active?.length > 0 ||
                  fm_type === "text" ||
                  fm_dir !== direction,
                title: "No Value Mappings exist",
                message: "This will result in no data being synced for this Field Mapping.",
              },
            ],
            error: [
              {
                test: !data?.status && !hsFieldOpts?.status,
                title: "There was an error retrieving your Value Mappings",
                message:
                  "Please refresh and try again. If the problem persists, please contact support",
              },
              {
                test: fm_valid,
                title: "The parent Field Mapping is invalid",
                message:
                  "In order to be able to adjust Value Mappings, the parent Field Mapping must be valid. This is likely due to a broken Slate Query.",
              },
              {
                test: hsConnect.status,
                title: "Your HubSpot Account is not connected",
                message: "Please connect your account in order to proceed with Value Mapping.",
              },
              {
                test: fm_dir === direction,
                title: "Woops, you're going to wrong way!",
                message:
                  "Looks like your trying to access Value Mappings in the wrong direction. Please return to Field Mappings and click the switch button to access these Value Mappings.",
              },
            ],
          }}
        />
      ) : (
        <Alerts
          {...props}
          minimize={true}
          content={{
            info: [
              {
                title: "HubSpot to Slate Value Mapping is a bit different",
                message:
                  "Unlike some field types for Slate to HubSpot Value Mapping, all fields will automatically use the provided value when syncing if no mapping for said value exists here.",
              },
            ],
            warning: [
              {
                test: !fm_archived,
                title: "The parent Field Mapping has been archived",
                message:
                  "Please activate it or create a new Field Mapping to continue with Value Mapping.",
              },
              {
                test:
                  data?.[direction]?.active?.length > 0 ||
                  fm_type === "text" ||
                  fm_dir !== direction,
                title: "No Value Mappings exist",
                message: "This will result in no data being synced for this Field Mapping.",
              },
            ],
            error: [
              {
                test: !data?.status && !hsFieldOpts?.status,
                title: "There was an error retrieving your Value Mappings",
                message:
                  "Please refresh and try again. If the problem persists, please contact support",
              },
              {
                test: fm_valid,
                title: "The parent Field Mapping is invalid",
                message:
                  "In order to be able to adjust Value Mappings, the parent Field Mapping must be valid. This is likely due to a broken Slate Query.",
              },
              {
                test: hsConnect.status,
                title: "Your HubSpot Account is not connected",
                message: "Please connect your account in order to proceed with Value Mapping.",
              },
              {
                test: slConnect.status,
                title: "Your Slate Account is not connected",
                message: "Please connect your account in order to proceed with Value Mapping.",
              },
              {
                test: fm_dir === direction,
                title: "Woops, you're going to wrong way!",
                message:
                  "Looks like your trying to access Value Mappings in the wrong direction. Please return to Field Mappings and click the switch button to access these Value Mappings.",
              },
            ],
          }}
        />
      )}
    </div>
  );
}
