import React, { useEffect, useState } from "react";
import {
  CheckCircleOutlineRounded,
  HighlightOffRounded,
  InfoOutlined,
  WarningAmberRounded,
  RemoveRounded,
} from "@mui/icons-material";
import { Badge, IconButton } from "@mui/material";
import parse from "html-react-parser";

export default function Alerts(props) {
  const { record, content, hidable = true } = props;
  const [success, setSuccess] = useState(true);
  const [info, setInfo] = useState(true);
  const [warning, setWarning] = useState(true);
  const [error, setError] = useState(true);

  const handleAlertState = (type) => {
    if (type === "success") setSuccess(!success);
    if (type === "info") setInfo(!info);
    if (type === "warning") setWarning(!warning);
    if (type === "error") setError(!error);
  };

  useEffect(() => {}, [content]);

  return (
    <div className="alerts">
      {content?.success?.some(
        (alert) => !alert?.test && (alert.record === record || alert.record === undefined)
      ) && (
        <div className={`success alert ${success ? "open" : "close"}`}>
          {hidable && (
            <IconButton
              onClick={() => handleAlertState("success")}
              className="hide"
              aria-label="hide"
            >
              <RemoveRounded />
            </IconButton>
          )}
          <div className="msg-wrap">
            <div className="icon">
              <CheckCircleOutlineRounded />
            </div>
            <ul className="messages">
              {content.success.map((alert, i) => {
                if (!alert?.test && (alert.record === record || alert.record === undefined)) {
                  return (
                    <li key={i} className="message">
                      <h4>{parse(alert.title)}</h4>
                      <small>{parse(alert.message)}</small>
                    </li>
                  );
                } else {
                  return false;
                }
              })}
            </ul>
          </div>
        </div>
      )}
      {content?.info?.some(
        (alert) => !alert?.test && (alert.record === record || alert.record === undefined)
      ) && (
        <div className={`info alert ${info ? "open" : "close"}`}>
          {hidable && (
            <IconButton onClick={() => handleAlertState("info")} className="hide" aria-label="hide">
              <RemoveRounded />
            </IconButton>
          )}

          <div className="msg-wrap">
            <div className="icon">
              <InfoOutlined />
            </div>
            <ul className="messages">
              {content.info.map((alert, i) => {
                if (!alert?.test && (alert.record === record || alert.record === undefined)) {
                  return (
                    <li key={i} className="message">
                      <h4>{parse(alert.title)}</h4>
                      <small>{parse(alert.message)}</small>
                    </li>
                  );
                } else {
                  return false;
                }
              })}
            </ul>
          </div>
        </div>
      )}
      {content?.warning?.some(
        (alert) => !alert?.test && (alert.record === record || alert.record === undefined)
      ) && (
        <div className={`warning alert ${warning ? "open" : "close"}`}>
          <IconButton
            onClick={() => handleAlertState("warning")}
            className="hide"
            aria-label="hide"
          >
            <RemoveRounded />
          </IconButton>
          <div className="msg-wrap">
            <div className="icon">
              <WarningAmberRounded />
            </div>
            <ul className="messages">
              {content.warning.map((alert, i) => {
                if (!alert?.test && (alert.record === record || alert.record === undefined)) {
                  return (
                    <li key={i} className="message">
                      <h4>{parse(alert.title)}</h4>
                      <small>{parse(alert.message)}</small>
                    </li>
                  );
                } else {
                  return false;
                }
              })}
            </ul>
          </div>
        </div>
      )}
      {content?.error?.some(
        (alert) => !alert?.test && (alert.record === record || alert.record === undefined)
      ) && (
        <div className={`error alert ${error ? "open" : "close"}`}>
          <IconButton onClick={() => handleAlertState("error")} className="hide" aria-label="hide">
            <RemoveRounded />
          </IconButton>
          <div className="msg-wrap">
            <div className="icon">
              <HighlightOffRounded />
            </div>
            <ul className="messages">
              {content.error.map((alert, i) => {
                if (!alert?.test && (alert.record === record || alert.record === undefined)) {
                  return (
                    <li key={i} className="message">
                      <h4>{parse(alert.title)}</h4>
                      <small>{parse(alert.message)}</small>
                    </li>
                  );
                } else {
                  return false;
                }
              })}
            </ul>
          </div>
        </div>
      )}

      {hidable && (
        <div className="tray">
          {content?.success?.some(
            (alert) => !alert?.test && (alert.record === record || alert.record === undefined)
          ) && (
            <IconButton
              onClick={() => handleAlertState("success")}
              size="small"
              className="open"
              aria-label="open success"
            >
              <Badge variant="dot" overlap="circular" color="error">
                <CheckCircleOutlineRounded />
              </Badge>
            </IconButton>
          )}
          {content?.info?.some(
            (alert) => !alert?.test && (alert.record === record || alert.record === undefined)
          ) && (
            <IconButton
              onClick={() => handleAlertState("info")}
              size="small"
              className="open"
              aria-label="open info"
            >
              <Badge variant="dot" overlap="circular" color="error">
                <InfoOutlined />
              </Badge>
            </IconButton>
          )}
          {content?.warning?.some(
            (alert) => !alert?.test && (alert.record === record || alert.record === undefined)
          ) && (
            <IconButton
              onClick={() => handleAlertState("warning")}
              size="small"
              className="open"
              aria-label="open warning"
            >
              <Badge variant="dot" overlap="circular" color="error">
                <WarningAmberRounded />
              </Badge>
            </IconButton>
          )}
          {content?.error?.some(
            (alert) => !alert?.test && (alert.record === record || alert.record === undefined)
          ) && (
            <IconButton
              onClick={() => handleAlertState("error")}
              size="small"
              className="open"
              aria-label="open error"
            >
              <Badge variant="dot" overlap="circular" color="error">
                <HighlightOffRounded />
              </Badge>
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
}
