import React, { useEffect } from "react";
import Alerts from "../modules/Alerts";

export default function JobsAlerts(props) {
  const { direction, data, queries, hsLists, hsConnect, slConnect, reqMaps } = props;

  useEffect(() => {}, [data, direction]);

  return (
    <div className="dh-alerts">
      {direction === "toHubSpot" ? (
        <Alerts
          {...props}
          content={{
            info: [
              {
                title: "Jobs cannot be changed once created",
                message:
                  "If you would like to change a created job, simply delete said job and create a new one.",
              },
              {
                title: "Any contacts created in HubSpot are set as NON-marketing contacts",
                message:
                  "Contacts can be made into marketing contacts using HubSpot workflows or by updating the integration settings in HubSpot.",
                record: "contacts",
              },
              {
                title: "Application to Contact associations may take upwards of 15mins",
                message:
                  "The more new Applications that are created during a sync, the longer it will take for them to be automatically associated with Contacts.",
                record: "applications",
              },
              {
                title: "Only some Applications will sync",
                message:
                  "Only Applications with existing matching Contacts in HubSpot and values for all required fields, will be synced.",
                record: "applications",
              },
              {
                title: "Only some Engagements will sync",
                message:
                  "Only Engagements with existing matching Contacts in HubSpot and values for all required fields, will be synced. The rest will be ignored.",
                record: "engagements",
              },
            ],
            warning: [
              {
                test:
                  !queries?.toHubSpot?.active?.some((query) => query?.valid === 0) ||
                  queries?.toHubSpot?.active?.every((query) => query?.valid === 0),
                title: "One or more of your Slate Queries is invalid",
                message:
                  "This may result in some queries being unavailable and some sync schedules being marked as invalid.",
              },
            ],
            error: [
              {
                test: !data?.status && !queries?.status,
                title: "There was an error retrieving your Sync Schedule",
                message:
                  "Please refresh and try again. If the problem persists, please contact support",
              },
              {
                test:
                  queries?.toHubSpot?.active?.length &&
                  !queries?.toHubSpot?.active?.every((query) => query?.valid === 0),
                title: "You have not added any valid Slate Queries",
                message:
                  "Please add at least one valid Slate Query to create Slate to HubSpot Syncs.",
              },
              {
                test: hsConnect.status,
                title: "Your HubSpot Account is not connected",
                message:
                  "Please connect your account in order to proceed with Slate to HubSpot syncing.",
              },
              {
                test:
                  reqMaps?.emails ||
                  reqMaps?.calls ||
                  reqMaps?.notes ||
                  reqMaps?.meetings ||
                  reqMaps?.postal,
                title: "You are missing required Field Mappings",
                message:
                  "You do not have all the required Field Mappings for any of the Engagement Job types. Please add said Field Mappings to proceed.",
                record: "engagements",
              },
              {
                test: reqMaps?.apps,
                title: "You are missing required Field Mappings",
                message:
                  "You do not have all the required Field Mappings for Application Sync Jobs. Please add said Field Mappings to proceed.",
                record: "applications",
              },
            ],
          }}
        />
      ) : (
        <Alerts
          {...props}
          minimize={true}
          content={{
            info: [
              {
                title: "Lists have a maximum size",
                message:
                  "Jobs can only be created with HubSpot lists of 10,000 contacts or less. If you need to sync more, create an additional job with another list.",
              },
              {
                title: "Jobs cannot be changed once created.",
                message:
                  "If you would like to change a created job, simply delete said job and create a new one.",
              },
              {
                title: "Syncing may take time",
                message: "Slate can take up to 15 minutes to actually process any given sync.",
              },
            ],
            warning: [
              {
                test: hsLists.length,
                title: "Your HubSpot account contains no lists",
                message:
                  "Filtering by a HubSpot list is required in order to sync from HubSpot to Slate.",
              },
            ],
            error: [
              {
                test: !data?.status && !hsLists?.status,
                title: "There was an error retrieving your Sync Schedule",
                message:
                  "Please refresh and try again. If the problem persists, please contact support",
              },
              {
                test: hsConnect.status,
                title: "Your HubSpot Account is not connected",
                message:
                  "Please connect your account in order to proceed with Slate to HubSpot Field syncing.",
              },
              {
                test: slConnect.status,
                title: "Your Slate Account is not connected",
                message:
                  "Please connect your account in order to proceed with HubSpot to Slate syncing.",
              },
            ],
          }}
        />
      )}
    </div>
  );
}
