import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";
import dirDataFormat from "../../utils/dirDataFormat";

export default function useGetQueries(record) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    ["queries", record],
    async () => {
      try {
        const token = await getAccessTokenSilently();

        const queries = await axios.get(`/${record}/queries`, {
          params: {
            tenant: metadata.username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const formattedQueries = await dirDataFormat(queries);
        return formattedQueries;
      } catch (err) {
        return err.response;
      }
    },
    {
      initialData: () => queryClient.getQueryData(["queries", record]),
      staleTime: Infinity,
      initialDataUpdatedAt: () => queryClient.getQueryState(["queries", record])?.dataUpdatedAt,
    }
  );
}
