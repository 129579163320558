import React from "react";
import { CircularProgress } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Router from "./Router";
import { GlobalAlertsProvider } from "./globalAlerts";

const State = () => {
  const { isLoading, user } = useAuth0();

  if (isLoading) {
    return (
      <div className="loading">
        <h1 className="process">Loading...</h1>
        <CircularProgress />
      </div>
    );
  }

  return (
    <GlobalAlertsProvider>
      <Router user={user} />
    </GlobalAlertsProvider>
  );
};

export default State;
