import React from "react";
import { Button } from "@mui/material";

export default function Edit(props) {
  const { setSaveModalState, setEdit, setSubmit } = props;

  return (
    <div className="dh-edit">
      <Button
        onClick={() => {
          setSaveModalState(true);
          setEdit(true);
          setSubmit(true);
        }}
        variant="contained"
        className="white-btn"
      >
        Edit
      </Button>
    </div>
  );
}
