import React from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import DataHub from "../../components/data-hub/DataHub";

export default function SyncSchedule(props) {
  return (
    <Container className="contacts sync-jobs">
      <Helmet>
        <title>Sync Schedule | Contacts</title>
      </Helmet>
      <div className="content-wrapper">
        <DataHub {...props} record="contacts" screen="jobs" title="Sync Schedule" />
      </div>
    </Container>
  );
}
