import React, { useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Avatar,
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import { Link } from "react-router-dom";
import AuthenticationButton from "./AuthenticationButton";
import LogoutButton from "./LogoutButton";
import NotificationsPop from "./NotificationsPop";
import avatar from "../media/images/icons/avatar.svg";

export default function Navigation(props) {
  const { user, subscription } = props;

  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const openSettings = Boolean(anchorElSettings);
  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };
  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };

  return (
    <div className="navigation">
      <Box className="nav-area" sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <AuthenticationButton subscription={subscription} />
        {user && subscription?.id > 0 && <NotificationsPop />}
        {user && subscription?.id > 0 && (
          <Tooltip title="Account settings" placement="bottom-start">
            <IconButton onClick={handleClickSettings} size="small" sx={{ ml: 2 }}>
              <Avatar
                sx={{ width: 32, height: 32 }}
                src={avatar}
                alt="Account settings"
                className="profile-icon"
              />
            </IconButton>
          </Tooltip>
        )}
        {user && (
          <Menu
            anchorEl={anchorElSettings}
            open={openSettings}
            onClose={handleCloseSettings}
            onClick={handleCloseSettings}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem component={Link} to="/settings" state={{ startTab: "profile" }}>
              <Avatar src={user.picture} alt={user.name} /> Profile
            </MenuItem>
            <Divider />
            <MenuItem component={Link} to="/settings">
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <LogoutButton />
          </Menu>
        )}
      </Box>
    </div>
  );
}
