import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useCheckSlConnection() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    "slConnected",
    async () => {
      let slateObj = {
        settings: null,
        status: false,
      };

      const token = await getAccessTokenSilently();

      await axios
        .get("/database/settings", {
          params: {
            tenant: metadata.username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.sl_url) {
            slateObj = { ...slateObj, settings: res.data, status: true };
          } else {
            slateObj = { ...slateObj, settings: res.data, status: false };
          }

          return res.data;
        })
        .catch((err) => err);

      return slateObj;
    },
    {
      initialData: () => queryClient.getQueryData("slConnected"),
      staleTime: 10000,
      initialDataUpdatedAt: () => queryClient.getQueryState("slConnected")?.dataUpdatedAt,
    }
  );
}
