import { useContext } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../globalAlerts";

export default function useResetPassword(email) {
  const alertCtx = useContext(GlobalAlertsContext);
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async () => {
      const token = await getAccessTokenSilently();

      return await axios
        .put(
          "/auth0/password/reset",
          {
            tenant: metadata.username,
            email: email,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        alertCtx.info("A password reset link has been sent to your email.");
      },
      onError: () => {
        alertCtx.error(
          "Something went wrong, please try again later. If the problem persists, contact support."
        );
      },
    }
  );
}
