import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../../../globalAlerts";

export default function useRefreshQuery(record, query) {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async () => {
      const token = await getAccessTokenSilently();

      return await axios
        .put(
          `/${record}/query/refresh`,
          {
            tenant: metadata.username,
            query: query,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        alertCtx.success("Slate Query successfully refreshed");
      },
      onSettled: (data, err) => {
        queryClient.setQueryData(["queries", record], (old) => {
          err ? (query.valid = false) : (query.valid = true);

          return {
            toHubSpot: {
              active: old.toHubSpot.active.map((val) => {
                return val.id === query.id ? query : val;
              }),
              archived: old.toHubSpot.archived,
            },
            toSlate: {
              active: old.toSlate.active.map((val) => {
                return val.id === query.id ? query : val;
              }),
              archived: old.toSlate.archived,
            },
          };
        });

        queryClient.invalidateQueries(["fieldMaps", record]);
        queryClient.invalidateQueries([`slFields - ${record}`, "toHubSpot"]);
        queryClient.invalidateQueries([`hsFields - ${record}`, "toHubSpot"]);
        queryClient.invalidateQueries(["jobs", record]);
      },
      onError: (err) => {
        if (err.response.status === 400 && record === "contacts") {
          alertCtx.error(
            "This query does not contain the 'Email' and/or 'Updated' fields, which are required. Please check your query and try again."
          );
        } else if (err.response.status === 403) {
          alertCtx.error(
            "This query has incorrect credentials. Please check your query and try again."
          );
        } else if (err.response.status === 503) {
          alertCtx.warning(
            "This Slate Query returned no data. Please check your Slate Query and make sure it returns at least 1 valid record."
          );
        } else {
          alertCtx.error(
            "Something went wrong, please try again later. If the problem persists, contact support."
          );
        }
      },
    }
  );
}
