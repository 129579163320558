import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useGetSettings() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    "settings",
    async () => {
      const token = await getAccessTokenSilently();

      const config = {
        params: {
          tenant: metadata.username,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return await axios
        .get(`/database/settings`, config)
        .then((res) => res.data)
        .catch((err) => err);
    },
    {
      initialData: () => queryClient.getQueryData("settings"),
      staleTime: Infinity,
      initialDataUpdatedAt: () => queryClient.getQueryState("settings")?.dataUpdatedAt,
    }
  );
}
