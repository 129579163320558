import React from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import DataHub from "../../components/data-hub/DataHub";

export default function FieldMappings(props) {
  return (
    <Container className="contacts field-mappings">
      <Helmet>
        <title>Field Mappings | Contacts</title>
      </Helmet>
      <div className="content-wrapper">
        <DataHub {...props} record="contacts" screen="field_maps" title="Field Mappings" />
      </div>
    </Container>
  );
}
