import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress, Button, Container } from "@mui/material";
import { CheckCircleOutlineRounded, HighlightOffRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
const axios = require("axios");

export default function Authentication() {
  const [authenticated, setAuthenticated] = useState("Load");
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  const auth = async () => {
    const token = await getAccessTokenSilently();
    const platform = window.location.pathname.match(/([^/]*)\/*$/)[1];
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const entry of urlParams.entries()) {
      params[entry[0]] = entry[1];
    }

    const connect = await axios
      .post(
        `/database/connect/${platform}`,
        {
          tenant: metadata.username,
          params: params,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res)
      .catch((err) => err);

    await axios
      .post(
        `/applications/schema`,
        {
          tenant: metadata.username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res)
      .catch((err) => err);

    return connect;
  };

  useEffect(() => {
    auth().then((res) => {
      if (res.data?.status === 200) {
        setAuthenticated("Success");
      } else {
        setAuthenticated("Fail");
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authenticated === "Load") {
    return (
      <Container className="authentication center">
        <div className="loading">
          <h1>Authenticating...</h1>
          <CircularProgress />
        </div>
      </Container>
    );
  }

  return (
    <Container className="authentication center">
      {authenticated === "Success" ? (
        <div>
          <CheckCircleOutlineRounded className="green" sx={{ fontSize: 100 }} />
          <br></br>
          <h1>Authenticated</h1>
          <Button
            component={Link}
            to={"/settings"}
            state={{ startTab: "connections" }}
            variant="contained"
            className="white-btn"
          >
            Return to Connections
          </Button>
        </div>
      ) : (
        <div>
          <HighlightOffRounded className="red" sx={{ fontSize: 100 }} />
          <br></br>
          <h1>Authentication Failed</h1>
          <Button
            component={Link}
            to={"/settings"}
            state={{ startTab: "connections" }}
            variant="contained"
            className="white-btn"
          >
            Return to Connections
          </Button>
        </div>
      )}
    </Container>
  );
}
