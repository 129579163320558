import React, { useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  Autocomplete,
} from "@mui/material";
import useAddRow from "../hooks/POST/useAddFieldMap";
import useUpdateRow from "../hooks/PUT/useUpdateFieldMap";
import validate from "../utils/validateFieldMaps.js";
import validateSubmit from "../utils/validateSubmit.js";
import { ArrowCircleRightOutlined, CloseRounded } from "@mui/icons-material";

export default function FieldMapsModal(props) {
  const {
    record,
    direction,
    setSelectionModel,
    selectionModel,
    setPage,
    newRow,
    setNewRow,
    rows,
    saveModalState,
    setSaveModalState,
    setSubmit,
    submit,
    setEdit,
    edit,
    hsFields,
    slFields,
  } = props;
  const addRow = useAddRow(record, newRow, direction);
  const updateRow = useUpdateRow(record, newRow);
  const validateRow = validate(rows, newRow, submit, edit);

  let dbStruct = {
    id: null,
    hs_field_value: null,
    hs_field_label: null,
    hs_field_type: null,
    direction: direction,
    record: record,
    valid: true,
  };

  if (direction === "toHubSpot") {
    dbStruct = {
      ...dbStruct,
      sl_field_value: null,
      sl_field_label: null,
      sl_field_type: null,
    };
  }

  if (record === "engagements") {
    dbStruct = {
      ...dbStruct,
      subrecord: "",
    };
  }

  const handleSaveModalClose = () => {
    setSelectionModel([]);
    setNewRow(dbStruct);
    setSubmit(false);
    setEdit(false);
    setSaveModalState(false);
  };

  useEffect(() => {
    if (!edit) {
      setNewRow(dbStruct);
    } else {
      const selectedRow = rows.filter((row) => row.id === selectionModel[0]);

      let currRow = {
        hs_field_label: selectedRow[0]?.hs_field_label,
        hs_field_value: selectedRow[0]?.hs_field_value,
        hs_field_type: selectedRow[0]?.hs_field_type,
        id: selectedRow[0]?.id,
        direction: direction,
        valid: true,
      };

      if (direction === "toHubSpot") {
        currRow = {
          ...currRow,
          sl_field_label: selectedRow[0]?.sl_field_label,
          sl_field_value: selectedRow[0]?.sl_field_value,
          sl_field_type: selectedRow[0]?.sl_field_type,
        };
      }

      if (record === "engagements") {
        currRow = {
          ...currRow,
          subrecord: selectedRow[0]?.hs_field_type,
        };
      }

      setNewRow(currRow);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveModalState]);

  const handleSaveRow = async (e) => {
    validateSubmit("fieldMaps", rows, newRow, true, edit).then(async (res) => {
      if (res.valid) {
        if (!newRow.id) {
          await addRow.mutateAsync().then(() => {
            setPage(0);
            handleSaveModalClose();
          });
        } else {
          await updateRow.mutateAsync().then(() => {
            handleSaveModalClose();
          });
        }
      }
    });
  };

  return (
    <div className="dh-field-maps-form">
      <Dialog open={saveModalState} maxWidth="md" fullWidth className="dh-modal save">
        <IconButton onClick={handleSaveModalClose} className="close" aria-label="close">
          <CloseRounded />
        </IconButton>
        <DialogTitle>Add/Update Field Mapping</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill out the fields below to add or update a Field Mapping.
          </DialogContentText>
          <form className="dh-modal-form">
            <div className="field-map-selects">
              {direction === "toHubSpot" && (
                <Autocomplete
                  id="sl_fields"
                  value={
                    newRow.sl_field_value
                      ? {
                          sl_field_label: newRow?.sl_field_label,
                          sl_field_value: newRow?.sl_field_value,
                          sl_field_type: newRow?.sl_field_type,
                        }
                      : null
                  }
                  options={slFields}
                  isOptionEqualToValue={(option, value) =>
                    option?.sl_field_value === value?.sl_field_value
                  }
                  getOptionLabel={(option) => option?.sl_field_label}
                  renderInput={(params) => (
                    <TextField
                      className={
                        !validateRow.details.sl_field_value.similar &&
                        validateRow.details.sl_field_value.state
                          ? "warning"
                          : ""
                      }
                      error={
                        !validateRow.details.sl_field_value.state ||
                        !validateRow.details.sl_field_value.similar
                      }
                      helperText={
                        !validateRow.details.sl_field_value.state ||
                        !validateRow.details.sl_field_value.similar
                          ? validateRow.details.sl_field_value.errorMsg
                          : ""
                      }
                      required
                      {...params}
                      label="Slate Field"
                    />
                  )}
                  renderOption={(props, field) => (
                    <div
                      className="select-opts"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      {...props}
                      key={field.sl_field_value}
                    >
                      <div style={{ color: "#1976D2" }}>{field.sl_field_label}</div>
                      <small style={{ color: "#737373" }}>
                        internal:
                        <span style={{ fontWeight: "bold", lineBreak: "anywhere" }}>
                          &nbsp;{field.sl_field_value}
                        </span>
                      </small>
                      <small style={{ color: "#737373" }}>
                        type:
                        <span style={{ fontWeight: "bold" }}>&nbsp;{field.sl_field_type}</span>
                      </small>
                    </div>
                  )}
                  onChange={(e, option) => {
                    setNewRow({
                      ...newRow,
                      sl_field_label: option?.sl_field_label,
                      sl_field_value: option?.sl_field_value,
                      sl_field_type: option?.sl_field_type,
                    });
                  }}
                />
              )}
              {direction === "toHubSpot" && <ArrowCircleRightOutlined />}
              <Autocomplete
                id="hs_fields"
                value={
                  newRow.hs_field_value
                    ? {
                        hs_field_label: newRow?.hs_field_label,
                        hs_field_value: newRow?.hs_field_value,
                        hs_field_type: newRow?.hs_field_type,
                      }
                    : null
                }
                options={hsFields.sort((a, b) => -b.hs_group.localeCompare(a.hs_group))}
                isOptionEqualToValue={(option, value) =>
                  option?.hs_field_value === value?.hs_field_value
                }
                groupBy={(option) => option?.hs_group}
                getOptionLabel={(option) => option?.hs_field_label}
                renderInput={(params) => (
                  <TextField
                    className={
                      ((!validateRow.details.hs_field_value.similar && record === "engagements") ||
                        (!validateRow.details.hs_field_value.restricted &&
                          validateRow.details.hs_field_value.similar)) &&
                      validateRow.details.hs_field_value.state
                        ? "warning"
                        : ""
                    }
                    error={
                      !validateRow.details.hs_field_value.state ||
                      !validateRow.details.hs_field_value.similar ||
                      !validateRow.details.hs_field_value.restricted
                    }
                    helperText={
                      !validateRow.details.hs_field_value.state ||
                      !validateRow.details.hs_field_value.similar ||
                      !validateRow.details.hs_field_value.restricted
                        ? validateRow.details.hs_field_value.errorMsg
                        : ""
                    }
                    required
                    {...params}
                    label="HubSpot Field"
                  />
                )}
                renderOption={(props, field) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                    {...props}
                    key={field.hs_field_value}
                  >
                    <div style={{ color: "#1976D2" }}>{field.hs_field_label}</div>
                    <small style={{ color: "#737373", lineBreak: "anywhere" }}>
                      internal:
                      <span style={{ fontWeight: "bold" }}>&nbsp;{field.hs_field_value}</span>
                    </small>
                    <small style={{ color: "#737373" }}>
                      type:
                      <span style={{ fontWeight: "bold" }}>&nbsp;{field.hs_field_type}</span>
                    </small>
                  </div>
                )}
                onChange={(e, option) => {
                  setNewRow({
                    ...newRow,
                    hs_field_label: option?.hs_field_label,
                    hs_field_value: option?.hs_field_value,
                    hs_field_type: option?.hs_field_type,
                  });
                }}
              />
            </div>
            <DialogActions className="actions">
              <Button onClick={handleSaveModalClose} variant="text" className="line-btn warning">
                Cancel
              </Button>
              <Button
                variant="contained"
                className="gradient-btn"
                onClick={() => {
                  setSubmit(true);
                  handleSaveRow();
                }}
              >
                {addRow.isLoading || updateRow.isLoading ? <CircularProgress /> : "Save"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
