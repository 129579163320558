import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import SettingsTabs from "../components/SettingsTabs";
import Connections from "./settings/Connections";
import Logs from "./settings/Logs";
import Profile from "./settings/Profile";

export default function Settings() {
  const [tab, setTab] = useState("profile");

  return (
    <Container className="settings">
      <Helmet>
        <title>Settings | DD Sync</title>
      </Helmet>
      <SettingsTabs tab={tab} setTab={setTab} />
      <div className="content-wrapper">
        {tab === "profile" && <Profile />}
        {tab === "connections" && <Connections />}
        {tab === "logs" && <Logs />}
      </div>
    </Container>
  );
}
