import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  FormGroup,
  FormControlLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import cronTZConvert from "../utils/cronTZConvert";
import useGetAccountDetails from "../../../hooks/GET/useGetAccountDetails";
import useAddRow from "../hooks/POST/useAddJob";
import validate from "../utils/validateJobs";
import validateSubmit from "../utils/validateSubmit.js";
import { CloseRounded } from "@mui/icons-material";
import Alerts from "../modules/Alerts";

export default function JobsModal(props) {
  const {
    record,
    reqMaps,
    direction,
    setSelectionModel,
    setPage,
    newRow,
    setNewRow,
    rows,
    saveModalState,
    setSaveModalState,
    setSubmit,
    submit,
    setEdit,
    edit,
    subscription,
    hsLists,
    queries,
  } = props;
  const { isLoading, data: account } = useGetAccountDetails();
  const addRow = useAddRow(record, direction);
  const validateRow = validate(rows, newRow, submit, edit);
  const [now, setNow] = useState(
    new Date().toLocaleString([], {
      dateStyle: "long",
      timeStyle: "long",
      hourCycle: "h24",
    })
  );

  setInterval(() => {
    setNow(
      new Date().toLocaleString([], {
        dateStyle: "long",
        timeStyle: "long",
        hourCycle: "h24",
      })
    );
  }, 60000);

  let dbStruct;

  if (direction === "toHubSpot") {
    dbStruct = {
      id: null,
      jobName: "",
      jobInternal: "",
      cron: "",
      query: {
        index: "",
        id: "",
        name: "",
        url: "",
      },
      expression: {
        min: "",
        hour: "",
        date: "?",
        month: "*",
        day: "",
        year: "*",
      },
      updateOnly: false,
      direction: "toHubSpot",
      record: record,
      valid: true,
    };
  } else {
    dbStruct = {
      id: null,
      jobName: "",
      jobInternal: "",
      cron: "",
      list: {
        index: "",
        id: "",
        name: "",
        size: "",
      },
      expression: {
        min: "",
        hour: "",
        date: "?",
        month: "*",
        day: "",
        year: "*",
      },
      direction: "toSlate",
      record: record,
      valid: true,
    };
  }

  if (record === "engagements") {
    dbStruct = {
      ...dbStruct,
      subrecord: "",
    };
  }

  const handleSaveModalClose = () => {
    setSelectionModel([]);
    setNewRow(dbStruct);
    setSubmit(false);
    setEdit(false);
    setSaveModalState(false);
  };

  useEffect(() => {
    if (!edit) {
      setNewRow(dbStruct);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveModalState, account]);

  const handleSaveRow = async (e) => {
    const newExpression = cronTZConvert(newRow.expression, true);

    validateSubmit("jobs", rows, { ...newRow, expression: newExpression }, true, edit).then(
      async (res) => {
        if (res.valid) {
          await addRow.mutateAsync({ ...newRow, expression: newExpression }).then(() => {
            setPage(0);
            handleSaveModalClose();
          });
        }
      }
    );
  };

  return (
    <div className="dh-jobs-form">
      <Dialog open={saveModalState} maxWidth="md" fullWidth className="dh-modal save">
        <IconButton onClick={handleSaveModalClose} className="close" aria-label="close">
          <CloseRounded />
        </IconButton>
        <DialogTitle>Add Sync Schedule</DialogTitle>
        <DialogContent>
          <DialogContentText>Fill out the fields below to add a Sync Schedule.</DialogContentText>
          <form className="dh-modal-form">
            <div className="name-sub">
              <TextField
                id="job-name"
                label="Job Name"
                variant="outlined"
                value={newRow.jobName}
                fullWidth
                required
                onChange={(e) => {
                  setNewRow({
                    ...newRow,
                    jobName: e.target.value,
                    jobInternal: e.target.value
                      .replaceAll(" ", "-")
                      .replace(/[^a-zA-Z0-9-]/g, "")
                      .toLowerCase(),
                  });
                }}
                error={!validateRow.details.jobName.state}
                helperText={
                  !validateRow.details.jobName.state ? validateRow.details.jobName.errorMsg : ""
                }
              />

              {record === "engagements" ? (
                <FormControl
                  error={!validateRow.details.subrecord.filled}
                  required
                  className="subrecord-select"
                >
                  <InputLabel id="subrecord-label">Type</InputLabel>
                  <Select
                    id="subrecord"
                    labelId="subrecord-label"
                    value={newRow.subrecord}
                    label="Type"
                    onChange={(e) =>
                      setNewRow({
                        ...newRow,
                        subrecord: e.target.value,
                      })
                    }
                  >
                    {reqMaps?.emails && <MenuItem value="emails">Emails</MenuItem>}
                    {reqMaps?.calls && <MenuItem value="calls">Calls</MenuItem>}
                    {reqMaps?.notes && <MenuItem value="notes">Notes</MenuItem>}
                    {reqMaps?.meetings && <MenuItem value="meetings">Meetings</MenuItem>}
                    {reqMaps?.postal && <MenuItem value="postal_mail">Postal Mail</MenuItem>}
                  </Select>
                  <FormHelperText className="error-msg">
                    {!validateRow.details.subrecord.filled
                      ? validateRow.details.subrecord.errorMsg
                      : ""}
                  </FormHelperText>
                </FormControl>
              ) : (
                ""
              )}
            </div>
            <div
              className={
                (!validateRow.details.query.unique && validateRow.details.query.state) ||
                !validateRow.details.list.unique.state ||
                !validateRow.details.list.populated.state
                  ? "warning"
                  : ""
              }
            >
              {direction === "toHubSpot" ? (
                <div>
                  {record !== "engagements" || newRow?.subrecord !== "" ? (
                    <FormControl
                      error={!validateRow.details.query.state || !validateRow.details.query.unique}
                      required
                      className="query-select"
                      fullWidth
                    >
                      <InputLabel id="min-label">Slate Query</InputLabel>
                      <Select
                        id="query"
                        labelId="query-label"
                        value={newRow.query?.index}
                        label="Slate Query"
                        onChange={(e) =>
                          setNewRow({
                            ...newRow,
                            query: {
                              index: e.target.value,
                              id: queries.toHubSpot.active[e.target.value].id,
                              name: queries.toHubSpot.active[e.target.value].name,
                              internal: queries.toHubSpot.active[e.target.value].internal,
                              url: queries.toHubSpot.active[e.target.value].url,
                            },
                          })
                        }
                      >
                        {queries.toHubSpot.active &&
                          queries.toHubSpot.active.map((query, i) => {
                            if (query.valid && record !== "engagements") {
                              return (
                                <MenuItem key={`query-${i}`} value={i}>
                                  {query.name}
                                </MenuItem>
                              );
                            }

                            if (
                              query.valid &&
                              record === "engagements" &&
                              newRow?.subrecord === query.subrecord
                            ) {
                              return (
                                <MenuItem key={`query-${i}`} value={i}>
                                  {query.name}
                                </MenuItem>
                              );
                            }

                            return false;
                          })}
                      </Select>
                      <FormHelperText className="error-msg">
                        {!validateRow.details.query.state || !validateRow.details.query.unique
                          ? validateRow.details.query.errorMsg
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <FormControl
                  error={
                    !validateRow.details.list.unique.state ||
                    !validateRow.details.list.populated.state ||
                    !validateRow.details.list.filled.state ||
                    !validateRow.details.list.size.state
                  }
                  required
                  className="list-select"
                  fullWidth
                >
                  <InputLabel id="min-label">HubSpot List</InputLabel>
                  <Select
                    id="list"
                    labelId="list-label"
                    value={newRow.list?.index}
                    label="HubSpot List"
                    onChange={(e) =>
                      setNewRow({
                        ...newRow,
                        list: {
                          index: e.target.value,
                          id: hsLists[e.target.value].listId,
                          name: hsLists[e.target.value].name,
                          size: hsLists[e.target.value].size,
                        },
                      })
                    }
                  >
                    {hsLists &&
                      hsLists.map((list, i) => {
                        return (
                          <MenuItem key={`list-${i}`} value={i}>
                            {list.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText className="error-msg">
                    {!validateRow.details.list.filled.state
                      ? validateRow.details.list.filled.errorMsg
                      : ""}
                    {!validateRow.details.list.unique.state
                      ? validateRow.details.list.unique.errorMsg
                      : ""}
                    {!validateRow.details.list.unique.state &&
                    (!validateRow.details.list.populated.state ||
                      !validateRow.details.list.size.state) ? (
                      <br></br>
                    ) : (
                      ""
                    )}
                    {!validateRow.details.list.populated.state
                      ? validateRow.details.list.populated.errorMsg
                      : ""}
                    {!validateRow.details.list.size.state
                      ? validateRow.details.list.size.errorMsg
                      : ""}
                  </FormHelperText>
                </FormControl>
              )}
            </div>
            <div
              className={
                !validateRow.details.expression.day.unique &&
                validateRow.details.expression.day.state
                  ? "builder warning"
                  : "builder"
              }
            >
              <FormControl
                error={
                  !validateRow.details.expression.day.state ||
                  !validateRow.details.expression.day.unique
                }
                required
                className="day-select"
              >
                <InputLabel id="day-label">Day</InputLabel>
                <Select
                  id="day"
                  labelId="day-label"
                  value={newRow.expression?.day}
                  label="Day"
                  onChange={(e) =>
                    setNewRow({
                      ...newRow,
                      expression: {
                        ...newRow.expression,
                        day: e.target.value,
                      },
                    })
                  }
                >
                  {["daily", "hourly"].includes(subscription.frequency) && (
                    <MenuItem value={"*"}>Every day</MenuItem>
                  )}
                  <MenuItem value={1}>Sunday</MenuItem>
                  <MenuItem value={2}>Monday</MenuItem>
                  <MenuItem value={3}>Tuesday</MenuItem>
                  <MenuItem value={4}>Wednesday</MenuItem>
                  <MenuItem value={5}>Thursday</MenuItem>
                  <MenuItem value={6}>Friday</MenuItem>
                  <MenuItem value={7}>Saturday</MenuItem>
                </Select>
                <FormHelperText className="error-msg">
                  {!validateRow.details.expression.day.state ||
                  !validateRow.details.expression.day.unique
                    ? validateRow.details.expression.day.errorMsg
                    : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                error={
                  !validateRow.details.expression.hour.state ||
                  !validateRow.details.expression.hour.unique
                }
                required
                className="hour-select"
              >
                <InputLabel id="hour-label">Hour</InputLabel>
                <Select
                  id="hour"
                  labelId="hour-label"
                  value={newRow.expression?.hour}
                  label="Hour"
                  onChange={(e) =>
                    setNewRow({
                      ...newRow,
                      expression: {
                        ...newRow.expression,
                        hour: e.target.value,
                      },
                    })
                  }
                >
                  {["hourly"].includes(subscription.frequency) && (
                    <MenuItem value={"*"}>Every hour</MenuItem>
                  )}
                  {[...Array(24)].map((e, i) => (
                    <MenuItem key={`hour-${i}`} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="error-msg">
                  {!validateRow.details.expression.hour.state ||
                  !validateRow.details.expression.hour.unique
                    ? validateRow.details.expression.hour.errorMsg
                    : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                error={
                  !validateRow.details.expression.min.state ||
                  !validateRow.details.expression.min.unique
                }
                required
                className="min-select"
              >
                <InputLabel id="min-label">Minute</InputLabel>
                <Select
                  id="min"
                  labelId="min-label"
                  value={newRow.expression?.min}
                  label="Minute"
                  onChange={(e) =>
                    setNewRow({
                      ...newRow,
                      expression: {
                        ...newRow.expression,
                        min: e.target.value,
                      },
                    })
                  }
                >
                  {["hourly"].includes(subscription.frequency) && (
                    <MenuItem value={"0/15"}>Every 15mins</MenuItem>
                  )}

                  {[...Array(4)].map((e, i) => (
                    <MenuItem key={`min-${i}`} value={i * 15}>
                      {i * 15}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="error-msg">
                  {!validateRow.details.expression.min.state ||
                  !validateRow.details.expression.min.unique
                    ? validateRow.details.expression.min.errorMsg
                    : ""}
                </FormHelperText>
              </FormControl>
            </div>
            <div>
              {record === "contacts" && direction === "toHubSpot" ? (
                <FormGroup className="update-only">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setNewRow({
                            ...newRow,
                            updateOnly: e.target.checked,
                          });
                        }}
                      />
                    }
                    label="Update existing contact records only"
                  />
                </FormGroup>
              ) : (
                ""
              )}
            </div>
            <div className="dh-form-foot">
              <Alerts
                {...props}
                hidable={false}
                content={{
                  info: [
                    {
                      title: `${isLoading ? "Loading..." : now}`,
                      message: `Timezone is based on current location`,
                    },
                  ],
                }}
              />
              <DialogActions className="actions">
                <Button onClick={handleSaveModalClose} variant="text" className="line-btn warning">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="gradient-btn"
                  onClick={() => {
                    setSubmit(true);
                    handleSaveRow();
                  }}
                >
                  {addRow.isLoading ? <CircularProgress /> : "Save"}
                </Button>
              </DialogActions>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
