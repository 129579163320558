import React, { useEffect } from "react";
import Alerts from "../modules/Alerts";

export default function QueriesAlerts(props) {
  const { direction, data } = props;

  useEffect(() => {}, [data, direction]);

  return (
    <div className="dh-alerts">
      <Alerts
        {...props}
        content={{
          info: [
            {
              title: "Refreshing Your Query",
              message:
                "If an existing query is updated in Slate, please refresh your query here to ensure accurate syncing.",
            },
            {
              title: "Required Fields",
              message:
                "'Email' and 'Updated' are required fields that must be a part of your query.",
              record: "contacts",
            },
          ],
          warning: [
            {
              test: !data?.toHubSpot?.active?.some((query) => query?.valid === 0),
              title: "One or more of your Slate Queries is invalid",
              message:
                "This will result in said queries being unavailable to be utilized by Field Mappings & Sync Schedules.",
            },
          ],
          error: [
            {
              test: !data?.status,
              title: "There was an error retrieving your Slate Queries",
              message:
                "Please refresh and try again. If the problem persists, please contact support.",
            },
          ],
        }}
      />
    </div>
  );
}
