import React from "react";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const redirect = process.env.REACT_APP_REDIRECT_URI;
  const { loginWithRedirect } = useAuth0();
  return (
    <Button
      variant="contained"
      className="gradient-btn"
      onClick={() =>
        loginWithRedirect({
          redirectUri: redirect,
        })
      }
    >
      <span>Log In</span>
    </Button>
  );
};

export default LoginButton;
