import React, { useEffect } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Button, Card, CardContent, CircularProgress } from "@mui/material";
import {
  CheckCircleOutlineRounded,
  HighlightOffRounded,
  RefreshRounded,
} from "@mui/icons-material";
import useGetQueries from "../hooks/GET/useGetQueries.js";
import useRefreshQuery from "../hooks/PUT/useRefreshQuery.js";
import QueriesAlerts from "../alerts/QueriesAlerts.js";
import QueriesModal from "../modals/QueriesModal.js";
import Table from "../modules/Table.js";
import State from "../modules/State.js";

export default function QueriesTable(props) {
  const {
    record,
    state,
    saveModalState,
    setSaveModalState,
    setArchiveModalState,
    setDeleteModalState,
    edit,
    setEdit,
    newRow,
    direction,
    setSubmit,
    setNewRow,
    rows,
    selectionModel,
    setSelectionModel,
    setSelectedRows,
  } = props;
  const { isLoading, data } = useGetQueries(record);
  const refreshRow = useRefreshQuery(record, newRow);

  let columns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      editable: false,
    },
    {
      field: "user",
      headerName: "Username",
      width: 200,
      editable: false,
      hide: true,
    },
    {
      field: "password",
      headerName: "Password",
      width: 200,
      editable: false,
      hide: true,
      renderCell: () => {
        return <span>*********</span>;
      },
    },
    {
      field: "url",
      headerName: "URL",
      width: 400,
      editable: false,
    },
  ];

  if (state === "active") {
    columns = [
      ...columns.slice(0, 1),
      {
        field: "refresh",
        headerName: "Refresh",
        width: 150,
        editable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <Button
              variant="contained"
              color="secondary"
              className="circle-btn actions"
              onClick={() => {
                refreshQuery(params.row);
              }}
            >
              {params.id === newRow.id && !edit ? <CircularProgress /> : <RefreshRounded />}
            </Button>
          );
        },
      },
      {
        field: "valid",
        headerName: "Valid",
        type: "boolean",
        width: 150,
        editable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <span className="valid">
              {params.row.valid ? (
                <CheckCircleOutlineRounded className="green" />
              ) : (
                <HighlightOffRounded className="red" />
              )}
            </span>
          );
        },
      },
      ...columns.slice(1),
      {
        field: "Actions",
        type: "actions",
        width: 40,
        getActions: (params) => [
          <GridActionsCellItem
            onClick={() => {
              setSelectionModel([params.id]);
              setSelectedRows([params.row]);
              setSaveModalState(true);
              setEdit(true);
              setSubmit(true);
            }}
            label="Edit"
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => {
              setSelectionModel([params.id]);
              setSelectedRows([params.row]);
              setArchiveModalState(true);
            }}
            label="Archive"
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => {
              refreshQuery(params.row);
            }}
            label="Refresh"
            showInMenu
          />,
        ],
      },
    ];
  } else {
    columns = [
      ...columns,
      {
        field: "Actions",
        type: "actions",
        width: 40,
        getActions: (params) => [
          <GridActionsCellItem
            onClick={() => {
              setSelectionModel([params.id]);
              setSelectedRows([params.row]);
              setDeleteModalState(true);
            }}
            label="Delete"
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => {
              refreshQuery(params.row);
            }}
            label="Refresh"
            showInMenu
          />,
        ],
      },
    ];
  }

  if (record === "engagements") {
    columns = [
      ...columns.slice(0, 1),
      {
        field: "subrecord",
        headerName: "Type",
        width: 150,
        editable: false,
        renderCell: (params) => {
          return (
            <span style={{ textTransform: "uppercase" }}>
              {params.row.subrecord.replace("_", " ")}
            </span>
          );
        },
      },
      ...columns.slice(1),
    ];
  }

  let dbStruct = {
    id: null,
    name: "",
    user: "",
    pass: "",
    url: "",
    direction: direction,
    record: record,
    valid: true,
  };

  if (record === "engagements") {
    dbStruct = {
      ...dbStruct,
      subrecord: "",
    };
  }

  const refreshQuery = (row) => {
    setSubmit(true);
    setNewRow(row);
    setTimeout(() => {
      refreshRow
        .mutateAsync()
        .then(() => {
          setNewRow(dbStruct);
          setSubmit(false);
        })
        .catch(() => {
          setNewRow(dbStruct);
          setSubmit(false);
        });
    }, 1000);
  };

  useEffect(() => {
    if (!edit) {
      setNewRow(dbStruct);
    } else {
      const selectedRow = rows.filter((row) => row.id === selectionModel[0]);

      let currRow = {
        name: selectedRow[0].name,
        url: selectedRow[0].url,
        user: selectedRow[0].user,
        pass: selectedRow[0].pass,
        id: selectedRow[0].id,
        record: selectedRow[0].record,
        direction: direction,
        valid: true,
      };

      if (record === "engagements") {
        currRow = {
          ...currRow,
          subrecord: selectedRow[0].subrecord,
        };
      }

      setNewRow(currRow);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveModalState, edit]);

  if (isLoading) {
    return (
      <div className="retrieving">
        <CircularProgress />
        <h2 className="process">Retrieving data...</h2>
      </div>
    );
  }

  return (
    <div className="dh-type dh-queries">
      <QueriesAlerts {...props} isLoading={isLoading} data={data} />

      {!data?.status ? (
        <div className={`dh-tables ${state}`}>
          <State {...props} />
          <Table {...props} isLoading={isLoading} data={data} columns={columns} />
        </div>
      ) : (
        <Card className="dh-error">
          <CardContent>Data could not be displayed.</CardContent>
        </Card>
      )}
      <div className="dh-modals">
        <QueriesModal {...props} dbStruct={dbStruct} />
      </div>
    </div>
  );
}
