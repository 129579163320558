import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Typography,
  TextField,
  CircularProgress,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import useCheckHsConnection from "../../hooks/GET/useCheckHsConnection";
import useCheckSlConnection from "../../hooks/GET/useCheckSlConnection";
import useGetSettings from "../../hooks/GET/useGetSettings";
import useDeleteHsConnection from "../../hooks/DELETE/useDeleteHsConnection";
import useDeleteSlConnection from "../../hooks/DELETE/useDeleteSlConnection";

export default function Connections() {
  const { isLoading: hsIsLoading, data: hsConnect } = useCheckHsConnection();
  const { isLoading: slIsLoading, data: slConnect } = useCheckSlConnection();
  const { isLoading: settingsIsLoading, data: settings } = useGetSettings();
  const disconnectSl = useDeleteSlConnection();
  const disconnectHs = useDeleteHsConnection(settings?.hs_refresh_token);

  const [disconnectDialog, setDisconnectDialog] = useState({
    hubspot: false,
    slate: false,
  });

  const scopes = process.env.REACT_APP_HS_SCOPES;
  const optScopes = process.env.REACT_APP_HS_OPT_SCOPES;
  const clientId = process.env.REACT_APP_HS_CLIENT_ID;
  const redirectURI = process.env.REACT_APP_HS_REDIRECT_URI;
  const HsAuthUrl =
    `https://app.hubspot.com/oauth/authorize` +
    `?client_id=${clientId}` +
    `&redirect_uri=${redirectURI}` +
    `&scope=${scopes}` +
    `&optional_scope=${optScopes}`;
  const [slateQuery, setSlateQuery] = useState({
    url: "",
    name: "",
    user: "",
    pass: "",
  });
  const SlAuthUrl =
    `/settings/connections/slate` +
    `?url=${encodeURIComponent(slateQuery.url)}` +
    `&name=${slateQuery.name}` +
    `&user=${slateQuery.user}` +
    `&pass=${slateQuery.pass}`;

  if (hsIsLoading || slIsLoading || settingsIsLoading) {
    return (
      <div className="retrieving">
        <CircularProgress />
        <h2 className="process">Authenticating...</h2>
      </div>
    );
  }

  return (
    <div className="connections">
      <Helmet>
        <title>Connections | DD Sync</title>
      </Helmet>
      <h1 className="title">Connections</h1>
      {hsConnect?.status ? (
        <Card sx={{ maxWidth: 500 }}>
          <CardContent>
            <h2>HubSpot Account</h2>
            <Typography>
              Status: <strong style={{ color: "green" }}>Connected</strong>
            </Typography>
            <Typography variant="h6">
              Portal ID: <strong>{hsConnect.settings.portalId}</strong>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="text"
              className="line-btn warning"
              onClick={() => {
                setDisconnectDialog({ ...disconnectDialog, hubspot: true });
              }}
            >
              DISCONNECT
            </Button>
            <Dialog
              maxWidth="md"
              fullWidth
              className="dh-modal"
              open={disconnectDialog.hubspot}
              onClose={() => {
                setDisconnectDialog({
                  ...disconnectDialog,
                  hubspot: false,
                });
              }}
            >
              <IconButton
                onClick={() => {
                  setDisconnectDialog({
                    ...disconnectDialog,
                    hubspot: false,
                  });
                }}
                className="close"
                aria-label="close"
              >
                <CloseRounded />
              </IconButton>
              <DialogTitle>Are you sure you want to delete your HubSpot connection?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Deleting this connection will prevent any data transfers or retrieval from
                  HubSpot. This will also result in the deletion of all Field Mappings and the
                  deactivation of all Sync Jobs. Proceed with EXTREME caution. This will not
                  uninstall the application from your HubSpot portal.
                </DialogContentText>
                <DialogActions className="actions">
                  <Button
                    variant="text"
                    className="line-btn warning"
                    onClick={() => {
                      setDisconnectDialog({
                        ...disconnectDialog,
                        hubspot: false,
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="gradient-btn danger"
                    onClick={() => {
                      disconnectHs.mutate();
                      setDisconnectDialog({
                        ...disconnectDialog,
                        hubspot: false,
                      });
                    }}
                  >
                    <span>Disconnect</span>
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </CardActions>
        </Card>
      ) : (
        <Card sx={{ maxWidth: 500 }} className="hs-connect">
          <CardContent>
            <h2>HubSpot Account</h2>
            <Typography>
              Status: <strong style={{ color: "red" }}>Disconnected</strong>
            </Typography>
            <Typography variant="h6">
              Portal ID: <strong>Unknown</strong>
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" className="gradient-btn" href={HsAuthUrl}>
              <span>Connect</span>
            </Button>
          </CardActions>
        </Card>
      )}
      <br></br>
      {slConnect?.status ? (
        <Card sx={{ maxWidth: 500 }}>
          <CardContent>
            <h2>Slate Account</h2>
            <Typography>
              Status: <strong style={{ color: "green" }}>Connected</strong>
            </Typography>
            <Typography variant="h6">
              Source Format: <strong className="query-url">{slConnect.settings.sl_name}</strong>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="text"
              className="line-btn warning"
              onClick={() => {
                setDisconnectDialog({ ...disconnectDialog, slate: true });
              }}
            >
              DISCONNECT
            </Button>
            <Dialog
              maxWidth="md"
              fullWidth
              className="dh-modal"
              open={disconnectDialog.slate}
              onClose={() => {
                setDisconnectDialog({
                  ...disconnectDialog,
                  slate: false,
                });
              }}
            >
              <IconButton
                onClick={() => {
                  setDisconnectDialog({
                    ...disconnectDialog,
                    slate: false,
                  });
                }}
                className="close"
                aria-label="close"
              >
                <CloseRounded />
              </IconButton>
              <DialogTitle>Are you sure you want to delete your Slate connection?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Deleting this connection will prevent any data transfers into Slate. This will
                  also result in the deletion of all HubSpot to Slate Field Mappings and the
                  deactivation of all HubSpot to Slate Sync Jobs. Proceed with EXTREME caution. This
                  will not remove your Custom Slate Fields.
                </DialogContentText>
                <DialogActions className="actions">
                  <Button
                    variant="text"
                    className="line-btn warning"
                    onClick={() => {
                      setDisconnectDialog({
                        ...disconnectDialog,
                        slate: false,
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="gradient-btn danger"
                    onClick={() => {
                      disconnectSl.mutate();
                      setDisconnectDialog({
                        ...disconnectDialog,
                        slate: false,
                      });
                    }}
                  >
                    <span>Disconnect</span>
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </CardActions>
        </Card>
      ) : (
        <Card sx={{ maxWidth: 500 }}>
          <CardContent>
            <h2>Slate Account</h2>
            <Typography>
              Status: <strong style={{ color: "red" }}>Disconnected</strong>
            </Typography>
            <TextField
              label="Source Format Name"
              type="name"
              fullWidth={true}
              size="small"
              margin="dense"
              onChange={(e) => setSlateQuery({ ...slateQuery, name: e.target.value })}
            ></TextField>
            <TextField
              label="Source Format URL"
              type="url"
              fullWidth={true}
              size="small"
              margin="dense"
              onChange={(e) => setSlateQuery({ ...slateQuery, url: e.target.value })}
            ></TextField>
            <TextField
              label="Service Account Username"
              type="text"
              fullWidth={true}
              size="small"
              margin="dense"
              onChange={(e) => setSlateQuery({ ...slateQuery, user: e.target.value })}
            ></TextField>
            <TextField
              label=" Service Account Password"
              type="password"
              fullWidth={true}
              size="small"
              margin="dense"
              onChange={(e) => setSlateQuery({ ...slateQuery, pass: e.target.value })}
            ></TextField>
          </CardContent>
          <CardActions>
            <Button variant="contained" className="gradient-btn" href={SlAuthUrl}>
              <span>Connect</span>
            </Button>
          </CardActions>
        </Card>
      )}
    </div>
  );
}
