import React, { useState } from "react";
import { Routes, Route } from "react-router";
import { CircularProgress } from "@mui/material";
import Authentication from "./system/Authentication";
import Error404 from "./system/Error404";
import ProtectedRoute from "./auth/ProtectedRoute";
import Navigation from "./components/Navigation";
import DirectionPanel from "./components/DirectionPanel";
import Home from "./screens/Home";
import Settings from "./screens/Settings";

import ContactSlateQueries from "./screens/contacts/SlateQueries";
import ContactFieldMappings from "./screens/contacts/FieldMappings";
import ContactValueMappings from "./screens/contacts/ValueMappings";
import ContactSyncJobs from "./screens/contacts/SyncJobs";
import ContactSyncHistory from "./screens/contacts/SyncHistory";

import EngagementSlateQueries from "./screens/engagements/SlateQueries";
import EngagementFieldMappings from "./screens/engagements/FieldMappings";
import EngagementSyncJobs from "./screens/engagements/SyncJobs";
import EngagementSyncHistory from "./screens/engagements/SyncHistory";

import ApplicationSlateQueries from "./screens/applications/SlateQueries";
import ApplicationFieldMappings from "./screens/applications/FieldMappings";
import ApplicationSyncJobs from "./screens/applications/SyncJobs";
import ApplicationSyncHistory from "./screens/applications/SyncHistory";

import useGetSubscriptionDetails from "./hooks/GET/useGetSubscriptionDetails";
import Subscribe from "./screens/Subscribe";
import Delinquent from "./components/Delinquent";
import GlobalAlerts from "./components/GlobalAlerts";

const Router = (props) => {
  const { user } = props;
  const [direction, setDirection] = useState("toHubSpot");
  const { isLoading: subLoading, data: subscription } = useGetSubscriptionDetails();

  if (subLoading) {
    return (
      <div className="loading">
        <h1 className="process">Loading...</h1>
        <CircularProgress />
      </div>
    );
  }

  if (subscription?.id === 0) {
    window.history.replaceState(null, "DD Sync", "/");
  }

  if (subscription?.delinquent && !window.location.href.includes("settings")) {
    window.history.replaceState(null, "DD Sync", "/");
  }

  return (
    <div id="main">
      <Navigation subscription={subscription} user={user} />
      {user && subscription?.id > 0 && (
        <DirectionPanel direction={direction} setDirection={setDirection} />
      )}
      {(!user || subscription?.id > 0) && !subscription?.delinquent && (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Error404 />} />

          {/* Protected Routes */}
          <Route path="/settings" element={<ProtectedRoute component={Settings} />} />
          <Route
            path="/settings/connections/hubspot"
            element={<ProtectedRoute component={Authentication} />}
          />
          <Route
            path="/settings/connections/slate"
            element={<ProtectedRoute component={Authentication} />}
          />

          <Route
            path="/contacts/slate-queries"
            element={
              <ProtectedRoute
                component={ContactSlateQueries}
                direction={direction}
                setDirection={setDirection}
              />
            }
          />
          <Route
            path="/contacts/mappings/fields"
            element={<ProtectedRoute component={ContactFieldMappings} direction={direction} />}
          />
          <Route
            path="/contacts/mappings/fields/:id/values"
            element={<ProtectedRoute component={ContactValueMappings} direction={direction} />}
          />
          <Route
            path="/contacts/sync-jobs"
            element={<ProtectedRoute component={ContactSyncJobs} direction={direction} />}
          />
          <Route
            path="/contacts/sync-history"
            element={<ProtectedRoute component={ContactSyncHistory} />}
          />

          <Route
            path="/engagements/slate-queries"
            element={
              <ProtectedRoute
                component={EngagementSlateQueries}
                direction={direction}
                setDirection={setDirection}
              />
            }
          />
          <Route
            path="/engagements/mappings/fields"
            element={<ProtectedRoute component={EngagementFieldMappings} direction={direction} />}
          />
          <Route
            path="/engagements/sync-jobs"
            element={<ProtectedRoute component={EngagementSyncJobs} direction={direction} />}
          />
          <Route
            path="/engagements/sync-history"
            element={<ProtectedRoute component={EngagementSyncHistory} />}
          />

          <Route
            path="/applications/slate-queries"
            element={
              <ProtectedRoute
                component={ApplicationSlateQueries}
                direction={direction}
                setDirection={setDirection}
              />
            }
          />
          <Route
            path="/applications/mappings/fields"
            element={<ProtectedRoute component={ApplicationFieldMappings} direction={direction} />}
          />
          <Route
            path="/applications/sync-jobs"
            element={<ProtectedRoute component={ApplicationSyncJobs} direction={direction} />}
          />
          <Route
            path="/applications/sync-history"
            element={<ProtectedRoute component={ApplicationSyncHistory} />}
          />
        </Routes>
      )}

      {user && subscription?.id === 0 && (
        <Routes>
          <Route path="*" element={<Subscribe user={user} />} />
        </Routes>
      )}

      {user && subscription?.delinquent ? (
        <Routes>
          <Route path="*" element={<Home />} />

          {/* Protected Routes */}
          <Route path="/settings" element={<ProtectedRoute component={Settings} />} />
          <Route
            path="/settings/connections/hubspot"
            element={<ProtectedRoute component={Authentication} />}
          />
          <Route
            path="/settings/connections/slate"
            element={<ProtectedRoute component={Authentication} />}
          />
        </Routes>
      ) : (
        ""
      )}

      {user && subscription?.delinquent ? <Delinquent /> : ""}
      {user ? <GlobalAlerts /> : ""}
    </div>
  );
};

export default Router;
