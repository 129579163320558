import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useGetLimits() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  let metadata;

  if (user) {
    metadata = user[process.env.REACT_APP_AUTH0_METADATA];
  }

  return useQuery(
    "limits",
    async () => {
      const token = await getAccessTokenSilently();

      const config = {
        params: {
          tenant: metadata.username,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return await axios
        .get(`/database/limits`, config)
        .then((res) => {
          return {
            contacts: res.data[0],
            engagements: res.data[1],
            applications: res.data[2],
          };
        })
        .catch((err) => err);
    },
    {
      initialData: () => queryClient.getQueryData("limits"),
      staleTime: Infinity,
      initialDataUpdatedAt: () => queryClient.getQueryState("limits")?.dataUpdatedAt,
    }
  );
}
