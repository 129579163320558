import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowBackRounded } from "@mui/icons-material";
import DataHub from "../../components/data-hub/DataHub";

export default function ValueMappings(props) {
  return (
    <Container className="contacts value-mappings">
      <Helmet>
        <title>Value Mapping | Contacts</title>
      </Helmet>
      <div className="content-wrapper">
        <Button
          component={Link}
          variant="text"
          className="line-btn return"
          to="/contacts/mappings/fields"
        >
          <ArrowBackRounded /> <span>Return to Field Mapping</span>
        </Button>
        <DataHub {...props} record="contacts" screen="value_maps" title="Value Mapping" />
      </div>
    </Container>
  );
}
