import React, { useState } from "react";
import { Button, Card, CardContent, CircularProgress } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  CheckCircleOutlineRounded,
  HighlightOffRounded,
  PlayArrowRounded,
} from "@mui/icons-material";
import cronTZConvert from "../utils/cronTZConvert";
import useGetJobs from "../hooks/GET/useGetJobs";
import useGetQueries from "../hooks/GET/useGetQueries";
import useGetHsLists from "../hooks/GET/useGetHsLists";
import useSyncToHs from "../hooks/POST/useSyncToHs";
import useSyncToSl from "../hooks/POST/useSyncToSl";
import JobsAlerts from "../alerts/JobsAlerts";
import JobsModal from "../modals/JobsModal.js";
import Table from "../modules/Table.js";
import State from "../modules/State.js";

export default function JobsTable(props) {
  const {
    record,
    reqMaps,
    state,
    direction,
    hsConnect,
    slConnect,
    limits,
    subscription,
    rows,
    setRows,
    setSelectionModel,
    setArchiveModalState,
    setDeleteModalState,
    setSelectedRows,
  } = props;
  const [currRunJob, setCurrRunJob] = useState({});
  const days = ["SUN", "MON", "TUES", "WED", "THUR", "FRI"];

  const { isLoading, data } = useGetJobs(record);
  const { isLoading: hsListsLoading, data: hsLists } = useGetHsLists();
  const { isLoading: queriesLoading, data: queries } = useGetQueries(record);
  let runRow;
  const syncToHs = useSyncToHs(record, currRunJob);
  const syncToSl = useSyncToSl(record, currRunJob);

  let columns = [];
  let defaults = [];

  if (direction === "toHubSpot") {
    runRow = syncToHs;

    columns = [
      {
        field: "id",
        headerName: "ID #",
        width: 75,
        editable: false,
        renderCell: (params) => {
          return <strong>#{params.row.id}</strong>;
        },
      },
      {
        field: "name",
        headerName: "Job Name",
        width: 150,
        editable: false,
      },
      {
        field: "cron",
        headerName: "Period",
        width: 300,
        editable: false,
        renderCell: (params) => {
          const expression = {
            date: "?",
            day: isNaN(params.row.day_week) ? params.row.day_week : parseInt(params.row.day_week),
            hour: isNaN(params.row.hours) ? params.row.hours : parseInt(params.row.hours),
            min: parseInt(params.row.mins),
            month: "*",
            year: "*",
          };

          const newExpression = cronTZConvert(expression, false);

          return (
            <span className="period">
              <span>
                Every <strong>{days[newExpression.day - 1] || "DAY"} </strong>
              </span>
              <span>
                at
                <strong>
                  {" "}
                  {newExpression.hour < 10 && "0"}
                  {newExpression.hour}:{newExpression.min < 10 && "0"}
                  {newExpression.min}{" "}
                </strong>
                {new Date().toLocaleTimeString([], { timeZoneName: "short" }).split(" ")[2]}
              </span>
              {params.row.mins === "0/15" && (
                <span>
                  {" "}
                  Every <strong>15mins</strong>
                </span>
              )}
            </span>
          );
        },
      },
      {
        field: "query",
        headerName: "Query Name",
        width: 200,
        editable: false,
      },
      {
        field: "update_only",
        headerName: "Scope",
        type: "boolean",
        width: 130,
        editable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params.row.update_only ? "Update Only" : "Create/Update"}</span>;
        },
      },
    ];

    if (record === "engagements") {
      columns = [
        ...columns,
        {
          field: "subrecord",
          headerName: "Type",
          width: 150,
          editable: false,
          renderCell: (params) => {
            return (
              <span style={{ textTransform: "uppercase" }}>
                {params.row.subrecord.replace("_", " ")}
              </span>
            );
          },
        },
      ];
    }
  } else if (direction === "toSlate") {
    runRow = syncToSl;

    columns = [
      {
        field: "id",
        headerName: "ID #",
        width: 75,
        editable: false,
        renderCell: (params) => {
          return <strong>#{params.row.id}</strong>;
        },
      },
      {
        field: "name",
        headerName: "Job Name",
        width: 150,
        editable: false,
      },
      {
        field: "cron",
        headerName: "Period",
        width: 200,
        editable: false,
        renderCell: (params) => {
          const expression = {
            date: "?",
            day: isNaN(params.row.day_week) ? params.row.day_week : parseInt(params.row.day_week),
            hour: isNaN(params.row.hours) ? params.row.hours : parseInt(params.row.hours),
            min: parseInt(params.row.mins),
            month: "*",
            year: "*",
          };

          const newExpression = cronTZConvert(expression, false);

          return (
            <span className="period">
              <span>
                Every <strong>{days[newExpression.day - 1] || "DAY"} </strong>
              </span>
              <span>
                at
                <strong>
                  {" "}
                  {newExpression.hour < 10 && "0"}
                  {newExpression.hour}:{newExpression.min < 10 && "0"}
                  {newExpression.min}{" "}
                </strong>
                {new Date().toLocaleTimeString([], { timeZoneName: "short" }).split(" ")[2]}
              </span>
              {params.row.mins === "0/15" && (
                <span>
                  {", "}
                  Every <strong>15mins</strong>
                </span>
              )}
            </span>
          );
        },
      },
      {
        field: "list_name",
        headerName: "List Name",
        width: 150,
        editable: false,
        renderCell: (params) => {
          return <span>{params.row?.list_name || "N/A"}</span>;
        },
      },
    ];
  }

  if (state === "active") {
    columns = [
      ...columns,
      {
        field: "play",
        headerName: "Play",
        width: 90,
        editable: false,
        renderCell: (params) => {
          const now = Date.now();
          const frequency = subscription.frequency;
          const lastSync = params.row.last_sync_ms;
          let timeframe = 0;
          if (frequency === "weekly") {
            timeframe = 604800000;
          } else if (frequency === "daily") {
            timeframe = 86400000;
          } else if (frequency === "hourly") {
            timeframe = 3600000;
          }

          const playable = lastSync + timeframe < now;

          return (
            <span>
              {params.row.valid &&
              playable &&
              (!limits?.[`${record}`]?.max || subscription?.[`${record}`] === -1) ? (
                <Button
                  variant="contained"
                  color="secondary"
                  className="circle-btn actions"
                  onClick={(e) => {
                    runJob(params.row);
                  }}
                >
                  {params.row.id === currRunJob?.id ? <CircularProgress /> : <PlayArrowRounded />}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className="circle-btn actions"
                  disabled
                >
                  <PlayArrowRounded />
                </Button>
              )}
            </span>
          );
        },
      },
      {
        field: "valid",
        headerName: "Valid",
        type: "boolean",
        width: 90,
        editable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <span className="valid">
              {params.row.valid ? (
                <CheckCircleOutlineRounded className="green" />
              ) : (
                <HighlightOffRounded className="red" />
              )}
            </span>
          );
        },
      },
      {
        field: "Actions",
        type: "actions",
        width: 40,
        getActions: (params) => [
          <GridActionsCellItem
            onClick={() => {
              setSelectionModel([params.id]);
              setSelectedRows([params.row]);
              setArchiveModalState(true);
            }}
            label="Archive"
            showInMenu
          />,
        ],
      },
    ];
  } else {
    columns = [
      ...columns,
      {
        field: "Actions",
        type: "actions",
        width: 40,
        getActions: (params) => [
          <GridActionsCellItem
            onClick={() => {
              setSelectionModel([params.id]);
              setDeleteModalState(true);
            }}
            label="Delete"
            showInMenu
          />,
        ],
      },
    ];
  }

  const runJob = (row) => {
    setCurrRunJob(row);
    setTimeout(() => {
      runRow
        .mutateAsync()
        .then((res) => {
          const syncDetails = res.details;
          if (syncDetails.rFailed === 0) {
            let updatedRows = [];

            rows.forEach((row) => {
              if (row.query_id === null && row.list_id === res.job.list_id) {
                row.last_sync_ms = Date.now();
              }

              if (row.list_id === null && row.query_id === res.job.query_id) {
                row.last_sync_ms = Date.now();
              }

              updatedRows.push(row);
            });

            setRows(updatedRows);
          }

          setCurrRunJob({});
        })
        .catch(() => {
          row.valid = false;
          setCurrRunJob({});
        });
    }, 1000);
  };

  if (isLoading || hsListsLoading || queriesLoading) {
    return (
      <div className="retrieving">
        <CircularProgress />
        <h2 className="process">Retrieving data...</h2>
      </div>
    );
  }

  return (
    <div className="dh-type dh-jobs">
      <div className="dh-alerts">
        <JobsAlerts {...props} data={data} hsLists={hsLists} queries={queries} />
      </div>

      {!data?.status &&
      hsConnect.status &&
      (((reqMaps?.emails || reqMaps?.calls || reqMaps?.notes) && record === "engagements") ||
        (reqMaps.apps && record === "applications") ||
        (reqMaps.contacts && record === "contacts")) &&
      (slConnect.status || direction === "toHubSpot") &&
      (!hsLists?.status || direction === "toHubSpot") &&
      (!queries?.status || direction === "toSlate") &&
      ((queries?.toHubSpot?.active?.length &&
        !queries?.toHubSpot?.active?.every((query) => query?.valid === 0)) ||
        direction === "toSlate") ? (
        <div className={`dh-tables ${state}`}>
          <State {...props} />
          <Table
            {...props}
            isLoading={isLoading}
            data={data}
            columns={columns}
            defaults={defaults}
          />
        </div>
      ) : (
        <Card className="dh-error">
          <CardContent>Data could not be displayed.</CardContent>
        </Card>
      )}

      <div className="dh-modals">
        {(!queries?.status || direction === "toSlate") &&
          (!hsLists?.status || direction === "toHubSpot") && (
            <JobsModal {...props} hsLists={hsLists} queries={queries} />
          )}
      </div>
    </div>
  );
}
