import React, { useEffect } from "react";
import { Button, Card, CardContent, CircularProgress, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  AccountTreeRounded,
  CheckCircleOutlineRounded,
  HighlightOffRounded,
  WarningAmberRounded,
} from "@mui/icons-material";
import useGetFieldMaps from "../hooks/GET/useGetFieldMaps.js";
import useGetHsFields from "../hooks/GET/useGetHsFields";
import useGetSlFields from "../hooks/GET/useGetSlFields";
import useGetQueries from "../hooks/GET/useGetQueries";
import FieldMapsAlerts from "../alerts/FieldMapsAlerts.js";
import FieldMapsModal from "../modals/FieldMapsModal.js";
import Table from "../modules/Table.js";
import State from "../modules/State.js";
import { Link } from "react-router-dom";

export default function FieldMapsTable(props) {
  const {
    record,
    state,
    direction,
    hsConnect,
    slConnect,
    setSelectionModel,
    setSaveModalState,
    setEdit,
    setSubmit,
    setArchiveModalState,
    setDeleteModalState,
    setSelectedRows,
  } = props;
  const valueMapFieldTypes = ["text", "select", "radio", "checkbox"];
  const { isLoading, data } = useGetFieldMaps(record);
  const { isLoading: hsFieldsLoading, data: hsFields } = useGetHsFields(direction, record);
  const { isLoading: slFieldsLoading, data: slFields } = useGetSlFields(record);
  const { isLoading: queriesLoading, data: queries } = useGetQueries(record);

  let columns = [];
  let defaults = [];

  if (direction === "toHubSpot") {
    columns = [
      {
        field: "sl_field_label",
        headerName: "Slate Fields",
        width: 300,
        editable: false,
      },
      {
        field: "hs_field_label",
        headerName: "HubSpot Fields",
        width: 300,
        editable: false,
      },
    ];

    if (record === "engagements") {
      columns = [
        ...columns,
        {
          field: "hs_field_type",
          headerName: "Type",
          width: 150,
          editable: false,
          renderCell: (params) => {
            return (
              <span style={{ textTransform: "uppercase" }}>
                {params.row.hs_field_type.replace("_", " ")}
              </span>
            );
          },
        },
      ];
    }

    if (record === "contacts") {
      defaults = [
        {
          id: 0.5,
          hs_field_value: "email",
          hs_field_label: "Email",
          hs_field_type: "email",
          sl_field_value: "Email",
          sl_field_label: "Email",
          sl_field_type: "Text/Unspecified",
          direction: direction,
          valid: true,
        },
      ];
    }
  } else if (direction === "toSlate") {
    columns = [
      {
        field: "hs_field_label",
        headerName: "HubSpot Fields",
        width: 300,
        editable: false,
      },
    ];

    defaults = [
      {
        id: 0.1,
        hs_field_value: "email",
        hs_field_label: "Email",
        hs_field_type: "email",
        direction: direction,
        valid: true,
      },
      {
        id: 0.2,
        hs_field_value: "firstname",
        hs_field_label: "First Name",
        hs_field_type: "text",
        direction: direction,
        valid: true,
      },
      {
        id: 0.3,
        hs_field_value: "lastname",
        hs_field_label: "Last Name",
        hs_field_type: "text",
        direction: direction,
        valid: true,
      },
    ];
  }

  if (direction === "toHubSpot" && state === "active" && record === "contacts") {
    columns = [
      ...columns,
      {
        field: "values",
        headerName: "Values",
        width: 150,
        editable: false,
        renderCell: (params) => {
          return (
            <span className="values">
              {params.row.valid && valueMapFieldTypes.includes(params.row.hs_field_type) ? (
                <Button
                  component={Link}
                  to={`/contacts/mappings/fields/${params.row.id}/values`}
                  variant="contained"
                  color="secondary"
                  className="circle-btn large"
                >
                  <AccountTreeRounded />
                </Button>
              ) : (
                <Tooltip
                  title={
                    !params.row.valid
                      ? "You cannot edit Values on invalid Field Mapping."
                      : `Value Mapping is not available for this field type: ${params.row.hs_field_type}`
                  }
                >
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="circle-btn large disabled"
                    >
                      <AccountTreeRounded />
                    </Button>
                  </div>
                </Tooltip>
              )}
            </span>
          );
        },
      },
    ];
  }

  if (state === "active") {
    columns = [
      ...columns,
      {
        field: "valid",
        headerName: "Valid",
        type: "boolean",
        width: 150,
        editable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <span className="valid">
              {params.row.valid &&
              !params.row.vm_exists &&
              valueMapFieldTypes.includes(params.row.hs_field_type) &&
              params.row.hs_field_type !== "text" ? (
                <Tooltip title="No Value Mappings exist.">
                  <WarningAmberRounded className="yellow" />
                </Tooltip>
              ) : (
                <span>
                  {params.row.valid ? (
                    <CheckCircleOutlineRounded className="green" />
                  ) : (
                    <HighlightOffRounded className="red" />
                  )}
                </span>
              )}
            </span>
          );
        },
      },
      {
        field: "Actions",
        type: "actions",
        width: 40,
        getActions: (params) => [
          <GridActionsCellItem
            onClick={() => {
              setSelectionModel([params.id]);
              setSelectedRows([params.row]);
              setSaveModalState(true);
              setEdit(true);
              setSubmit(true);
            }}
            label="Edit"
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => {
              setSelectionModel([params.id]);
              setSelectedRows([params.row]);
              setArchiveModalState(true);
            }}
            label="Archive"
            showInMenu
          />,
        ],
      },
    ];
  } else {
    columns = [
      ...columns,
      {
        field: "Actions",
        type: "actions",
        width: 40,
        getActions: (params) => [
          <GridActionsCellItem
            onClick={() => {
              setSelectionModel([params.id]);
              setSelectedRows([params.row]);
              setDeleteModalState(true);
            }}
            label="Delete"
            showInMenu
          />,
        ],
      },
    ];
  }

  useEffect(() => {}, [direction]);

  if (isLoading || hsFieldsLoading || slFieldsLoading || queriesLoading) {
    return (
      <div className="retrieving">
        <CircularProgress />
        <h2 className="process">Retrieving data...</h2>
      </div>
    );
  }

  return (
    <div className="dh-type dh-field-maps">
      <div className="dh-alerts">
        <FieldMapsAlerts
          {...props}
          data={data}
          hsFields={hsFields}
          slFields={slFields}
          queries={queries}
        />
      </div>
      {!data?.status &&
      !hsFields?.status &&
      hsConnect.status &&
      (!queries?.status || direction === "toSlate") &&
      (!slFields?.status || direction === "toSlate") &&
      (slFields?.length > 0 || direction === "toSlate") &&
      (slConnect.status || direction === "toHubSpot") ? (
        <div className={`dh-tables ${state}`}>
          <State {...props} />
          <Table
            {...props}
            isLoading={isLoading}
            data={data}
            columns={columns}
            defaults={defaults}
          />
        </div>
      ) : (
        <Card className="dh-error">
          <CardContent>Data could not be displayed.</CardContent>
        </Card>
      )}
      <div className="dh-modals">
        {!hsFields?.status && (!slFields?.status || direction === "toSlate") && (
          <FieldMapsModal {...props} hsFields={hsFields} slFields={slFields} />
        )}
      </div>
    </div>
  );
}
