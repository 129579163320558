import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Tooltip,
  Avatar,
  Button,
  Badge,
  Popover,
  Tab,
} from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import {
  Info,
  CheckCircle,
  CheckCircleOutline,
  Error,
  Cancel,
  Inbox,
  NotificationsNoneRounded,
} from "@mui/icons-material";
import useGetNotifications from "../hooks/GET/useGetNotifications";
import useUpdateNotifications from "../hooks/PUT/useUpdateNotifications";

export default function NotificationsPop() {
  const { data, isLoading } = useGetNotifications();
  const updateNotifications = useUpdateNotifications();

  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [notifications, setNotifications] = useState(data);
  const [notifTab, setNotifTab] = useState("1");
  const openNotifications = Boolean(anchorElNotifications);

  const handleClickNotifications = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    setAnchorElNotifications(null);
    setTimeout(() => {
      setNotifTab("1");
    }, 500);
  };

  const handleChangeNotifTab = (e, newTab) => {
    setNotifTab(newTab);
  };

  const markRead = async (id) => {
    await updateNotifications.mutateAsync({ id: id, read: true });
  };

  const markUnread = async (id) => {
    await updateNotifications.mutateAsync({ id: id, read: false });
  };

  const markAllRead = async () => {
    await updateNotifications.mutateAsync({ id: "all", read: true });
  };

  useEffect(() => {
    setNotifications(data);
  }, [data]);

  return (
    <Badge
      className="notif-badge"
      badgeContent={notifications?.unread?.length || 0}
      max={99}
      overlap="circular"
      color="error"
    >
      <Tooltip title="Notifications" placement="bottom-start">
        <IconButton onClick={handleClickNotifications} size="small" sx={{ ml: 2 }}>
          <NotificationsNoneRounded />
        </IconButton>
      </Tooltip>
      <Popover
        className="notif-pop"
        anchorEl={anchorElNotifications}
        open={openNotifications}
        onClose={handleCloseNotifications}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "100%",
            maxWidth: 700,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="title-area">
          <h2>Notifications</h2>
          {/* <Link
            className="settings"
            onClick={handleCloseNotifications}
            to="/settings/notifications"
          >
            <Settings />
          </Link> */}
          <Button
            onClick={markAllRead}
            className={`mark-all ${notifTab === "2" ? "disabled" : ""}`}
            aria-label="Mark all as read"
            variant="text"
            size="small"
            disabled={notifTab === "2" ? true : false}
          >
            Mark all as read &nbsp;
            <CheckCircle fontSize="small" />
          </Button>
        </div>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={notifTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChangeNotifTab} aria-label="Notification tabs">
                <Tab iconPosition="end" label="Unread" value="1" color="secondary" />
                <Tab iconPosition="end" label="Read" value="2" />
              </TabList>
            </Box>
            <TabPanel className="panel unread" value="1">
              {!isLoading && notifications?.unread?.length > 0 ? (
                <List dense={true}>
                  {notifications.unread.map((notif) => (
                    <ListItem
                      key={`unread-${notif.id}`}
                      secondaryAction={
                        <div>
                          <span className="time">
                            {Math.floor((Date.now() - notif.time_ms) / (1000 * 3600 * 24)) > 0
                              ? `${Math.floor((Date.now() - notif.time_ms) / (1000 * 3600 * 24))}d`
                              : `${Math.floor((Date.now() - notif.time_ms) / (1000 * 3600))}h`}{" "}
                          </span>
                          <IconButton
                            onClick={() => markRead(notif.id)}
                            edge="end"
                            aria-label="Mark as read"
                          >
                            <CheckCircle color="primary" />
                          </IconButton>
                        </div>
                      }
                    >
                      <ListItemAvatar className={notif.type}>
                        <Avatar>
                          {notif.type === "INFO" && <Info />}
                          {notif.type === "WARN" && <Error />}
                          {notif.type === "ERROR" && <Cancel />}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={notif.title} secondary={notif.message}></ListItemText>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    typography: "body1",
                    padding: "50px 20px",
                    textAlign: "center",
                    color: "rgba(0,0,0,0.6)",
                  }}
                >
                  <Inbox sx={{ fontSize: 60 }} />
                  <div>No notifications</div>
                </Box>
              )}
            </TabPanel>
            <TabPanel className="panel read" value="2">
              {!isLoading && notifications?.read?.length > 0 ? (
                <List dense={true}>
                  {notifications.read.map((notif, i) => (
                    <ListItem
                      key={`read-${i}`}
                      secondaryAction={
                        <div>
                          <span className="time">
                            {Math.floor((Date.now() - notif.time_ms) / (1000 * 3600 * 24)) > 0
                              ? `${Math.floor((Date.now() - notif.time_ms) / (1000 * 3600 * 24))}d`
                              : `${new Date(notif.time_ms).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  minute: "2-digit",
                                })}`}{" "}
                          </span>
                          <IconButton
                            onClick={() => markUnread(notif.id)}
                            edge="end"
                            aria-label="Mark as unread"
                          >
                            <CheckCircleOutline color="primary" />
                          </IconButton>
                        </div>
                      }
                    >
                      <ListItemAvatar className={notif.type}>
                        <Avatar>
                          {notif.type === "INFO" && <Info />}
                          {notif.type === "WARN" && <Error />}
                          {notif.type === "ERROR" && <Cancel />}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={notif.title} secondary={notif.message}></ListItemText>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    typography: "body1",
                    padding: "50px 20px",
                    textAlign: "center",
                    color: "rgba(0,0,0,0.6)",
                  }}
                >
                  <Inbox sx={{ fontSize: 60 }} />
                  <div>No notifications</div>
                </Box>
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Popover>
    </Badge>
  );
}
