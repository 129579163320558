import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../../../globalAlerts";

export default function useUpdateFieldMap(record, fieldMap) {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async () => {
      const token = await getAccessTokenSilently();

      const settings = await axios
        .get("/database/settings", {
          params: {
            tenant: metadata.username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => err);

      return await axios
        .put(
          `/${record}/mapping/field`,
          {
            grantType: "refresh_token",
            connectToken: settings.hs_refresh_token,
            tenant: metadata.username,
            fieldMap: fieldMap,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (res) => {
          if (record === "engagements") {
            await axios
              .get("/engagements/jobs/subrecords", {
                params: {
                  tenant: metadata.username,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .catch((err) => err);
          }

          return res.data;
        });
    },
    {
      onSettled: () => {
        if (record === "engagements") {
          queryClient.invalidateQueries("reqMappings");
          queryClient.invalidateQueries(["jobs", "engagements"]);
        }
      },
      onSuccess: () => {
        alertCtx.success("Field Mapping successfully updated");

        queryClient.setQueryData(["fieldMaps", record], (old) => {
          return {
            toHubSpot: {
              active: old.toHubSpot.active.map((val) => {
                return val.id === fieldMap.id ? fieldMap : val;
              }),
              archived: old.toHubSpot.archived,
            },
            toSlate: {
              active: old.toSlate.active.map((val) => {
                return val.id === fieldMap.id ? fieldMap : val;
              }),
              archived: old.toSlate.archived,
            },
          };
        });

        queryClient.invalidateQueries(["hsFieldOpts", fieldMap.id]);
        queryClient.invalidateQueries([`valueMaps - ${record}`, fieldMap.id]);
      },
      onError: () => {
        alertCtx.error(
          "Something went wrong, please try again later. If the problem persists, contact support."
        );
      },
    }
  );
}
