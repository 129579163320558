import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import DataHub from "../../components/data-hub/DataHub";

export default function SlateQueries(props) {
  const { setDirection } = props;

  useEffect(() => {
    setDirection("toHubSpot");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="contacts slate-queries">
      <Helmet>
        <title>Slate Queries | Contacts</title>
      </Helmet>
      <div className="content-wrapper">
        <DataHub {...props} record="contacts" screen="queries" title="Slate Queries" />
      </div>
    </Container>
  );
}
