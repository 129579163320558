import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useGetHsLists() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    "hsLists",
    async () => {
      const token = await getAccessTokenSilently();

      const settings = await axios
        .get("/database/settings", {
          params: {
            tenant: metadata.username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => err);

      return await axios
        .get("/hubspot/lists", {
          params: {
            grantType: "refresh_token",
            connectToken: settings.hs_refresh_token,
            tenant: metadata.username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let lists = [];

          res.data.forEach((list) => {
            lists.push({
              portalId: list.portalId,
              listId: list.listId,
              name: list.name,
              size: list.metaData.size,
            });
          });

          lists = lists.sort((a, b) => (a.name > b.name ? 1 : -1));

          return lists;
        })
        .catch((err) => err.response);
    },
    {
      initialData: () => queryClient.getQueryData("hsLists"),
      staleTime: Infinity,
      initialDataUpdatedAt: () => queryClient.getQueryState("hsLists")?.dataUpdatedAt,
    }
  );
}
