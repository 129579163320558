import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../../../globalAlerts";

export default function useAddSyncJob(record, direction) {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async (job) => {
      const token = await getAccessTokenSilently();

      return await axios
        .post(
          `/${record}/job`,
          {
            tenant: metadata.username,
            job: job,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data);
    },
    {
      onSuccess: (data, job) => {
        alertCtx.success("Sync Job successfully added");

        queryClient.setQueryData(["jobs", record], (old) => {
          const cron = `${job.expression.min} ${job.expression.hour} ${job.expression.date} ${job.expression.month} ${job.expression.day} ${job.expression.year}`;

          console.log(job);

          return {
            toHubSpot: {
              active:
                direction === "toHubSpot"
                  ? [
                      {
                        id: data.results.insertId,
                        name: job.jobName,
                        internal: job.jobInternal,
                        mins: job.expression.min,
                        hours: job.expression.hour,
                        day_month: job.expression.date,
                        month: job.expression.month,
                        day_week: job.expression.day,
                        year: job.expression.year,
                        direction: job.direction,
                        update_only: job.updateOnly,
                        record: job.record,
                        subrecord: job?.subrecord,
                        query_id: job.query.id,
                        cron: cron,
                        query: job.query.name,
                        query_url: job.query.url,
                        valid: true,
                        last_sync_ms: data.lastSync,
                      },
                      ...old.toHubSpot.active,
                    ]
                  : old.toHubSpot.active,
              archived: old.toHubSpot.archived,
            },
            toSlate: {
              active:
                direction === "toSlate"
                  ? [
                      {
                        id: data.results.insertId,
                        name: job.jobName,
                        internal: job.jobInternal,
                        mins: job.expression.min,
                        hours: job.expression.hour,
                        day_month: job.expression.date,
                        month: job.expression.month,
                        day_week: job.expression.day,
                        year: job.expression.year,
                        direction: job.direction,
                        record: job.record,
                        subrecord: job?.subrecord,
                        list_id: job?.list?.id,
                        list_name: job?.list?.name,
                        cron: cron,
                        valid: true,
                        last_sync_ms: data.lastSync,
                      },
                      ...old.toSlate.active,
                    ]
                  : old.toSlate.active,
              archived: old.toSlate.archived,
            },
          };
        });
      },
      onError: (err) => {
        if (err.response.status === 426) {
          alertCtx.warning(
            "Sync Job could not be added. Account limit for Sync Jobs has been reached."
          );
        } else {
          alertCtx.error(
            "Something went wrong, please try again later. If the problem persists, contact support."
          );
        }
      },
    }
  );
}
