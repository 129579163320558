export default function validate(rows, newRow, submit, edit, currEdit) {
  let valid = true;
  const details = {
    hs_value: {
      filled: true,
      unique: true,
      similar: true,
      errorMsg: "",
      state: true,
    },
    sl_value: {
      filled: true,
      regex: true,
      unique: true,
      similar: true,
      errorMsg: "",
      state: true,
    },
  };

  // Filled Checks
  if (submit) {
    for (const [key, value] of Object.entries(newRow)) {
      if ((value === null && key === "hs_value") || (value === "" && key === "sl_value")) {
        details[key].filled = false;
        details[key].errorMsg = "This field is required.";
        details[key].state = false;
        valid = false;
      }
    }
  }

  // Regex check
  const regex = /[`!@#$%^*+=[\]{};"\\|<>?~]/;

  if (regex.test(newRow.sl_value)) {
    details.sl_value.regex = false;
    details.sl_value.state = false;
    valid = false;
  }

  if (!details.sl_value.regex) {
    details.sl_value.errorMsg =
      "Invalid character(s). Only letters, numbers, colons, dashes, and underscores are allowed.";
  }

  // Unique check
  for (let obj of rows) {
    if (
      obj.hs_value === newRow.hs_value &&
      obj.sl_value === newRow.sl_value &&
      ((edit && obj.id !== newRow.id) || !edit)
    ) {
      details.hs_value.unique = false;
      details.hs_value.state = false;
      details.sl_value.unique = false;
      details.sl_value.state = false;
      valid = false;
      break;
    }
  }

  for (let obj of rows) {
    if (
      obj.hs_value === newRow.hs_value &&
      ((edit && obj.hs_value !== currEdit.hs_value) || !edit)
    ) {
      details.hs_value.similar = false;
      details.hs_value.errorMsg =
        "Another Value Mapping already uses this value. If this intentional, ignore this warning.";
      break;
    }
  }

  for (let obj of rows) {
    if (obj.sl_value === newRow.sl_value && ((edit && obj.id !== newRow.id) || !edit)) {
      details.sl_value.similar = false;
      details.sl_value.state = false;
      details.sl_value.errorMsg =
        "Another Value Mapping already uses this value. Slate values can be used only once.";
      valid = false;
      break;
    }
  }

  if (!details.hs_value.unique && !details.sl_value.unique) {
    details.sl_value.errorMsg =
      "An identical Value Mapping already exists. Please create a unique Value Mapping.";
    details.hs_value.errorMsg =
      "An identical Value Mapping already exists. Please create a unique Value Mapping.";
  }

  return { valid, details };
}
