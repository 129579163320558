import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../globalAlerts";

export default function useUpdateNotifications() {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async (props) => {
      const { id, read } = props;
      const token = await getAccessTokenSilently();

      return await axios
        .put(
          "/database/notifications",
          {
            id: id,
            read: read,
            tenant: metadata.username,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data);
    },
    {
      onSuccess: (data, props) => {
        const { id, read } = props;

        queryClient.setQueryData(["notifications"], (old) => {
          let updatedNotifications;

          // Marking item(s) as read
          if (read) {
            // Mark all items as read
            if (id === "all") {
              let updatedRead = [...old.read, ...old.unread];
              updatedRead.sort((a, b) => (a.time_ms < b.time_ms ? 1 : -1));

              updatedNotifications = {
                unread: [],
                read: updatedRead,
              };
            }
            // Mark one item as read
            else {
              let updatedUnread = old.unread.filter((val) => val.id !== id);
              let updatedRead = [...old.read, ...old.unread.filter((val) => val.id === id)];
              updatedUnread.sort((a, b) => (a.time_ms < b.time_ms ? 1 : -1));
              updatedRead.sort((a, b) => (a.time_ms < b.time_ms ? 1 : -1));

              updatedNotifications = {
                unread: updatedUnread,
                read: updatedRead,
              };
            }
          }

          // Marking item unread
          if (!read) {
            let updatedRead = old.read.filter((val) => val.id !== id);
            let updatedUnread = [...old.unread, ...old.read.filter((val) => val.id === id)];
            updatedUnread.sort((a, b) => (a.time_ms < b.time_ms ? 1 : -1));
            updatedRead.sort((a, b) => (a.time_ms < b.time_ms ? 1 : -1));

            updatedNotifications = {
              unread: updatedUnread,
              read: updatedRead,
            };
          }

          return updatedNotifications;
        });
      },
      onError: () => {
        alertCtx.error(
          "Something went wrong, please try again later. If the problem persists, contact support."
        );
      },
    }
  );
}
