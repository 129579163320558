import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useCheckHSConnection() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    "hsConnected",
    async () => {
      let hsObj = {
        settings: null,
        status: false,
      };

      const token = await getAccessTokenSilently();

      const settings = await axios
        .get("/database/settings", {
          params: {
            tenant: metadata.username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err;
        });

      if (settings.hs_refresh_token !== null) {
        await axios
          .get("/hubspot/account", {
            params: {
              grantType: "refresh_token",
              connectToken: settings.hs_refresh_token,
              tenant: metadata.username,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            hsObj = { ...hsObj, settings: res.data, status: true };
            return res.data;
          })
          .catch((err) => {
            hsObj = { ...hsObj, status: false };
            return err;
          });
      }

      return hsObj;
    },
    {
      initialData: () => queryClient.getQueryData("hsConnected"),
      staleTime: 100000,
      initialDataUpdatedAt: () => queryClient.getQueryState("hsConnected")?.dataUpdatedAt,
    }
  );
}
