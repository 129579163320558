import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import useDeleteQueries from "../hooks/DELETE/useDeleteQueries.js";
import useDeleteFieldMaps from "../hooks/DELETE/useDeleteFieldMaps.js";
import useDeleteJobs from "../hooks/DELETE/useDeleteJobs.js";
import useDeleteValueMaps from "../hooks/DELETE/useDeleteValueMaps.js";

export default function Delete(props) {
  const {
    record,
    screen,
    deleteModalState,
    setDeleteModalState,
    setSelectionModel,
    selectionModel,
    selectedRows,
  } = props;
  const deleteQueries = useDeleteQueries(record, selectionModel, selectedRows);
  const deleteFieldMaps = useDeleteFieldMaps(record, selectionModel);
  const deleteJobs = useDeleteJobs(record, selectionModel);
  const deleteValueMaps = useDeleteValueMaps(
    record,
    selectionModel,
    selectedRows[0]?.field_mapping_id
  );
  let deleteRows;
  if (screen === "queries") {
    deleteRows = deleteQueries;
  } else if (screen === "field_maps") {
    deleteRows = deleteFieldMaps;
  } else if (screen === "jobs") {
    deleteRows = deleteJobs;
  } else if (screen === "value_maps") {
    deleteRows = deleteValueMaps;
  }

  const handleDeleteRows = async () => {
    await deleteRows.mutateAsync().then(() => {
      setDeleteModalState(false);
      setSelectionModel([]);
    });
  };

  return (
    <div className="dh-delete">
      <Button onClick={() => setDeleteModalState(true)} variant="text" className="line-btn warning">
        Delete
      </Button>

      <Dialog open={deleteModalState} maxWidth="md" className="dh-modal">
        <IconButton onClick={() => setDeleteModalState(false)} className="close" aria-label="close">
          <CloseRounded />
        </IconButton>
        <DialogTitle>Are you sure you want to delete this?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {screen !== "value_maps" ? (
              <span>
                Permanently deleting an item will result in any associated Slate Queries, Field
                Mappings, Sync Schedules, and Sync History also being deleted. Proceed with caution.
              </span>
            ) : (
              <span>
                Deleting a Value Mapping cannot be undone and will result in data containing that
                value no longer being synced. Proceed with caution.
              </span>
            )}
          </DialogContentText>
          <DialogActions className="actions">
            <Button onClick={() => setDeleteModalState(false)} variant="text" className="line-btn">
              Cancel
            </Button>
            <Button onClick={handleDeleteRows} variant="contained" className="gradient-btn danger">
              <span>{deleteRows.isLoading ? <CircularProgress /> : "Delete"}</span>
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
