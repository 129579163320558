export default function validate(rows, newRow, submit, edit) {
  let valid = true;
  const details = {
    name: {
      filled: true,
      regex: true,
      unique: true,
      errorMsg: "",
      state: true,
    },
    subrecord: {
      filled: true,
      errorMsg: "",
      state: true,
    },
    url: {
      filled: true,
      unique: true,
      errorMsg: "",
      state: true,
    },
    user: {
      filled: true,
      errorMsg: "",
      state: true,
    },
    pass: {
      filled: true,
      errorMsg: "",
      state: true,
    },
  };

  // Filled Checks
  if (submit) {
    for (const [key, value] of Object.entries(newRow)) {
      if (value === "") {
        details[key].filled = false;
        details[key].errorMsg = "This field is required.";
        details[key].state = false;
        valid = false;
      }
    }
  }

  const regex = /[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;

  // NAME CHECKS ==========================
  // Regex check
  if (regex.test(newRow.name)) {
    details.name.regex = false;
    details.name.state = false;
    valid = false;
  }

  // Unique check
  for (let obj of rows) {
    if (
      obj.name?.toLowerCase() === newRow.name?.toLowerCase() &&
      ((edit && obj.id !== newRow.id) || !edit)
    ) {
      details.name.unique = false;
      details.name.state = false;
      valid = false;
      break;
    }
  }

  if (!details.name.unique) {
    details.name.errorMsg = "A query with this name already exists. Please choose a unique name.";
  } else if (!details.name.regex) {
    details.name.errorMsg = "Invalid character(s). Only letters, numbers, and dashes are allowed.";
  }

  // URL CHECKS ==========================

  // Unique check
  for (let obj of rows) {
    if (
      obj.url?.toLowerCase() === newRow.url?.toLowerCase() &&
      ((edit && obj.id !== newRow.id) || !edit)
    ) {
      details.url.unique = false;
      details.url.state = false;
      valid = false;
      break;
    }
  }

  if (!details.url.unique) {
    details.url.errorMsg = "A query with this URL already exists. Please insert a unique URL.";
  }

  return { valid, details };
}
