import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Container,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  CheckCircleOutlineRounded,
  HighlightOffRounded,
  Source,
  CloseRounded,
  WarningAmberRounded,
} from "@mui/icons-material";
import useGetSyncHistory from "../../components/data-hub/hooks/GET/useGetSyncHistory";
import useGetLogsByTimestamp from "../../hooks/GET/useGetLogsByTimestamp";
import useGetSettings from "../../hooks/GET/useGetSettings";
import useGetSubscriptionDetails from "../../hooks/GET/useGetSubscriptionDetails";
import Alerts from "../../components/data-hub/modules/Alerts";

export default function SyncHistory() {
  const [selectionModel, setSelectionModel] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [openLogsModal, setOpenLogsModal] = useState(false);
  const [logsID, setLogsID] = useState();
  const [logsTimestamp, setLogsTimestamp] = useState();
  const [errorOnly, setErrorOnly] = useState(false);
  const {
    isLoading: historyIsLoading,
    isFetching: historyIsFetching,
    data: syncs,
  } = useGetSyncHistory("applications");
  const { isFetching: logsIsFetching, data: logs } = useGetLogsByTimestamp(logsTimestamp);
  const { isLoading: settingsIsLoading, data: settings } = useGetSettings();
  const { isLoading: subIsLoading, data: subscription } = useGetSubscriptionDetails();

  const handleLogModalOpen = (row) => {
    setOpenLogsModal(true);
    setLogsID(row.id);
    setLogsTimestamp(row.unix * 1000);
  };

  const handleLogModalClose = () => {
    setOpenLogsModal(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sync #",
      width: 75,
      editable: false,
      renderCell: (params) => {
        return <strong>#{params.row.id}</strong>;
      },
    },
    {
      field: "direction",
      headerName: "Direction",
      width: 155,
      editable: false,
      renderCell: (params) => {
        if (params.row.direction === "toHubSpot") {
          return "To HubSpot";
        } else if (params.row.direction === "toSlate") {
          return "To Slate";
        }
      },
    },
    {
      field: "r_created",
      headerName: "Created",
      width: 100,
      editable: false,
    },
    {
      field: "r_updated",
      headerName: "Updated",
      width: 100,
      editable: false,
    },
    {
      field: "r_failed",
      headerName: "Failed",
      width: 100,
      editable: false,
    },
    {
      field: "job_id",
      headerName: "Job ID",
      width: 100,
      editable: false,
      renderCell: (params) => {
        return <strong>#{params.row.job_id}</strong>;
      },
    },
    {
      field: "logs",
      headerName: "Logs",
      width: 90,
      editable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              handleLogModalOpen(params.row);
            }}
            className="circle-btn large"
            variant="contained"
            color="secondary"
          >
            <Source />
          </Button>
        );
      },
    },
    {
      field: "valid",
      headerName: "Valid",
      width: 90,
      editable: false,
      renderCell: (params) => {
        return (
          <span className="valid">
            {(params.row.r_created > 0 || params.row.r_updated > 0) &&
            params.row.r_failed === 0 &&
            params.row.r_partial === 0 &&
            params.row.max === 0 &&
            !params.row.err ? (
              <CheckCircleOutlineRounded className="green" />
            ) : (
              ""
            )}

            {((params.row.r_created > 0 || params.row.r_updated > 0) &&
              params.row.r_failed > 0 &&
              !params.row.err) ||
            (params.row.r_created === 0 &&
              params.row.r_updated === 0 &&
              params.row.r_failed === 0 &&
              !params.row.err) ||
            (params.row.r_partial > 0 &&
              (params.row.r_created !== 0 || params.row.r_updated !== 0) &&
              !params.row.err) ||
            (params.row.max === 1 && params.row.r_failed === 0 && !params.row.err) ? (
              <WarningAmberRounded className="yellow" />
            ) : (
              ""
            )}

            {(params.row.r_created === 0 &&
              params.row.r_updated === 0 &&
              params.row.r_failed > 0) ||
            params.row.err ? (
              <HighlightOffRounded className="red" />
            ) : (
              ""
            )}
          </span>
        );
      },
    },
    {
      field: "create_date",
      headerName: "Timestamp",
      width: 500,
      editable: false,
      renderCell: (params) => {
        const date = new Date(params.row.create_date).toLocaleString([], {
          dateStyle: "long",
          timeStyle: "long",
          hourCycle: "h24",
        });

        return `${date}`;
      },
    },
  ];

  if (settingsIsLoading || subIsLoading) {
    return (
      <div className="retrieving middle">
        <CircularProgress />
        <h2 className="process">Authenticating...</h2>
      </div>
    );
  }

  return (
    <Container className="applications sync-history">
      <Helmet>
        <title>Sync History | Applications</title>
      </Helmet>
      {settings?.hs_enterprise && subscription.id >= 3 ? (
        <div className="content-wrapper">
          <h3 className="kicker">Applications</h3>
          <h1 className="title">Sync History</h1>
          {historyIsLoading || historyIsFetching ? (
            <div className="retrieving">
              <CircularProgress />
              <h2 className="process">Retrieving History...</h2>
            </div>
          ) : (
            <div className="table-wrap">
              <Alerts
                content={{
                  error: [
                    {
                      test: !syncs?.response,
                      title: "There was an error retrieving your Sync History.",
                      message:
                        "Please refresh and try again. If the problem persists, please contact support.",
                    },
                  ],
                }}
              />
              {!syncs?.response ? (
                <div className="history-table table">
                  <div className="data-table">
                    {syncs && (
                      <DataGrid
                        rows={syncs}
                        columns={columns}
                        page={page}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        disableSelectionOnClick
                        selectionModel={selectionModel}
                        onPageChange={(newPage) => {
                          setSelectionModel([]);
                          setPage(newPage);
                        }}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <Card className="dh-error">
                  <CardContent>Data could not be displayed.</CardContent>
                </Card>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="content-wrapper">
          <Card className="dh-error">
            <CardContent style={{ textAlign: "center" }}>
              <h2 className="red">ACCESS DENIED.</h2>
              <div>
                You must have a DD Sync Gold level account connected to a <br />
                HubSpot Enterprise level account in order to access Applications syncing tools.
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {settings?.hs_enterprise && subscription.id >= 3 ? (
        <Dialog className="logs-modal" open={openLogsModal} maxWidth="md" fullWidth>
          <IconButton onClick={handleLogModalClose} className="close" aria-label="close">
            <CloseRounded />
          </IconButton>
          <DialogTitle>
            <h2>Sync Logs #{logsID}</h2>
          </DialogTitle>
          {logs?.length && !logsIsFetching > 0 ? (
            <DialogContent>
              <DialogContentText>
                Below are all log events within 5 minutes of the current sync.
              </DialogContentText>
              <div className="logs">
                <div className="console">
                  {logs.map((log, i) => (
                    <code key={i} className={log.type !== "ERROR" && errorOnly ? "hidden" : ""}>
                      <span className="timestamp">{new Date(log.timestamp).toUTCString()}: </span>
                      <span className={`type ${log.type}`}>[{log.type}] </span>
                      <span className="action">{log.action}</span> --
                      {log.message.length >= 30 && <br></br>}
                      <span className="message">
                        {log.message.slice(0, 1000)}
                        {log.message.length >= 1000 ? "..." : ""}
                      </span>
                    </code>
                  ))}
                </div>

                <div className="details">
                  <small className="lines">{logs?.length} lines</small>
                  <div className="error-only">
                    <small>Error logs ONLY</small>
                    <Switch
                      checked={errorOnly}
                      onChange={(e) => setErrorOnly(e.target.checked)}
                      inputProps={{ "aria-label": "controlled" }}
                      color="error"
                      aria-label="Error Logs ONLY"
                    />
                  </div>
                </div>
              </div>
              <DialogActions>
                <Button variant="text" className="line-btn warning" onClick={handleLogModalClose}>
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          ) : (
            <div className="retrieving">
              <CircularProgress />
              <h2 className="process">Retrieving Logs...</h2>
            </div>
          )}
        </Dialog>
      ) : (
        ""
      )}
    </Container>
  );
}
