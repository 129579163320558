export default function cronTZConvert(expression, toUTC) {
  if (typeof expression.hour === "number") {
    const offset = new Date().getTimezoneOffset() / 60;
    let newDay = expression.day;
    let newHour = expression.hour;
    let adjustment;

    if (toUTC) {
      adjustment = expression.hour + offset;
    } else {
      adjustment = expression.hour - offset;
    }

    if (adjustment > 23) {
      newHour = adjustment - 24;

      if (typeof newDay === "number") {
        newDay + 1 > 7 ? (newDay = 1) : newDay++;
      }
    } else if (adjustment < 0) {
      newHour = adjustment + 24;

      if (typeof newDay === "number") {
        newDay - 1 < 1 ? (newDay = 7) : newDay--;
      }
    } else {
      newHour = adjustment;
    }

    return {
      date: "?",
      day: newDay,
      hour: newHour,
      min: expression.min,
      month: "*",
      year: "*",
    };
  } else {
    return expression;
  }
}
