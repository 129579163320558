import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Card, CardContent } from "@mui/material";
import { CircularProgress } from "@mui/material";
import DataHub from "../../components/data-hub/DataHub";
import useGetSettings from "../../hooks/GET/useGetSettings";
import useGetSubscriptionDetails from "../../hooks/GET/useGetSubscriptionDetails";

export default function FieldMappings(props) {
  const { isLoading: settingsIsLoading, data: settings } = useGetSettings();
  const { isLoading: subIsLoading, data: subscription } = useGetSubscriptionDetails();

  if (settingsIsLoading || subIsLoading) {
    return (
      <div className="retrieving middle">
        <CircularProgress />
        <h2 className="process">Authenticating...</h2>
      </div>
    );
  }

  return (
    <Container className="applications field-mappings">
      <Helmet>
        <title>Field Mappings | Applications</title>
      </Helmet>
      <div className="content-wrapper">
        {settings?.hs_enterprise && subscription.id >= 3 ? (
          <DataHub {...props} record="applications" screen="field_maps" title="Field Mappings" />
        ) : (
          <Card className="dh-error">
            <CardContent style={{ textAlign: "center" }}>
              <h2 className="red">ACCESS DENIED.</h2>
              <div>
                You must have a DD Sync Gold level account connected to a <br />
                HubSpot Enterprise level account in order to access Applications syncing tools.
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </Container>
  );
}
