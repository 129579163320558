import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../../../globalAlerts";

export default function useArchiveQueries(record, ids, rows) {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async () => {
      const token = await getAccessTokenSilently();

      return await axios
        .put(
          `/${record}/queries/archive`,
          {
            ids: ids,
            rows: rows,
            tenant: metadata.username,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        alertCtx.success("Slate Queries successfully archived");

        queryClient.setQueryData(["queries", record], (old) => {
          return {
            toHubSpot: {
              active: old.toHubSpot.active.filter((val) => {
                if (!ids.includes(val.id)) {
                  return val;
                }

                return false;
              }),
              archived: [
                ...old.toHubSpot.active.filter((val) => {
                  if (ids.includes(val.id)) {
                    return val;
                  }

                  return false;
                }),
                ...old.toHubSpot.archived,
              ],
            },
            toSlate: {
              active: old.toSlate.active.filter((val) => {
                if (!ids.includes(val.id)) {
                  return val;
                }

                return false;
              }),
              archived: [
                ...old.toSlate.active.filter((val) => {
                  if (ids.includes(val.id)) {
                    return val;
                  }

                  return false;
                }),
                ...old.toSlate.archived,
              ],
            },
          };
        });

        queryClient.invalidateQueries(["fieldMaps", record]);
        queryClient.invalidateQueries([`slFields - ${record}`, "toHubSpot"]);
        queryClient.invalidateQueries([`hsFields - ${record}`, "toHubSpot"]);
        queryClient.invalidateQueries(["jobs", record]);
      },
      onError: () => {
        alertCtx.error(
          "Something went wrong, please try again later. If the problem persists, contact support."
        );
      },
    }
  );
}
