export default function validate(rows, newRow, submit, edit) {
  let valid = true;
  const details = {
    hs_field_value: {
      filled: true,
      unique: true,
      similar: true,
      restricted: true,
      errorMsg: "",
      state: true,
    },
    sl_field_value: {
      filled: true,
      unique: true,
      similar: true,
      errorMsg: "",
      state: true,
    },
  };

  // Filled Checks
  if (submit) {
    for (const [key, value] of Object.entries(newRow)) {
      if (value === null && (key === "hs_field_value" || key === "sl_field_value")) {
        details[key].filled = false;
        details[key].errorMsg = "This field is required.";
        details[key].state = false;
        valid = false;
      }
    }
  }

  // Unique check
  for (let obj of rows) {
    if (
      obj.hs_field_value === newRow.hs_field_value &&
      obj.sl_field_value === newRow.sl_field_value &&
      ((edit && obj.id !== newRow.id) || !edit)
    ) {
      details.hs_field_value.unique = false;
      details.hs_field_value.state = false;
      details.sl_field_value.unique = false;
      details.sl_field_value.state = false;
      valid = false;
      break;
    }
  }

  for (let obj of rows) {
    if (obj.hs_field_value === newRow.hs_field_value && ((edit && obj.id !== newRow.id) || !edit)) {
      details.hs_field_value.similar = false;
      if (newRow.record !== "engagements") {
        valid = false;
      }
      break;
    }
  }

  for (let obj of rows) {
    if (obj.sl_field_value === newRow.sl_field_value && ((edit && obj.id !== newRow.id) || !edit)) {
      details.sl_field_value.similar = false;
      break;
    }
  }

  // Restricted checks
  const restrictedFieldTypes = ["number", "select", "checkbox", "radio"];

  if (restrictedFieldTypes.includes(newRow.hs_field_type) && !edit) {
    details.hs_field_value.restricted = false;
    details.hs_field_value.errorMsg = `This field is of type "${newRow.hs_field_type}" and therefore the values & formatting it accepts are restricted. Make sure the associated Slate Field is providing proper values to avoid errors.`;
  }

  if (!details.hs_field_value.unique && !details.sl_field_value.unique) {
    details.sl_field_value.errorMsg =
      "An identical Field Mapping already exists. Please create a unique Field Mapping.";
    details.hs_field_value.errorMsg =
      "An identical Field Mapping already exists. Please create a unique Field Mapping.";
  } else if (!details.hs_field_value.similar || !details.sl_field_value.similar) {
    if (!details.hs_field_value.similar) {
      if (newRow.record !== "engagements") {
        details.hs_field_value.errorMsg =
          "Another Field Mapping already uses this field. HubSpot fields may only be used once.";
      } else {
        details.hs_field_value.errorMsg =
          "Another Field Mapping already uses this field. If this is intentional, you may ignore this warning.";
      }
    }
    if (!details.sl_field_value.similar) {
      if (newRow.record !== "engagements") {
        details.sl_field_value.errorMsg =
          "Another Field Mapping already uses this field. Using the same field multiple times may result in inaccurate or conflicting data.";
      } else {
        details.sl_field_value.errorMsg =
          "Another Field Mapping already uses this field. If this is intentional, you may ignore this warning.";
      }
    }
  }

  return { valid, details };
}
