import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";
import dirDataFormat from "../../utils/dirDataFormat";

export default function useGetValueMaps(record, fieldMapId) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    [`valueMaps - ${record}`, fieldMapId],
    async () => {
      try {
        const token = await getAccessTokenSilently();

        const valueMaps = await axios.get(`/${record}/mappings/values`, {
          params: {
            tenant: metadata.username,
            fieldMapId: fieldMapId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const formattedValueMaps = await dirDataFormat(valueMaps);
        return formattedValueMaps;
      } catch (err) {
        return err.response;
      }
    },
    {
      initialData: () => queryClient.getQueryData([`valueMaps - ${record}`, fieldMapId]),
      staleTime: Infinity,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState([`valueMaps - ${record}`, fieldMapId])?.dataUpdatedAt,
    }
  );
}
