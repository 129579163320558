import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import Add from "./modules/Add";
import Edit from "./modules/Edit";
import Archive from "./modules/Archive";
import Delete from "./modules/Delete";
import QueriesTable from "./tables/QueriesTable";
import FieldMapsTable from "./tables/FieldMapsTable";
import Count from "./modules/Count";
import useGetSubscriptionDetails from "../../hooks/GET/useGetSubscriptionDetails";
import useGetLimits from "../../hooks/GET/useGetLimits";
import useCheckHsConnection from "../../hooks/GET/useCheckHsConnection";
import useCheckSlConnection from "../../hooks/GET/useCheckSlConnection";
import useCheckReqMaps from "./hooks/GET/useCheckReqMaps";
import JobsTable from "./tables/JobsTable";
import XML from "./modules/XML";
import ValueMapsTable from "./tables/ValueMapsTable";

export default function DataHub(props) {
  const { record, screen, title, direction } = props;
  const [marker, setMarker] = useState(null);
  const [state, setState] = useState("active");
  const [selectionModel, setSelectionModel] = useState([]);
  const [count, setCount] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rows, setRows] = useState([]);
  const [newRow, setNewRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [edit, setEdit] = useState(false);
  const [saveModalState, setSaveModalState] = useState(false);
  const [archiveModalState, setArchiveModalState] = useState(false);
  const [activateModalState, setActivateModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [xmlModalState, setXMLModalState] = useState(false);
  const { isLoading: limitsLoading, data: limits } = useGetLimits();
  const { isLoading: subscriptionLoading, data: subscription } = useGetSubscriptionDetails();
  const { isLoading: hsConnectLoading, data: hsConnect } = useCheckHsConnection();
  const { isLoading: slConnectLoading, data: slConnect } = useCheckSlConnection();
  const { isLoading: reqMapsLoading, data: reqMaps } = useCheckReqMaps();

  const dhProps = {
    ...props,
    marker,
    setMarker,
    state,
    setState,
    selectionModel,
    setSelectionModel,
    count,
    setCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    rows,
    setRows,
    newRow,
    setNewRow,
    selectedRows,
    setSelectedRows,
    submit,
    setSubmit,
    edit,
    setEdit,
    limits,
    subscription,
    hsConnect,
    slConnect,
    reqMaps,
    saveModalState,
    setSaveModalState,
    archiveModalState,
    setArchiveModalState,
    activateModalState,
    setActivateModalState,
    deleteModalState,
    setDeleteModalState,
    xmlModalState,
    setXMLModalState,
  };

  if (
    subscriptionLoading ||
    limitsLoading ||
    hsConnectLoading ||
    slConnectLoading ||
    reqMapsLoading
  ) {
    return (
      <div className="retrieving">
        <CircularProgress />
        <h2 className="process">Loading...</h2>
      </div>
    );
  }

  return (
    <div className="data-hub">
      <div className="dh-head">
        <div className="dh-label">
          <h3 className="kicker">{record}</h3>
          <h1 className="title">{title}</h1>
        </div>
        <Add {...dhProps} />
      </div>
      <div className="dh-body">
        {screen === "queries" && <QueriesTable {...dhProps} />}
        {screen === "field_maps" && <FieldMapsTable {...dhProps} />}
        {screen === "value_maps" && <ValueMapsTable {...dhProps} />}
        {screen === "jobs" && <JobsTable {...dhProps} />}
      </div>
      <div className="dh-foot">
        {state === "active" ? (
          <div className="dh-active-btns">
            {selectionModel?.length === 0 && screen === "field_maps" && direction === "toSlate" && (
              <XML {...dhProps} />
            )}
            {selectionModel?.length > 0 && screen !== "value_maps" && <Archive {...dhProps} />}
            {selectionModel?.length > 0 && screen === "value_maps" && <Delete {...dhProps} />}
            {selectionModel?.length === 1 && screen !== "jobs" && <Edit {...dhProps} />}
          </div>
        ) : (
          <div className="dh-archive-btns">
            {selectionModel?.length > 0 && <Delete {...dhProps} />}
          </div>
        )}

        <Count {...dhProps} />
      </div>
    </div>
  );
}
