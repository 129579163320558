import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  Autocomplete,
} from "@mui/material";
import useAddRow from "../hooks/POST/useAddValueMap";
import useUpdateRow from "../hooks/PUT/useUpdateValueMap";
import validate from "../utils/validateValueMaps.js";
import validateSubmit from "../utils/validateSubmit.js";
import { ArrowCircleRightOutlined, CloseRounded } from "@mui/icons-material";

export default function ValueMapsModal(props) {
  const {
    record,
    direction,
    setSelectionModel,
    selectionModel,
    setPage,
    newRow,
    setNewRow,
    rows,
    saveModalState,
    setSaveModalState,
    setSubmit,
    submit,
    setEdit,
    edit,
    hsFieldOpts,
    fieldMapId,
    fieldMapType,
  } = props;
  const addRow = useAddRow(record, newRow, direction);
  const updateRow = useUpdateRow(record, newRow);
  const [currEdit, setCurrEdit] = useState("");
  const validateRow = validate(rows, newRow, submit, edit, currEdit);

  const dbStruct = {
    id: null,
    hs_value: fieldMapType === "text" ? "" : null,
    sl_value: "",
    field_mapping_id: fieldMapId,
    direction: direction,
    record: record,
    valid: true,
  };

  const handleSaveModalClose = () => {
    setSelectionModel([]);
    setNewRow(dbStruct);
    setSubmit(false);
    setEdit(false);
    setSaveModalState(false);
  };

  useEffect(() => {
    if (!edit) {
      setNewRow(dbStruct);
    } else {
      const selectedRow = rows.filter((row) => row.id === selectionModel[0]);

      const currRow = {
        hs_value: selectedRow[0]?.hs_value,
        sl_value: selectedRow[0]?.sl_value,
        id: selectedRow[0]?.id,
        field_mapping_id: fieldMapId,
        direction: direction,
        valid: true,
      };

      setCurrEdit(currRow);
      setNewRow(currRow);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveModalState]);

  const handleSaveRow = async (e) => {
    validateSubmit("valueMaps", rows, newRow, true, edit, currEdit).then(async (res) => {
      if (res.valid) {
        if (!newRow.id) {
          await addRow.mutateAsync().then(() => {
            setPage(0);
            handleSaveModalClose();
          });
        } else {
          await updateRow.mutateAsync().then(() => {
            handleSaveModalClose();
          });
        }
      }
    });
  };

  return (
    <div className="dh-mappings-form">
      <Dialog open={saveModalState} maxWidth="md" fullWidth className="dh-modal save">
        <IconButton onClick={handleSaveModalClose} className="close" aria-label="close">
          <CloseRounded />
        </IconButton>
        <DialogTitle>Add/Update Value Mapping</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill out the fields below to add or update an Value Mapping.
          </DialogContentText>
          <form className="dh-modal-form">
            <div className="value-map-inputs">
              {direction === "toHubSpot" && (
                <TextField
                  id="sl-value"
                  label="Slate Value"
                  variant="outlined"
                  value={newRow?.sl_value}
                  fullWidth
                  required
                  onChange={(e) => {
                    setNewRow({
                      ...newRow,
                      sl_value: e.target.value,
                    });
                  }}
                  error={!validateRow.details.sl_value.state}
                  helperText={
                    !validateRow.details.sl_value.state ? validateRow.details.sl_value.errorMsg : ""
                  }
                />
              )}
              {direction === "toHubSpot" && <ArrowCircleRightOutlined />}

              {fieldMapType === "text" ? (
                <TextField
                  id="hs-value"
                  label="HubSpot Value"
                  variant="outlined"
                  value={newRow?.hs_value}
                  fullWidth
                  required
                  onChange={(e) => {
                    setNewRow({
                      ...newRow,
                      hs_value: e.target.value,
                    });
                  }}
                  error={!validateRow.details.hs_value.state}
                  helperText={
                    !validateRow.details.hs_value.state ? validateRow.details.hs_value.errorMsg : ""
                  }
                />
              ) : (
                <Autocomplete
                  id="hs-value"
                  value={
                    newRow.hs_value
                      ? {
                          hs_option_value: newRow?.hs_value,
                          hs_option_label:
                            hsFieldOpts.filter(
                              (opt) => opt?.hs_option_value === newRow?.hs_value
                            )[0]?.hs_option_label || null,
                        }
                      : null
                  }
                  options={hsFieldOpts}
                  isOptionEqualToValue={(option, value) =>
                    option?.hs_option_value === value?.hs_option_value
                  }
                  getOptionLabel={(option) => option?.hs_option_label}
                  renderInput={(params) => (
                    <TextField
                      className={
                        !validateRow.details.hs_value.similar && validateRow.details.hs_value.state
                          ? "warning"
                          : ""
                      }
                      error={
                        !validateRow.details.hs_value.state || !validateRow.details.hs_value.similar
                      }
                      helperText={
                        !validateRow.details.hs_value.state || !validateRow.details.hs_value.similar
                          ? validateRow.details.hs_value.errorMsg
                          : ""
                      }
                      required
                      {...params}
                      label="HubSpot Value"
                    />
                  )}
                  renderOption={(props, option) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      {...props}
                      key={option.hs_option_value}
                    >
                      <div style={{ color: "#1976D2" }}>{option.hs_option_label}</div>
                      <small style={{ color: "#737373", lineBreak: "anywhere" }}>
                        internal:
                        <span style={{ fontWeight: "bold" }}>&nbsp;{option.hs_option_value}</span>
                      </small>
                    </div>
                  )}
                  onChange={(e, option) => {
                    setNewRow({
                      ...newRow,
                      hs_value: option?.hs_option_value,
                    });
                  }}
                />
              )}

              {direction === "toSlate" && <ArrowCircleRightOutlined />}

              {direction === "toSlate" && (
                <TextField
                  id="sl-value"
                  label="Slate Value"
                  variant="outlined"
                  value={newRow?.sl_value}
                  fullWidth
                  required
                  onChange={(e) => {
                    setNewRow({
                      ...newRow,
                      sl_value: e.target.value,
                    });
                  }}
                  error={!validateRow.details.sl_value.state}
                  helperText={
                    !validateRow.details.sl_value.state ? validateRow.details.sl_value.errorMsg : ""
                  }
                />
              )}
            </div>
            <DialogActions className="actions">
              <Button onClick={handleSaveModalClose} variant="text" className="line-btn warning">
                Cancel
              </Button>
              <Button
                variant="contained"
                className="gradient-btn"
                onClick={() => {
                  setSubmit(true);
                  handleSaveRow();
                }}
              >
                {addRow.isLoading || updateRow.isLoading ? <CircularProgress /> : "Save"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
