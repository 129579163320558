import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useGetHsFieldOptions(id) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    ["hsFieldOptions", id],
    async () => {
      const token = await getAccessTokenSilently();

      const settings = await axios
        .get("/database/settings", {
          params: {
            tenant: metadata.username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => err);

      return await axios
        .get("/hubspot/field/options", {
          params: {
            grantType: "refresh_token",
            connectToken: settings.hs_refresh_token,
            tenant: metadata.username,
            fieldMapId: id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const hsFieldOpts = res.data.map((opt) => {
            return { hs_option_label: opt.label, hs_option_value: opt.value };
          });

          return hsFieldOpts;
        })
        .catch((err) => err.response);
    },
    {
      initialData: () => queryClient.getQueryData(["hsFieldOpts", id]),
      staleTime: Infinity,
      initialDataUpdatedAt: () => queryClient.getQueryState(["hsFieldOpts", id])?.dataUpdatedAt,
    }
  );
}
