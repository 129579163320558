import React, { useEffect } from "react";

export default function Count(props) {
  const { count, subscription, screen } = props;

  useEffect(() => {}, [count]);

  if (count === null) {
    return true;
  }

  return (
    <div
      className={
        count >= subscription[screen]
          ? "max dh-count"
          : `${count / subscription[screen] >= 0.8 ? "close dh-count" : "dh-count"}`
      }
    >
      Limit:{" "}
      <span>
        {count} / {subscription[screen]}
      </span>
    </div>
  );
}
