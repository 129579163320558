import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../../../globalAlerts";

export default function useAddValueMap(record, valueMap, direction) {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async () => {
      const token = await getAccessTokenSilently();

      return await axios
        .post(
          `/${record}/mapping/value`,
          {
            tenant: metadata.username,
            valueMap: valueMap,
            direction: direction,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        alertCtx.success("Value Mapping successfully added");

        queryClient.setQueryData(
          [`valueMaps - ${record}`, `${valueMap.field_mapping_id}`],
          (old) => {
            return {
              toHubSpot: {
                active:
                  direction === "toHubSpot"
                    ? [{ ...valueMap, id: data.insertId, valid: true }, ...old.toHubSpot.active]
                    : old.toHubSpot.active,
                archived: old.toHubSpot.archived,
              },
              toSlate: {
                active:
                  direction === "toSlate"
                    ? [{ ...valueMap, id: data.insertId, valid: true }, ...old.toSlate.active]
                    : old.toSlate.active,
                archived: old.toSlate.archived,
              },
            };
          }
        );
      },
      onError: (err) => {
        if (err.response.status === 426) {
          alertCtx.warning(
            "Value Mapping could not be added. Account limit for Value Mappings has been reached."
          );
        } else {
          alertCtx.error(
            "Something went wrong, please try again later. If the problem persists, contact support."
          );
        }
      },
    }
  );
}
