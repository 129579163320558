import React from "react";
import { Container } from "@mui/material";
import logo from "../media/images/logos/ddsync/logo.svg";
import { useAuth0 } from "@auth0/auth0-react";
import SyncStats from "../components/dashboard/SyncStats";
import UpcomingSyncs from "../components/dashboard/UpcomingSyncs";
import UsageDetails from "../components/dashboard/UsageDetails";

export default function Home() {
  const { user } = useAuth0();

  return (
    <Container className={`home center ${user ? "auth" : "no-auth"}`}>
      <div className="header">
        <img className="logo" src={logo} alt="DD Sync"></img>
        {user ? (
          <h2 className="welcome">
            Welcome back, <strong>{user.name}</strong>!
          </h2>
        ) : (
          <h2 className="login">Login to view your account.</h2>
        )}
      </div>

      {user ? (
        <div className="upcoming">
          <UpcomingSyncs />
        </div>
      ) : (
        ""
      )}

      {user ? (
        <div className="information">
          <SyncStats />
          <UsageDetails />
        </div>
      ) : (
        ""
      )}
    </Container>
  );
}
