import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useGetSyncStats() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    "syncStats",
    async () => {
      const token = await getAccessTokenSilently();

      return await axios
        .get("/database/sync/stats", {
          params: {
            tenant: metadata.username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => err);
    },
    {
      initialData: () => queryClient.getQueryData("syncStats"),
      staleTime: Infinity,
      initialDataUpdatedAt: () => queryClient.getQueryState("syncStats")?.dataUpdatedAt,
    }
  );
}
