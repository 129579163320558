import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../globalAlerts";

export default function useSubCreate() {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async (pkg) => {
      return new Promise(async (resolve, reject) => {
        const token = await getAccessTokenSilently();

        const settings = await axios
          .get("/database/settings", {
            params: {
              tenant: metadata.username,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err;
          });

        const account = await axios
          .get("/database/account", {
            params: {
              tenant: metadata.username,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err;
          });

        return await axios
          .post(
            `/stripe/subscription/create`,
            {
              tenant: metadata.username,
              pkg: pkg,
              customerId: settings?.stripe_customer_id,
              email: account.email,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => resolve(res.data))
          .catch((err) => reject(err));
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("account");
        queryClient.invalidateQueries("settings");
        queryClient.invalidateQueries("limits");
        queryClient.invalidateQueries("syncStats");
      },
      onError: () => {
        alertCtx.error(
          "Something went wrong, please try again later. If the problem persists, contact support."
        );
      },
    }
  );
}
