import React, { useEffect } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import {
  MergeRounded,
  // NotificationsNoneRounded,
  PersonOutlineRounded,
  // SettingsOutlined,
  SourceOutlined,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";

export default function SettingsTabs(props) {
  const location = useLocation();
  const startTab = location?.state?.startTab;
  const { tab, setTab } = props;

  const handleChangeTab = (e, newTab) => {
    setTab(newTab);
  };

  useEffect(() => {
    setTab(startTab || "profile");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="settings-tabs">
      <div className="wrapper">
        <Box sx={{ width: "100%" }}>
          <Tabs value={tab} onChange={handleChangeTab} textColor="primary" indicatorColor="primary">
            {/* <Tab icon={<SettingsOutlined />} iconPosition="start" value="account" label="Account" /> */}
            <Tab
              icon={<PersonOutlineRounded />}
              iconPosition="start"
              value="profile"
              label="profile"
            />
            <Tab
              icon={<MergeRounded />}
              iconPosition="start"
              value="connections"
              label="Connections"
            />
            {/* <Tab
              icon={<NotificationsNoneRounded />}
              iconPosition="start"
              value="notifications"
              label="Notifications"
            /> */}
            <Tab icon={<SourceOutlined />} iconPosition="start" value="logs" label="System Logs" />
          </Tabs>
        </Box>
      </div>
    </div>
  );
}
