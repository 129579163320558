import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { Archive, PlaylistAddCheck } from "@mui/icons-material";

export default function State(props) {
  const { state, setState } = props;
  const handleChangeState = (e, newState) => {
    setState(newState);
  };

  return (
    <div className="dh-state">
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={state}
          onChange={handleChangeState}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab icon={<PlaylistAddCheck />} iconPosition="start" value="active" label="Active" />
          <Tab icon={<Archive />} iconPosition="start" value="archived" label="Archived" />
        </Tabs>
      </Box>
    </div>
  );
}
