import React, { useState, useEffect } from "react";
import logo from "../media/images/logos/ddsync/logo-rev.svg";
import ddLogo from "../media/images/logos/companies/dd-agency-logo-rev.png";
import { CircularProgress, Button, Card, CardContent } from "@mui/material";

import useSubCreate from "../hooks/POST/useSubCreate";

export default function Subscribe(props) {
  const { user } = props;
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  const subCreate = useSubCreate();

  const [btnPkg, setBtnPkg] = useState("");

  const handleSubCreate = async (pkg) => {
    setBtnPkg(pkg);
    const session = await subCreate.mutateAsync(pkg);
    window.location.href = session.url;
  };

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("no-gutter");
  }, []);

  return (
    <div className="subscribe">
      <div className="header center">
        <img className="logo" src={logo} alt="DD Sync"></img>
        <h2>Welcome {metadata.name}, to your DD Sync account!</h2>
        <div>
          In order to get started syncing your data, please purchase a subscription package.
        </div>

        <div className="project">
          <span>A Project of </span>
          <a href="https://agency.directdevelopment.com/">
            <img src={ddLogo} alt="DD Agency"></img>
          </a>
        </div>
      </div>
      <div className="checkout">
        <div className="packages">
          <Card className="pricing bronze">
            <CardContent>
              <h2>Bronze Plan</h2>
              <div className="price">
                <div className="dollar">$149</div>
                <div className="period">
                  <span>per</span>
                  <span>month</span>
                </div>
              </div>
              <Button
                variant="contained"
                className="gradient-btn"
                onClick={() => handleSubCreate("bronze")}
              >
                {subCreate.isLoading && btnPkg === "bronze" ? <CircularProgress /> : "Checkout"}
              </Button>
              <div className="features">
                <h3>This includes:</h3>
                <ul>
                  <li>Bi-directional contact syncing</li>
                  <li>Weekly syncing frequency</li>
                  <li>1,000 contact syncs per month</li>
                  <li>10 contact queries</li>
                  <li>15 contact field mappings per direction</li>
                  <li>999 Slate to HubSpot contact value mappings per field</li>
                  <li>1 contact sync job per direction</li>
                  <li>48 hour turnaround for technical support</li>
                </ul>
              </div>
            </CardContent>
          </Card>

          <Card className="pricing silver">
            <CardContent>
              <div className="most-popular">Most Popular</div>
              <h2 className="plan">Silver Plan</h2>
              <div className="price">
                <div className="dollar">$499</div>
                <div className="period">
                  <span>per</span>
                  <span>month</span>
                </div>
              </div>
              <Button
                variant="contained"
                className="gradient-btn"
                onClick={() => handleSubCreate("silver")}
              >
                {subCreate.isLoading && btnPkg === "silver" ? <CircularProgress /> : "Checkout"}
              </Button>
              <div className="features">
                <h3>This includes:</h3>
                <ul>
                  <li>Bronze tier items</li>
                  <li>Daily syncing frequency</li>
                  <li>Slate to HubSpot Engagements syncing</li>
                  <li>15,000 contact & engagement syncs per month</li>
                  <li>20 contact & engagement queries</li>
                  <li>75 contact & engagement field mappings per direction</li>
                  <li>10 contact & engagement sync jobs per directions</li>
                </ul>
              </div>
            </CardContent>
          </Card>

          <Card className="pricing gold">
            <CardContent>
              <h2>Gold Plan</h2>
              <div className="price">
                <div className="dollar">$749</div>
                <div className="period">
                  <span>per</span>
                  <span>month</span>
                </div>
              </div>
              <Button
                variant="contained"
                className="gradient-btn"
                onClick={() => handleSubCreate("gold")}
              >
                {subCreate.isLoading && btnPkg === "gold" ? <CircularProgress /> : "Checkout"}
              </Button>
              <div className="features">
                <h3>This includes:</h3>
                <ul>
                  <li>Bronze + Silver tier items</li>
                  <li>Hourly syncing frequency</li>
                  <li>Slate to HubSpot Applications syncing</li>
                  <li>50,000 contact, engagement, & application syncs per month</li>
                  <li>35 contact, engagement, & application queries</li>
                  <li>150 contact, engagement, & application field mappings per direction</li>
                  <li>20 contact, engagement, and application sync jobs per direction</li>
                  <li>24 hour technical support turnaround</li>
                </ul>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
