import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Card, CardContent } from "@mui/material";
import { CircularProgress } from "@mui/material";
import DataHub from "../../components/data-hub/DataHub";
import useGetSubscriptionDetails from "../../hooks/GET/useGetSubscriptionDetails";

export default function FieldMappings(props) {
  const { isLoading: subIsLoading, data: subscription } = useGetSubscriptionDetails();

  if (subIsLoading) {
    return (
      <div className="retrieving middle">
        <CircularProgress />
        <h2 className="process">Authenticating...</h2>
      </div>
    );
  }

  return (
    <Container className="engagements field-mappings">
      <Helmet>
        <title>Field Mappings | Engagements</title>
      </Helmet>
      <div className="content-wrapper">
        {subscription.id >= 2 ? (
          <DataHub {...props} record="engagements" screen="field_maps" title="Field Mappings" />
        ) : (
          <Card className="dh-error">
            <CardContent style={{ textAlign: "center" }}>
              <h2 className="red">ACCESS DENIED.</h2>
              <div>
                You must have a DD Sync Silver level account in order to access Engagements syncing
                tools.
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </Container>
  );
}
