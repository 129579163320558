import React, { useState, createContext } from "react";

const GlobalAlertsContext = createContext({
  alertType: null,
  alertText: null,
  alertOpen: true,
  alertBtn: {
    text: null,
    path: null,
  },
  success: () => {},
  error: () => {},
  info: () => {},
  warning: () => {},
  clear: () => {},
});

const GlobalAlertsProvider = (props) => {
  const [alertType, setAlertType] = useState(null);
  const [alertText, setAlertText] = useState(null);
  const [alertOpen, setAlertOpen] = useState(true);
  const [alertBtn, setAlertBtn] = useState({
    text: null,
    path: null,
  });

  const success = (text, btn) => {
    if (alertType !== null) {
      clear();
    }

    setTimeout(
      () => {
        setAlertText(text);
        setAlertType("success");
        setAlertBtn({
          text: btn?.text,
          path: btn?.path,
        });

        setAlertOpen(true);

        window["expire_timer"] = setTimeout(() => {
          setAlertOpen(false);
          setTimeout(() => {
            clear();
          }, 1000);
        }, 10000);
      },
      alertType !== null ? 400 : 0
    );
  };

  const error = (text, btn) => {
    if (alertType !== null) {
      clear();
    }

    setTimeout(
      () => {
        setAlertText(text);
        setAlertType("error");
        setAlertBtn({
          text: btn?.text,
          path: btn?.path,
        });

        setAlertOpen(true);

        window["expire_timer"] = setTimeout(() => {
          setAlertOpen(false);
          setTimeout(() => {
            clear();
          }, 1000);
        }, 10000);
      },
      alertType !== null ? 400 : 0
    );
  };

  const info = (text, btn) => {
    if (alertType !== null) {
      clear();
    }

    setTimeout(
      () => {
        setAlertText(text);
        setAlertType("info");
        setAlertBtn({
          text: btn?.text,
          path: btn?.path,
        });

        setAlertOpen(true);

        window["expire_timer"] = setTimeout(() => {
          setAlertOpen(false);
          setTimeout(() => {
            clear();
          }, 1000);
        }, 10000);
      },
      alertType !== null ? 400 : 0
    );
  };

  const warning = (text, btn) => {
    if (alertType !== null) {
      clear();
    }

    setTimeout(
      () => {
        setAlertText(text);
        setAlertType("warning");
        setAlertBtn({
          text: btn?.text,
          path: btn?.path,
        });

        setAlertOpen(true);

        window["expire_timer"] = setTimeout(() => {
          setAlertOpen(false);
          setTimeout(() => {
            clear();
          }, 1000);
        }, 10000);
      },
      alertType !== null ? 400 : 0
    );
  };

  const clear = () => {
    clearTimeout(window["expire_timer"]);
    setAlertOpen(false);

    setTimeout(() => {
      setAlertText(null);
      setAlertType(null);
      setAlertBtn({
        text: null,
        path: null,
      });
    }, 200);
  };

  return (
    <GlobalAlertsContext.Provider
      value={{
        success,
        error,
        info,
        warning,
        clear,
        alertType,
        alertText,
        alertOpen,
        alertBtn,
      }}
    >
      {props.children}
    </GlobalAlertsContext.Provider>
  );
};

export { GlobalAlertsProvider };
export default GlobalAlertsContext;
