import React from "react";
import { Alert, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function Delinquent() {
  return (
    <div className="delinquent">
      <Alert severity="error" variant="filled">
        <span>
          Your last payment attempt failed. Please update your payment method to continue using DD
          Sync.
        </span>
        <Button variant="contained" className="white-btn" component={Link} to="/settings">
          Settings
        </Button>
      </Alert>
    </div>
  );
}
