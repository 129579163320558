export default function dirDataFormat(data) {
  return new Promise((resolve, reject) => {
    const toHubSpot = data.data.filter((item) => item.direction === "toHubSpot");
    const toSlate = data.data.filter((item) => item.direction === "toSlate");

    const toHubSpotActive = toHubSpot.filter((item) => !item.archived);
    const toHubSpotArchived = toHubSpot.filter((item) => item.archived);
    const toSlateActive = toSlate.filter((item) => !item.archived);
    const toSlateArchived = toSlate.filter((item) => item.archived);

    resolve({
      toHubSpot: {
        active: toHubSpotActive,
        archived: toHubSpotArchived,
      },
      toSlate: {
        active: toSlateActive,
        archived: toSlateArchived,
      },
    });
  });
}
