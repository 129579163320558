import React from "react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

import { useAuth0 } from "@auth0/auth0-react";

const AuthenticationButton = (props) => {
  const { subscription } = props;
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return (
      <div>
        <LoginButton />
      </div>
    );
  } else if (subscription?.id === 0) {
    return (
      <div>
        <LogoutButton />
      </div>
    );
  } else {
    return null;
  }
};

export default AuthenticationButton;
