import validateQueries from "./validateQueries";
import validateFieldMaps from "./validateFieldMaps";
import validateJobs from "./validateJobs";
import validateValueMaps from "./validateValueMaps";

export default function validateSubmit(type, rows, newRow, submit, edit, currEdit) {
  return new Promise((resolve, reject) => {
    if (type === "queries") {
      const validate = validateQueries(rows, newRow, submit, edit);
      resolve(validate);
    }
    if (type === "fieldMaps") {
      const validate = validateFieldMaps(rows, newRow, submit, edit);
      resolve(validate);
    }
    if (type === "jobs") {
      const validate = validateJobs(rows, newRow, submit);
      resolve(validate);
    }
    if (type === "valueMaps") {
      const validate = validateValueMaps(rows, newRow, submit, edit, currEdit);
      resolve(validate);
    }
  });
}
