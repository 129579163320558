import React from "react";
import useCheckHSConnection from "../../hooks/GET/useCheckHsConnection";
import useCheckSlConnection from "../../hooks/GET/useCheckSlConnection";
import useGetSyncStats from "../../hooks/GET/useGetSyncStats";
import { CircularProgress, Card, CardContent } from "@mui/material";

export default function SyncStats() {
  const { isLoading: hsIsLoading, data: hsConnect } = useCheckHSConnection();
  const { isLoading: slIsLoading, data: slConnect } = useCheckSlConnection();
  const { isLoading: statsIsLoading, data: stats } = useGetSyncStats();

  if (statsIsLoading || hsIsLoading || slIsLoading) {
    <div className="retrieving">
      <CircularProgress />
      <h2 className="process">Retrieving data...</h2>
    </div>;
  }

  return (
    <div className="sync-stats">
      <Card>
        <CardContent>
          <h2 className="title">Sync Stats</h2>
          <div className="stats">
            <div className="stat created">
              <strong>{stats?.created + stats?.updated || 0}</strong> Records Successfully Synced
            </div>
            <div className="stat partial">
              <strong>{stats?.partial || 0}</strong> Records Partially Synced
            </div>
            <div className="stat failed">
              <strong>{stats?.failed || 0}</strong> Records Failed to Sync
            </div>
          </div>
          <div className="connections">
            <div className="hubspot">
              HubSpot:{" "}
              {hsConnect ? (
                <strong style={{ color: "green" }}>Connected</strong>
              ) : (
                <strong style={{ color: "red" }}>Disconnected</strong>
              )}
            </div>
            <div className="slate">
              Slate:{" "}
              {slConnect ? (
                <strong style={{ color: "green" }}>Connected</strong>
              ) : (
                <strong style={{ color: "red" }}>Disconnected</strong>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
