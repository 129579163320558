import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  AccountTreeRounded,
  ListAltRounded,
  SyncRounded,
  ArrowForwardRounded,
  AccessTimeRounded,
  ShuffleRounded,
  ExpandMoreRounded,
} from "@mui/icons-material";
import logo from "../media/images/logos/ddsync/mark.svg";
import ddLogo from "../media/images/logos/companies/dd-agency-logo-rev.png";
import hsLogo from "../media/images/logos/companies/hubspot-white.svg";
import slLogo from "../media/images/logos/companies/slate-white.svg";
import useGetSettings from "../hooks/GET/useGetSettings";
import useGetSubscriptionDetails from "../hooks/GET/useGetSubscriptionDetails";

export default function DirectionPanel(props) {
  const { direction, setDirection } = props;
  const [expanded, setExpanded] = useState(false);
  const { data: settings } = useGetSettings();
  const { data: subscription } = useGetSubscriptionDetails();

  const handleSwitch = () => {
    if (window.location.pathname !== "/slate-queries") {
      setDirection(`${direction === "toHubSpot" ? "toSlate" : "toHubSpot"}`);
    }
  };

  const handleExpand = (panel) => (e, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {}, [direction]);

  return (
    <div className={`direction-panel ${direction}`}>
      <div className="content-wrapper">
        <div className="head-area">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="DD Sync Mark"></img>
            </Link>
            <div className="half-circle"></div>
          </div>
        </div>
        <div
          className={`main-area ${direction === "toSlate" ? "flipped" : ""} ${
            subscription.delinquent ? "disabled" : ""
          }`}
        >
          <div className="switch">
            <div className="card">
              <div className="front">
                <div className="logos">
                  <div className="logo slate">
                    <img src={slLogo} alt="Slate Logo"></img>
                  </div>
                  <ArrowForwardRounded
                    className="arrow"
                    fontSize="large"
                    sx={{ stroke: "#ffffff", strokeWidth: 1 }}
                  />
                  <div className="logo hubspot">
                    <img src={hsLogo} alt="HubSpot Logo"></img>
                  </div>
                </div>
                <Tooltip
                  title={`${
                    window.location.pathname.includes("/contacts/slate-queries")
                      ? "You can't switch directions while editing Slate Queries."
                      : ""
                  }${
                    window.location.pathname.includes("/values")
                      ? "You can't switch directions while editing Value Mappings."
                      : ""
                  }${
                    window.location.pathname.includes("/applications")
                      ? "You can't switch directions while editing Applications."
                      : ""
                  }${
                    window.location.pathname.includes("/engagements")
                      ? "You can't switch directions while editing Engagements."
                      : ""
                  }`}
                >
                  <div>
                    <Button
                      onClick={handleSwitch}
                      className={`flip ${
                        window.location.pathname.includes("/contacts/slate-queries") ||
                        window.location.pathname.includes("/values") ||
                        window.location.pathname.includes("/applications") ||
                        window.location.pathname.includes("/engagements")
                          ? "disabled"
                          : ""
                      }`}
                      variant="contained"
                      color="primary"
                    >
                      <ShuffleRounded /> SWITCH
                    </Button>
                  </div>
                </Tooltip>
              </div>
              <div className="back">
                <div className="logos">
                  <div className="logo hubspot">
                    <img src={hsLogo} alt="HubSpot Logo"></img>
                  </div>
                  <ArrowForwardRounded
                    className="arrow"
                    fontSize="large"
                    sx={{ stroke: "#ffffff", strokeWidth: 1 }}
                  />
                  <div className="logo slate">
                    <img src={slLogo} alt="Slate Logo"></img>
                  </div>
                </div>
                <Tooltip
                  title={`${
                    window.location.pathname.includes("/contacts/slate-queries")
                      ? "You can't switch directions while editing Slate Queries."
                      : ""
                  }${
                    window.location.pathname.includes("/values")
                      ? "You can't switch directions while editing Value Mappings."
                      : ""
                  }${
                    window.location.pathname.includes("/applications")
                      ? "You can't switch directions while editing Applications."
                      : ""
                  }${
                    window.location.pathname.includes("/engagements")
                      ? "You can't switch directions while editing Engagements."
                      : ""
                  }`}
                >
                  <div>
                    <Button
                      onClick={handleSwitch}
                      className={`flip ${
                        window.location.pathname.includes("/contacts/slate-queries") ||
                        window.location.pathname.includes("/values") ||
                        window.location.pathname.includes("/applications") ||
                        window.location.pathname.includes("/engagements")
                          ? "disabled"
                          : ""
                      }`}
                      variant="contained"
                      color="primary"
                    >
                      <ShuffleRounded /> SWITCH
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="object-list">
            <Accordion
              className="object-list-item"
              expanded={expanded === "contacts"}
              onChange={handleExpand("contacts")}
            >
              <AccordionSummary expandIcon={<ExpandMoreRounded />}>Contacts</AccordionSummary>
              <AccordionDetails>
                <List className="tab-list contacts">
                  <ListItem
                    disablePadding
                    className={`tab-list-item slate-queries ${
                      direction === "toSlate" ? "disabled" : ""
                    }`}
                  >
                    <ListItemButton
                      component={NavLink}
                      to="/contacts/slate-queries"
                      className={(navData) => (navData.isActive ? "active" : "")}
                    >
                      <ListItemIcon>
                        <ListAltRounded />
                      </ListItemIcon>
                      <ListItemText primary="Slate Queries" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding className="tab-list-item field-maps">
                    <ListItemButton
                      component={NavLink}
                      to="/contacts/mappings/fields"
                      className={(navData) => (navData.isActive ? "active" : "")}
                    >
                      <ListItemIcon>
                        <AccountTreeRounded />
                      </ListItemIcon>
                      <ListItemText primary="Field Mappings" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding className="tab-list-item sync-jobs">
                    <ListItemButton
                      component={NavLink}
                      to="/contacts/sync-jobs"
                      className={(navData) => (navData.isActive ? "active" : "")}
                    >
                      <ListItemIcon>
                        <SyncRounded />
                      </ListItemIcon>
                      <ListItemText primary="Sync Schedule" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding className="tab-list-item sync-history">
                    <ListItemButton
                      component={NavLink}
                      to="/contacts/sync-history"
                      className={(navData) => (navData.isActive ? "active" : "")}
                    >
                      <ListItemIcon>
                        <AccessTimeRounded />
                      </ListItemIcon>
                      <ListItemText primary="Sync History" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
            {subscription?.id >= 2 ? (
              <Accordion
                className={`object-list-item ${direction === "toSlate" ? "disabled" : ""}`}
                expanded={expanded === "engagements"}
                onChange={handleExpand("engagements")}
              >
                <AccordionSummary expandIcon={<ExpandMoreRounded />}>Engagements</AccordionSummary>
                <AccordionDetails>
                  <List className="tab-list engagements">
                    <ListItem
                      disablePadding
                      className={`tab-list-item slate-queries ${
                        direction === "toSlate" ? "disabled" : ""
                      }`}
                    >
                      <ListItemButton
                        component={NavLink}
                        to="/engagements/slate-queries"
                        className={(navData) => (navData.isActive ? "active" : "")}
                      >
                        <ListItemIcon>
                          <ListAltRounded />
                        </ListItemIcon>
                        <ListItemText primary="Slate Queries" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className="tab-list-item field-maps">
                      <ListItemButton
                        component={NavLink}
                        to="/engagements/mappings/fields"
                        className={(navData) => (navData.isActive ? "active" : "")}
                      >
                        <ListItemIcon>
                          <AccountTreeRounded />
                        </ListItemIcon>
                        <ListItemText primary="Field Mappings" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className="tab-list-item sync-jobs">
                      <ListItemButton
                        component={NavLink}
                        to="/engagements/sync-jobs"
                        className={(navData) => (navData.isActive ? "active" : "")}
                      >
                        <ListItemIcon>
                          <SyncRounded />
                        </ListItemIcon>
                        <ListItemText primary="Sync Schedule" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className="tab-list-item sync-history">
                      <ListItemButton
                        component={NavLink}
                        to="/engagements/sync-history"
                        className={(navData) => (navData.isActive ? "active" : "")}
                      >
                        <ListItemIcon>
                          <AccessTimeRounded />
                        </ListItemIcon>
                        <ListItemText primary="Sync History" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            ) : (
              ""
            )}
            {settings?.hs_enterprise && subscription?.id >= 3 ? (
              <Accordion
                className={`object-list-item ${direction === "toSlate" ? "disabled" : ""}`}
                expanded={expanded === "applications"}
                onChange={handleExpand("applications")}
              >
                <AccordionSummary expandIcon={<ExpandMoreRounded />}>Applications</AccordionSummary>
                <AccordionDetails>
                  <List className="tab-list contacts">
                    <ListItem disablePadding className="tab-list-item slate-queries">
                      <ListItemButton
                        component={NavLink}
                        to="/applications/slate-queries"
                        className={(navData) => (navData.isActive ? "active" : "")}
                      >
                        <ListItemIcon>
                          <ListAltRounded />
                        </ListItemIcon>
                        <ListItemText primary="Slate Queries" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className="tab-list-item field-maps">
                      <ListItemButton
                        component={NavLink}
                        to="/applications/mappings/fields"
                        className={(navData) => (navData.isActive ? "active" : "")}
                      >
                        <ListItemIcon>
                          <AccountTreeRounded />
                        </ListItemIcon>
                        <ListItemText primary="Field Mappings" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className="tab-list-item sync-jobs">
                      <ListItemButton
                        component={NavLink}
                        to="/applications/sync-jobs"
                        className={(navData) => (navData.isActive ? "active" : "")}
                      >
                        <ListItemIcon>
                          <SyncRounded />
                        </ListItemIcon>
                        <ListItemText primary="Sync Schedule" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className="tab-list-item sync-history">
                      <ListItemButton
                        component={NavLink}
                        to="/applications/sync-history"
                        className={(navData) => (navData.isActive ? "active" : "")}
                      >
                        <ListItemIcon>
                          <AccessTimeRounded />
                        </ListItemIcon>
                        <ListItemText primary="Sync History" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            ) : (
              ""
            )}
          </div>

          <div className="account">
            <div className="project">
              <span>A Project of </span>
              <a href="https://agency.directdevelopment.com/">
                <img src={ddLogo} alt="DD Agency"></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
