import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import useArchiveQueries from "../hooks/PUT/useArchiveQueries.js";
import useArchiveFieldMaps from "../hooks/PUT/useArchiveFieldMaps.js";
import useArchiveJobs from "../hooks/PUT/useArchiveJobs.js";

export default function Archive(props) {
  const {
    record,
    screen,
    archiveModalState,
    setArchiveModalState,
    setSelectionModel,
    selectionModel,
    selectedRows,
  } = props;
  const archiveQueries = useArchiveQueries(record, selectionModel, selectedRows);
  const archiveFieldMaps = useArchiveFieldMaps(record, selectionModel);
  const archiveJobs = useArchiveJobs(record, selectionModel, selectedRows);
  let archiveRows;
  if (screen === "queries") {
    archiveRows = archiveQueries;
  } else if (screen === "field_maps") {
    archiveRows = archiveFieldMaps;
  } else if (screen === "jobs") {
    archiveRows = archiveJobs;
  }

  const handleArchiveRows = async () => {
    await archiveRows.mutateAsync().then(() => {
      setArchiveModalState(false);
      setSelectionModel([]);
    });
  };

  return (
    <div className="dh-archive">
      <Button onClick={() => setArchiveModalState(true)} variant="text" className="line-btn">
        Archive
      </Button>

      <Dialog open={archiveModalState} maxWidth="md" className="dh-modal archive">
        <IconButton
          onClick={() => setArchiveModalState(false)}
          className="close"
          aria-label="close"
        >
          <CloseRounded />
        </IconButton>
        <DialogTitle>Are you sure you want to archive this?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Archiving an item will result in any associated items such as Slate Queries, Field
            Mappings, and Sync Schedules being archived as well. Proceed with caution.
          </DialogContentText>
          <DialogActions className="actions">
            <Button
              onClick={() => setArchiveModalState(false)}
              variant="text"
              className="line-btn warning"
            >
              Cancel
            </Button>
            <Button onClick={handleArchiveRows} variant="contained" className="gradient-btn">
              <span>{archiveRows.isLoading ? <CircularProgress /> : "Archive"}</span>
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
