import React, { useContext } from "react";
import GlobalAlertsContext from "../globalAlerts";
import { Alert, Button, Slide } from "@mui/material";
import { Link } from "react-router-dom";

export default function GlobalAlerts() {
  const alertCtx = useContext(GlobalAlertsContext);

  return (
    alertCtx.alertType !== null && (
      <div className="global-alerts">
        <Slide direction="up" in={alertCtx.alertOpen} mountOnEnter unmountOnExit>
          <Alert
            severity={alertCtx.alertType}
            className="alert"
            onClose={() => {
              alertCtx.clear();
            }}
          >
            <span>{alertCtx.alertText}</span>
            {alertCtx?.alertBtn?.text ? (
              <Button
                className="white-btn"
                variant="contained"
                component={Link}
                to={alertCtx.alertBtn.path}
              >
                {alertCtx.alertBtn.text}
              </Button>
            ) : (
              ""
            )}
          </Alert>
        </Slide>
      </div>
    )
  );
}
