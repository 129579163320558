import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useGetSubscriptionDetails() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  let metadata;

  if (user) {
    metadata = user[process.env.REACT_APP_AUTH0_METADATA];
  }

  return useQuery(
    "subscription",
    async () => {
      if (user) {
        const token = await getAccessTokenSilently();

        const config = {
          params: {
            tenant: metadata.username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        return await axios
          .get(`/database/account/subscription`, config)
          .then((res) => res.data)
          .catch((err) => err);
      } else {
        return null;
      }
    },
    {
      initialData: () => queryClient.getQueryData("subscription"),
      staleTime: Infinity,
      initialDataUpdatedAt: () => queryClient.getQueryState("subscription")?.dataUpdatedAt,
    }
  );
}
