import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useGetLogsByTimestamp(timestamp) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    ["timestampLogs", timestamp],
    async () => {
      const token = await getAccessTokenSilently();

      const config = {
        params: {
          tenant: metadata.username,
          timestamp: timestamp,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return await axios
        .get(`/aws/logs/timestamp`, config)
        .then((res) => {
          let logs = res.data;

          logs = logs.map((log) => {
            let { message, timestamp } = log;
            const type = message.substring(1, message.indexOf("]"));
            const action = message.substring(message.indexOf("]") + 2, message.indexOf("--"));
            message = message.substring(message.indexOf("--") + 2);

            return { message: message, timestamp: timestamp, type: type, action: action };
          });

          return logs;
        })
        .catch((err) => err);
    },
    {
      // enabled: false,
      initialData: () => queryClient.getQueryData("timestampLogs"),
      staleTime: Infinity,
      initialDataUpdatedAt: () => queryClient.getQueryState("timestampLogs")?.dataUpdatedAt,
    }
  );
}
