import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useGetSlFields(record) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    ["slFields", record],
    async () => {
      const token = await getAccessTokenSilently();

      return axios
        .get(`/${record}/query/fields`, {
          params: {
            tenant: metadata.username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const slFields = res.data.filter((fields) => {
            if (fields.sl_field_value !== "Email" && fields.sl_field_value !== "Updated") {
              return fields;
            }

            return false;
          });

          return slFields
            .map((fields) => {
              return {
                sl_field_value: fields.sl_field_value,
                sl_field_label: fields.sl_field_label,
                sl_field_type: fields.sl_field_type,
              };
            })
            .sort();
        })
        .catch((err) => err.response);
    },
    {
      initialData: () => queryClient.getQueryData(["slFields", record]),
      staleTime: Infinity,
      initialDataUpdatedAt: () => queryClient.getQueryState(["slFields", record])?.dataUpdatedAt,
    }
  );
}
