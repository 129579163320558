import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { CircularProgress, Button, Switch, Card, CardContent } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import useGetLogs from "../../hooks/GET/useGetLogs";
import Alerts from "../../components/data-hub/modules/Alerts";

export default function Logs() {
  const { isLoading, data: logs, refetch, isRefetching } = useGetLogs();
  const [errorOnly, setErrorOnly] = useState(false);

  if (isLoading) {
    return (
      <div className="retrieving">
        <CircularProgress />
        <h2 className="process">Retrieving logs. This may take a few minutes...</h2>
      </div>
    );
  }

  return (
    <div className="logs">
      <Helmet>
        <title>System Logs | DD Sync</title>
      </Helmet>
      <div className="controls">
        <div className="heading">
          <h1 className="title">System Logs</h1>
          <p>Up to last 30 days or 1,000 lines. Whichever comes first.</p>
        </div>
        {!logs?.response && (
          <Button
            className="circle-btn actions"
            variant="contained"
            color="secondary"
            onClick={refetch}
          >
            {isRefetching ? <CircularProgress /> : <Refresh />}
          </Button>
        )}
      </div>
      <Alerts
        content={{
          error: [
            {
              test: !logs?.response,
              title: "There was an error retrieving your Sync History.",
              message:
                "Please refresh and try again. If the problem persists, please contact support.",
            },
          ],
        }}
      />
      {!logs?.response && (
        <div className="console">
          {logs.length > 0 &&
            logs.map((log, i) => (
              <code key={i} className={log.type !== "ERROR" && errorOnly ? "hidden" : ""}>
                <span className="timestamp">{new Date(log.timestamp).toUTCString()}: </span>
                <span className={`type ${log.type}`}>[{log.type}] </span>
                <span className="action">{log.action}</span> --
                {log.message.length >= 20 && <br></br>}
                <span className="message">
                  {log.message.slice(0, 1000)}
                  {log.message.length >= 1000 ? "..." : ""}
                </span>
              </code>
            ))}
        </div>
      )}
      {!logs?.response && (
        <div className="details">
          <small className="lines">{logs?.length} lines</small>
          <div className="error-only">
            <small>Error logs ONLY</small>
            <Switch
              checked={errorOnly}
              onChange={(e) => setErrorOnly(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
              color="error"
              aria-label="Error Logs ONLY"
            />
          </div>
        </div>
      )}
      {logs?.response && (
        <Card className="dh-error">
          <CardContent>Data could not be displayed.</CardContent>
        </Card>
      )}
    </div>
  );
}
