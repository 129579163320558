import React, { useEffect } from "react";
import { AddCircleRounded } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";

export default function Add(props) {
  const { setSaveModalState, count, subscription, screen, selectionModel, marker } = props;

  useEffect(() => {}, [count]);

  return (
    <div className="dh-add">
      {count < subscription[screen] &&
      marker !== "visualize" &&
      marker !== "archived" &&
      count !== null &&
      selectionModel.length === 0 ? (
        <Button
          onClick={() => setSaveModalState(true)}
          variant="contained"
          className="gradient-btn"
        >
          <AddCircleRounded />
          <span>ADD NEW</span>
        </Button>
      ) : (
        <Tooltip
          title={`${count >= subscription[screen] ? "Limit reached" : ""}
            ${marker === "archived" ? "You can't add new rows to an archived item" : ""}
            ${marker === "visualize" ? "Switch to 'Update' to add a new row" : ""}
            ${
              count === null && marker !== "visualize" && marker !== "archived"
                ? "Error loading table"
                : ""
            }
            ${selectionModel.length > 0 ? "Deselect all rows to add new row" : ""}`}
        >
          <div>
            <Button variant="contained" className="gradient-btn disabled">
              <AddCircleRounded />
              <span>ADD NEW</span>
            </Button>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
