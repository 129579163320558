import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { MenuItem, ListItemIcon } from "@mui/material";
import { Logout } from "@mui/icons-material";

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <MenuItem
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      <ListItemIcon>
        <Logout fontSize="small" />
      </ListItemIcon>
      Log Out
    </MenuItem>
  );
};

export default LogoutButton;
