import React, { useEffect } from "react";
import Alerts from "../modules/Alerts";

export default function FieldMapsAlerts(props) {
  const { direction, data, queries, hsFields, slFields, hsConnect, slConnect, reqMaps } = props;

  useEffect(() => {}, [data, direction]);

  return (
    <div className="dh-alerts">
      {direction === "toHubSpot" ? (
        <Alerts
          {...props}
          content={{
            info: [
              {
                title: "Behavior of mappings to the same HubSpot field",
                message:
                  "If two or more Slate fields are mapped to the same HubSpot field of the same type, the sync process will use the the first Slate field containing a value. Priority order is from earliest added to latest.",
                record: "engagements",
              },
            ],

            warning: [
              {
                test: !queries?.toHubSpot?.active?.some((query) => query?.valid === 0),
                title: "One or more of your Slate Queries is invalid",
                message:
                  "This may result in some fields being unavailable and some Field Mappings being marked as invalid.",
              },
              {
                test:
                  reqMaps?.emails ||
                  !queries?.toHubSpot?.active?.some((query) => query?.subrecord === "emails"),
                title: "Required Field Mappings for Emails Sync Jobs are missing.",
                message: `You will not be able to create or run Emails Sync Jobs without mappings to these HubSpot fields: 
                    <ul>
                    ${!reqMaps?.fields?.emails.includes("to_email") ? "<li>To Email</li>" : ""}
                    ${!reqMaps?.fields?.emails.includes("send_date") ? "<li>Send Date</li>" : ""}
                    ${
                      !reqMaps?.fields?.emails.includes("email_subject")
                        ? "<li>Email Subject</li>"
                        : ""
                    }
                    </ul>
                  `,
                record: "engagements",
              },
              {
                test:
                  reqMaps?.calls ||
                  !queries?.toHubSpot?.active?.some((query) => query?.subrecord === "calls"),
                title: "Required Field Mappings for Calls Sync Jobs are missing.",
                message: `You will not be able to create or run Calls Sync Jobs without mappings to these HubSpot fields:
                    <ul>
                      ${
                        !reqMaps?.fields?.calls.includes("call_person_email")
                          ? "<li>Person Email</li>"
                          : ""
                      }
                      ${!reqMaps?.fields?.calls.includes("to_number") ? "<li>To Number</li>" : ""}
                      ${!reqMaps?.fields?.calls.includes("call_date") ? "<li>Call Date</li>" : ""}
                      ${!reqMaps?.fields?.calls.includes("call_title") ? "<li>Call Title</li>" : ""}
                    </ul>
                  `,
                record: "engagements",
              },
              {
                test:
                  reqMaps?.notes ||
                  !queries?.toHubSpot?.active?.some((query) => query?.subrecord === "notes"),
                title: "Required Field Mappings for Notes Sync Jobs are missing.",
                message: `You will not be able to create or run Notes Sync Jobs without mappings to these HubSpot fields:
                    <ul>
                      ${
                        !reqMaps?.fields?.notes.includes("note_person_email")
                          ? "<li>Person Email</li>"
                          : ""
                      }
                      ${!reqMaps?.fields?.notes.includes("note_date") ? "<li>Note Date</li>" : ""}
                      ${!reqMaps?.fields?.notes.includes("note_body") ? "<li>Note Body</li>" : ""}
                    </ul>
                  `,
                record: "engagements",
              },
              {
                test:
                  reqMaps?.meetings ||
                  !queries?.toHubSpot?.active?.some((query) => query?.subrecord === "meetings"),
                title: "Required Field Mappings for Meetings Sync Jobs are missing.",
                message: `You will not be able to create or run Meetings Sync Jobs without mappings to these HubSpot fields:
                    <ul>
                      ${
                        !reqMaps?.fields?.meetings.includes("meeting_person_email")
                          ? "<li>Person Email</li>"
                          : ""
                      }
                      ${
                        !reqMaps?.fields?.meetings.includes("meeting_start_time_date")
                          ? "<li>Start Date/Time</li>"
                          : ""
                      }
                      ${
                        !reqMaps?.fields?.meetings.includes("meeting_title")
                          ? "<li>Meeting Title</li>"
                          : ""
                      }
                    </ul>
                  `,
                record: "engagements",
              },
              {
                test:
                  reqMaps?.postal ||
                  !queries?.toHubSpot?.active?.some((query) => query?.subrecord === "postal_mail"),
                title: "Required Field Mappings for Postal Mail Sync Jobs are missing.",
                message: `You will not be able to create or run Postal Mail Sync Jobs without mappings to these HubSpot fields:
                    <ul>
                      ${
                        !reqMaps?.fields?.postal.includes("postal_person_email")
                          ? "<li>Person Email</li>"
                          : ""
                      }
                      ${
                        !reqMaps?.fields?.postal.includes("postal_date") ? "<li>Send Date</li>" : ""
                      }
                      ${
                        !reqMaps?.fields?.postal.includes("postal_body")
                          ? "<li>Postal Mail Body</li>"
                          : ""
                      }
                    </ul>
                  `,
                record: "engagements",
              },
              {
                test: reqMaps?.apps || !queries?.toHubSpot?.active?.length > 0,
                title: "Required Field Mappings for Application Sync Jobs are missing.",
                message: `You will not be able to create or run Application Sync Jobs without mappings to these HubSpot fields:
                    <ul>
                      ${!reqMaps?.fields?.apps.includes("email") ? "<li>Email</li>" : ""}
                      ${!reqMaps?.fields?.apps.includes("app_id") ? "<li>Application ID</li>" : ""}
                      ${
                        !reqMaps?.fields?.apps.includes("app_updated")
                          ? "<li>Updated Date</li>"
                          : ""
                      }
                    </ul>
                  `,
                record: "applications",
              },
            ],

            error: [
              {
                test: !data?.status && !queries?.status && !hsFields?.status && !slFields?.status,
                title: "There was an error retrieving your Field Mappings",
                message:
                  "Please refresh and try again. If the problem persists, please contact support",
              },
              {
                test: slFields?.length > 0 || slFields?.status,
                title: "No Slate Query contains usable fields",
                message:
                  "You have not added any Slate Queries with usable fields. Please add at least one valid Slate Query to create Slate to HubSpot Field Mappings.",
              },
              {
                test: hsConnect.status,
                title: "Your HubSpot Account is not connected",
                message:
                  "Please connect your account in order to proceed with Slate to HubSpot Field Mapping.",
              },
            ],
          }}
        />
      ) : (
        <Alerts
          {...props}
          minimize={true}
          content={{
            info: [
              {
                title: "Only HubSpot Fields can be selected",
                message: `Field Mapping included here only controls which fields DD Sync will pull from HubSpot. 
                  To actually map these HubSpot Fields to Slate Fields, click the "Generate XML Map" button and use it in your Slate Source Format.`,
              },
            ],
            error: [
              {
                test:
                  !data?.status &&
                  (!queries?.status || direction === "toSlate") &&
                  !hsFields?.status,
                title: "There was an error retrieving your Field Mapping",
                message:
                  "Please refresh and try again. If the problem persists, please contact support",
              },
              {
                test: hsConnect.status,
                title: "Your HubSpot Account is not connected",
                message:
                  "Please connect your account in order to proceed with HubSpot to Slate Field Mapping.",
              },
              {
                test: slConnect.status,
                title: "Your Slate Account is not connected",
                message:
                  "Please connect your account in order to proceed with HubSpot to Slate Field Mapping.",
              },
            ],
          }}
        />
      )}
    </div>
  );
}
