import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useQuery } from "react-query";

export default function useGetNotifications() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useQuery(
    "notifications",
    async () => {
      const token = await getAccessTokenSilently();

      return await axios
        .get("/database/notifications", {
          params: {
            tenant: metadata.username,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const unreadNotifs = res.data.filter((query) => !query.read);
          const readNotifs = res.data.filter((query) => query.read);

          return { unread: unreadNotifs, read: readNotifs };
        })
        .catch((err) => err);
    },
    {
      initialData: () => queryClient.getQueryData("notifications"),
      staleTime: 30000,
      refetchInterval: 30000,
      initialDataUpdatedAt: () => queryClient.getQueryState("notifications")?.dataUpdatedAt,
    }
  );
}
