import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../../../globalAlerts";

export default function useAddQuery(record, query, direction) {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async () => {
      const token = await getAccessTokenSilently();

      return await axios
        .post(
          `/${record}/query`,
          {
            tenant: metadata.username,
            query: query,
            direction: direction,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        alertCtx.success("Slate Query successfully added");

        queryClient.setQueryData(["queries", record], (old) => {
          return {
            toHubSpot: {
              active:
                direction === "toHubSpot"
                  ? [{ ...query, id: data.insertId, valid: true }, ...old.toHubSpot.active]
                  : old.toHubSpot.active,
              archived: old.toHubSpot.archived,
            },
            toSlate: {
              active:
                direction === "toSlate"
                  ? [{ ...query, id: data.insertId, valid: true }, ...old.toSlate.active]
                  : old.toSlate.active,
              archived: old.toSlate.archived,
            },
          };
        });

        queryClient.invalidateQueries([`slFields - ${record}`, "toHubSpot"]);
        queryClient.invalidateQueries([`hsFields - ${record}`, "toHubSpot"]);
      },
      onError: (err) => {
        if (err.response.status === 400) {
          alertCtx.error(
            "This query does not contain the 'Email' and/or 'Updated' fields, which are required. Please check your query and try again."
          );
        } else if (err.response.status === 403) {
          alertCtx.error(
            "This query has incorrect credentials. Please check your query and try again."
          );
        } else if (err.response.status === 426) {
          alertCtx.warning(
            "Slate Query could not be added. Account limit for Slate Queries has been reached."
          );
        } else if (err.response.status === 503) {
          alertCtx.warning(
            "This Slate Query returned no data. Please check your Slate Query and make sure it returns at least 1 valid record."
          );
        } else {
          alertCtx.error(
            "Something went wrong, please try again later. If the problem persists, contact support."
          );
        }
      },
    }
  );
}
