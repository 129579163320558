import { useContext } from "react";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalAlertsContext from "../../globalAlerts";

export default function useDeleteHsConnection(refreshToken) {
  const alertCtx = useContext(GlobalAlertsContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const metadata = user[process.env.REACT_APP_AUTH0_METADATA];

  return useMutation(
    async () => {
      const token = await getAccessTokenSilently();

      return await axios
        .delete("/database/connect/hubspot", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            tenant: metadata.username,
            refreshToken: refreshToken,
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        alertCtx.success("HubSpot account successfully disconnected");

        queryClient.invalidateQueries("settings");
        queryClient.invalidateQueries("hsConnected");
      },
      onError: () => {
        alertCtx.error(
          "Something went wrong, please try again later. If the problem persists, contact support."
        );
      },
    }
  );
}
